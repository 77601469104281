import {useContext, useState} from "react";
import axios from "axios";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import AuthContext from "../../../store/auth-context";
import EmailInput from "../Inputs/EmailInput";

function InviteForm(props){

    const authCtx = useContext(AuthContext);

    const [email, setEmail] = useState(false);

    const [sending, setSending] = useState(false);
    const [forceValidation, setForceValidation] = useState(false);
    const [returnMsg, setReturnMsg] = useState('');
    const [returnMsgError, setReturnMsgError] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    function formSubmitHandler(e){
        e.preventDefault();
        setForceValidation(true);

        if(email){
            setSending(true);
            setShowMessage(false);

            const data = {
                id: authCtx.id,
                email: email,
                userType: props.userType
            };

            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + authCtx.token,
                },
            };

            setTimeout(function(){

                axios.post('/api/invite/', data, config).then(response => {
                    if(response.status === 200){
                        setReturnMsg(response.data.message);
                        setSending(false);

                        //Check for api error
                        if(response.data.error !== false){
                            setReturnMsgError('ReturnMsgError');
                        }else{
                            setReturnMsgError('');
                        }
                        setShowMessage(true);
                    }else{ connectionError()}
                }).catch(function (error) {
                    if (error) { connectionError()}
                });
            }, 500);
        }
    }

    function connectionError(){
        setReturnMsg("There's been an issue connecting to the server. Please check your internet connection and try again.");
        setSending(false);
        setReturnMsgError("ReturnMsgError");
        setShowMessage(true);
    }

    function emailHandler(status){
        setEmail(status);
    }

    return(
        <>
            <TrayToggle open={showMessage}>
                <div className={["ReturnMsg", returnMsgError].join(" ")}>{returnMsg}</div>
            </TrayToggle>

            <form onSubmit={formSubmitHandler} className={"form"} action="">
                <div className={"field"}>
                    <EmailInput
                        value={(status) => emailHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Email address"}
                    />
                </div>

                <button className={"submit"} type="submit" disabled={sending}>{!sending ? "Send Invite" : "Sending..."}</button>

                {sending && <div className={"sending"} />}
            </form>
        </>
    )
}

export default InviteForm;