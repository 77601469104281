import {useRef, useState} from "react";
import style from './PercentageInput.module.scss'

function PercentageInput(props){

    let initialValue = ''
    if(props.initialValue){
        initialValue = props.initialValue
    }

    const [percent, setPercent] = useState(initialValue);

    const input = useRef();

    function is_int(value){
        return (parseInt(value) % 1 === 0);
    }

    const validPercent = (percent) => {
        const is_number = is_int(percent)
        if(is_number || percent === ''){
            const numeric = percent.replace(/\D/g,'').replace(/^0+/, '')
            if(parseInt(percent) <= 100 || percent === '') {
                return numeric
            }
        }
        return false
    }

    const updatePercent = (percent) => {
        let valid = validPercent(percent)
        if(valid !== false){
            setPercent(valid)
            props.value(valid)
        }
    }

    const showGrade = (percent) => {
        let valid = validPercent(percent)
        if(valid && props.showGrade){
            props.showGrade(valid)
        }
    }

    return(
        <>
            <div className={style.percent_wrapper}>
                <input
                    value={percent}
                    ref={input}
                    type="text"
                    maxLength='3'
                    onChange={(e) => updatePercent(e.target.value)}
                    onBlur={(e) => showGrade(e.target.value)}
                    className={style.percent_input}
                />
                <span className={style.percent_icon}>
                    %
                </span>
            </div>
        </>
    )
}

export default PercentageInput;