import style from '../MonthSection/month-section.module.scss'
import CourseIconsWrapper from '../CourseIconsWrapper/CourseIconsWrapper'
const MonthSection = ({title, children}) => {

    return (
        <div className={style.monthSection}>
            <div className={style.monthSection__title}>{title}</div>
            <CourseIconsWrapper>
                {children}
            </CourseIconsWrapper>
        </div>
    )
}

export default MonthSection