export const COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'TCR',
        accessor: 'tcr'
    },
    {
        Header: 'Remove',
        accessor: 'remove'
    }
]