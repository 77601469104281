import React, {useMemo} from 'react';
import {useTable, usePagination, useSortBy, useGlobalFilter} from "react-table";
import {COLUMNS} from "./columns/courseArchiveColumns";
import './table.scss';
import format from "date-fns/format";
import parse from "date-fns/parse";
import MoreTableButton from "../../ui-components/MoreTableButton/MoreTableButton";
import SelectInputMini from '../Inputs/SelectInputMini'
import ButtonLittle from '../../ui-components/ButtonLittle/ButtonLittle'

function CourseArchiveTable(props) {

    const columns = useMemo(() => COLUMNS, []);
    const data = props.data;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        pageCount,
        prepareRow,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        gotoPage,
        setPageSize
    } = useTable({
        columns: columns,
        data: data,
        initialState: { pageSize: 50 }
    }, useGlobalFilter, useSortBy, usePagination);

    const { pageIndex } = state;

    function cellContentHandler(columnTitle, content, id){

        //Details
        if(columnTitle === "Details"){
            return(
                <MoreTableButton onClick={() => props.goToCourse(id)}>Details</MoreTableButton>
            )
        }

        //Format Exam Date
        if(columnTitle === "Exam Date"){
            const removeTime = content.props.value.slice(0, -9)
            const parseDate = parse(removeTime, "yyyy-MM-dd", new Date())
            const formattedDate = format(parseDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
            return(
                formattedDate
            )
        }
        return content
    }

    return (
        <>
            <div className={'table_container'}>
                <div className={'table_container_padding'}>
                    <table {...getTableProps()}>
                        <thead>
                        {headerGroups.map((headerGroup, i) =>(
                            <tr {...headerGroup.getHeaderGroupProps} key={i}>
                                {headerGroup.headers.map((column, i) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                        {column.render('Header')}
                                        <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {

                                        return <td{...cell.getCellProps()} key={cell.getCellProps().key}>
                                            {cellContentHandler(cell.column.Header, cell.render('Cell'), row.original.id)}
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

                <div className={'table_nav'}>
                    <div className={'page_of_page_text'}>
                        Page <b>{pageIndex + 1}</b> of <b>{pageOptions.length}</b>
                    </div>

                    <div className={'go_to_page_text'}>
                        Go to page:
                    </div>
                    <input
                        className={'page_search'}
                        type={'number'}
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            let pageNumber = 0;
                            if(e.target.value){
                                pageNumber = e.target.value -1;
                            }
                            gotoPage(pageNumber);
                        }}
                    />

                    <SelectInputMini
                        className={'show_number'}
                        sending={false}
                        selected={''}
                        options={[[50, 'Show 50'], [100, 'Show 100'], [500, 'Show 500'], [1000, 'Show 1000']]}
                        onChange={(value) => {
                            setPageSize(Number(value))
                        }}
                    />

                    <div className={'nav_search_buttons'}>
                        <ButtonLittle onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</ButtonLittle>
                        <ButtonLittle onClick={() => previousPage()} disabled={!canPreviousPage}>Previous Page</ButtonLittle>
                        <ButtonLittle onClick={() => nextPage()} disabled={!canNextPage}>Next Page</ButtonLittle>
                        <ButtonLittle onClick={() => gotoPage(pageCount-1)} disabled={!canNextPage}>{'>>'}</ButtonLittle>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseArchiveTable;