import style from './AppIcon.module.scss';
import {Link} from "react-router-dom";
import archive from '../../../assets/images/icons/archive.svg'
import people from '../../../assets/images/icons/people.svg'
import add_people from '../../../assets/images/icons/add_people.svg'
import add from '../../../assets/images/icons/add.svg'
import mailing_lists from '../../../assets/images/icons/mailing_lists.svg'
import course from '../../../assets/images/icons/course.svg'
import history_log from '../../../assets/images/icons/history_log.svg'

function AppIcon({link, label, type, examDate}){

    let iconImage = ''
    let addStyle = ''
    if(link === 'course-archive'){
        iconImage = archive
    }

    if(link === 'new-course'){
        iconImage = add
    }

    if(link === 'manage-members/invite-member'){
        iconImage = add_people
    }

    if(link === 'manage-members/mailing-lists'){
        iconImage = mailing_lists
    }

    if(link === 'manage-members'){
        iconImage = people
    }

    if(link === 'admin-management'){
        iconImage = people
    }

    if(link === 'admin-management/invite-admin'){
        iconImage = add_people
    }

    if(link === 'admin-management/history-log'){
        iconImage = history_log
    }

    if(type === "course"){
        iconImage = course
    }

    if(link === "admin-management/all-courses"){
        iconImage = archive
    }

    return(
        <Link className={[style.AppIconContainer, addStyle].join(" ")} to={link}>
            <div className={style.AppIcon}>
                <span className={[style.background].join(" ")}>
                    <img className={style.image} src={iconImage} alt={label} />
                </span>
                {label}
                <div className={style.date}>{examDate}</div>
            </div>
        </Link>
    )
}

export default AppIcon