import {useContext, useState} from "react";
import axios from "axios";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import {Link, useNavigate} from "react-router-dom";
import AuthContext from '../../../store/auth-context';
import PasswordInput from "../Inputs/PasswordInput";
import EmailInput from "../Inputs/EmailInput";

function LoginForm(props){

    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);

    const [email, setEmail] = useState(false);
    const [password, setPassword] = useState(false);

    const [sending, setSending] = useState(false);
    const [forceValidation, setForceValidation] = useState(false);
    const [returnMsg, setReturnMsg] = useState('');
    const [returnMsgError, setReturnMsgError] = useState('');
    const [showMessage, setShowMessage] = useState(false);

    function formSubmitHandler(e){
        e.preventDefault();
        setForceValidation(true);

        if(email && password){

            setSending(true);
            setShowMessage(false);

            setTimeout(function(){

                const data = {
                    email: email,
                    password: password,
                    userType : props.userType,
                };

                axios.post('/api/auth/login/', data).then(response => {
                    if(response.status === 200){
                        setReturnMsg(response.data.message);
                        setSending(false);

                        //Check for api error
                        if(response.data.error !== false){
                            setReturnMsgError("ReturnMsgError");
                            setShowMessage(true);
                        }else{
                            authCtx.login(response.data.token, response.data.expirationTime, response.data.userType, response.data.id);
                            if(props.userType === 'member'){
                                navigate('/member');
                            }else{
                                if(response.data.userType === 'super-admin'){
                                    navigate('/super-admin');
                                }else{
                                    navigate('/admin');
                                }
                            }
                        }
                    }else{ connectionError()}
                }).catch(function (error) {
                    if (error) { connectionError()}
                });
            }, 500);
        }
    }

    function connectionError(){
        setReturnMsg("There's been an issue connecting to the server. Please check your internet connection and try again.");
        setSending(false);
        setReturnMsgError("ReturnMsgError");
        setShowMessage(true);
    }

    function emailHandler(status){
        setEmail(status);
    }

    function passwordHandler(status){
        setPassword(status);
    }

    return(
        <>
            <form onSubmit={formSubmitHandler} className={"form"} action="">
                <TrayToggle open={showMessage}>
                    <div className={["ReturnMsg", returnMsgError].join(" ")}>{returnMsg}</div>
                </TrayToggle>
                <div className={"field"}>
                    <EmailInput
                        value={(status) => emailHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Email address"}
                    />
                </div>

                <div className={"field"}>
                    <PasswordInput
                        basicValidation={true}
                        value={(status) => passwordHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Password"}
                    />
                </div>
                <button className={"submit"} type="submit" disabled={sending}>{!sending ? "Sign In" : "Sending..."}</button>
                <Link to={'/forgotten-password/' + props.userType} className={"forgotten_link"}>Forgotten password?</Link>

                {sending && <div className={"sending"} />}
            </form>
        </>
    )
}

export default LoginForm;