import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import BackButton from "../../components/ui-components/BackButton/BackButton";
import CoursesAllTable from "../../components/functional-components/Tables/CoursesAllTable";
import useGet from "../../hooks/useGet";
import Loading from "../../components/ui-components/Loading/Loading";
import SorryTheresAProblem from "../../components/ui-components/SorryTheresAProblem/SorryTheresAProblem";

function CoursesAll(){

    const {returnData, loading} = useGet('/api/courses/', 600)

    return(
        <>
            {
                loading ? <Loading/> : !returnData ? <SorryTheresAProblem message={"There's been an issue loading the courses. Please refresh the app."} /> :
                    <>
                        <PageTitle>All Courses</PageTitle>
                        <CoursesAllTable
                            data={returnData}
                        />
                        <BackButton>Home</BackButton>
                    </>
            }
        </>
    )
}

export default CoursesAll;