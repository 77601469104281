import style from '../Logo/Logo.module.scss';
import logo from '../../../assets/images/logo.svg'

function Logo({type}){

    let logoStyle = ''
    if(type === 'big'){
        logoStyle = style.big
    }

    return(
        <img className={[style.Logo, logoStyle].join(" ")} src={logo} alt='BADC Logo' />
    )
}

export default Logo