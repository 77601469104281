import style from './Footer.module.scss'
import twitter from '../../../assets/images/twitter.svg'
import facebook from '../../../assets/images/facebook.svg'
import instagram from '../../../assets/images/instagram.svg'
import {Link} from "react-router-dom";

const Footer = () => {

    return(
        <footer className={style.Footer}>
            <div className='app_width'>

                <div className={style.columns_container}>
                    <div className={style.column}>
                        <div className={style.title}>BADC Links</div>

                        <div className={style.social_container}>
                            <a className={[style.social_icon, 'hide-text'].join(" ")} rel='noreferrer' target='_blank' href={'https://twitter.com/BADC_UK'}>
                                Twitter
                                <img alt='' src={twitter} />
                            </a>

                            <a className={[style.social_icon, 'hide-text'].join(" ")} rel='noreferrer' target='_blank' href={'https://www.instagram.com/badc_uk/'}>
                                Instagram
                                <img alt='' src={instagram} />
                            </a>

                            <a className={[style.social_icon, 'hide-text'].join(" ")} rel='noreferrer' target='_blank' href={'https://www.facebook.com/BritishAcademyOfDramaticCombat/'}>
                                Facebook
                                <img alt='' src={facebook} />
                            </a>
                        </div>
                        <a href={'http://www.badc.org.uk'} rel='noreferrer' target='_blank' className={style.web_address}>www.badc.org.uk</a>
                    </div>
                    <div className={style.column}>
                        <div className={style.title}>Your Account</div>
                        <Link to={'profile'} className={style.link}>Update Details</Link><br/>
                        <Link to={'profile/security'} className={style.link}>Update Password</Link><br/>
                        <Link to={'profile/contact-preferences'} className={style.link}>Contact Preferences</Link><br/>
                    </div>
                    <div className={style.column}>
                        <div className={style.title}>Legal</div>
                        <Link to={'privacy-policy'} className={style.link}>Privacy Policy</Link><br/>
                    </div>
                </div>

                <div className={style.footer_info}>
                    App by <a className={style.web_address} href='http://www.ddigital.com' rel='noreferrer' target={'_blank'}>D digital</a> <span className={style.divide} /> © Copyright {new Date().getFullYear()}
                </div>


            </div>



        </footer>
    )
}

export default Footer