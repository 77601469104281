import {Link, useParams} from "react-router-dom";
import useGet from "../hooks/useGet";
import Loading from "../components/ui-components/Loading/Loading";
import Logo from "../components/ui-components/Logo/Logo";
import ExpiredInvite from "../components/functional-components/ExpiredInvite";
import CreateProfileForm from "../components/functional-components/Forms/CreateProfileForm";

function CreateProfile(){

    const params = useParams();
    const url = '/api/invite/?invite_string=' + params.InviteString + '&user_type=member'
    const {loading, returnMsg, returnData} = useGet(url, 2000)

    return(
        loading ? <Loading /> : !returnMsg ?
            <>
                <div className='login_container'>
                    <Link to={'/'}><Logo type={'big'} /></Link>
                    <div className={'login_box'}>
                        <h1>Become a BADC Member</h1>
                        <CreateProfileForm hiddenEmail={returnData.email} />
                    </div>
                </div>
            </> :
            <>
                <div className='login_container'>
                    <Link to={'/'}><Logo type={'big'} /></Link>
                    <div className={'login_box'}>
                        <ExpiredInvite />
                    </div>
                </div>
            </>
    )
}

export default CreateProfile;