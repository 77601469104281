import {useContext} from "react"
import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import CourseSidePanel from "../../components/ui-components/CourseSidePanel/CourseSidePanel";
import Loading from "../../components/ui-components/Loading/Loading";
import {useParams} from "react-router-dom";
import useGet from "../../hooks/useGet";
import BackButton from "../../components/ui-components/BackButton/BackButton";
import Section from "../../components/ui-components/Section/Section";
import assessGrade from "../../utils/assessGrade";
import isDateInPast from "../../utils/isDateInPast";
import UserContext from "../../store/user-context";
import Certificate from "../../components/functional-components/Certificate/Certificate";
import GradeStudentView from "../../components/functional-components/Tables/GradeStudentView";
import SorryTheresAProblem from "../../components/ui-components/SorryTheresAProblem/SorryTheresAProblem";

function CourseMember(){

    //Hooks
    const params = useParams()
    const {loading, returnData: course} = useGet('/api/courses/' + params.course_id, 250)
    const userCtx = useContext(UserContext)

    //Set grade and certificate
    const grade = userCtx.grades.filter((course) => (course.courseRef === params.course_id && course.confirmed === '1'))
    const certificate = grade[0]
    let result = '';
    if(certificate){
        if(certificate.percentage){
            result = assessGrade(certificate.percentage, certificate.level)
        }
    }

    return(
        <>
            {loading ? <Loading /> : !course ? <SorryTheresAProblem message={"There's been an issue loading this course. Please refresh the app."} /> :
                <>
                    <PageTitle>{course.weaponsSystem}</PageTitle>

                    <div className={'two_columns'}>

                        <div className={'left_column narrower-certificate-width'}>

                            <Section table={true} title={"Grade"}>
                                {certificate ?
                                    <GradeStudentView
                                        data={grade}
                                    />
                                    : <p>You have not been graded yet.</p>
                                }
                            </Section>

                            {(certificate && (result !== "Failed")) &&
                                <Section title={
                                    isDateInPast(certificate.expiryDate) ? "Expired Certificate" : "Certificate"
                                }>
                                    <Certificate
                                        name={userCtx.name}
                                        weaponsSystem={course.weaponsSystem}
                                        level={course.level}
                                        teachers={certificate.teachers}
                                        trainingProvider={certificate.trainingProvider}
                                        examiner={course.examiner}
                                        percentage={certificate.percentage}
                                        examDate={certificate.examDate}
                                        expiryDate={certificate.expiryDate}
                                    />
                                </Section>
                            }

                            <BackButton>Home</BackButton>
                        </div>

                        {course &&
                            <CourseSidePanel
                                trainingProvider={course.trainingProvider}
                                teachers={course.teachers}
                                examiner={course.examiner}
                                level={course.level}
                                examDate={course.exam_date}
                            />
                        }

                    </div>
                </>
            }
        </>
    )
}

export default CourseMember;