import style from './AccountSidePanel.module.scss';
import {Link} from "react-router-dom";
import Button from "../Button/Button";
import shield from "../../../assets/images/shield.svg"

function AccountSidePanel({userType, name, firstName, surName, email, phone, tcr, status, level, lapsed}){
    return(
        (userType !== 'member') ?
            <div className={[style.AccountSidePanel, style.AccountSidePanelAdmin].join(" ")}>
                <div className={style.avatar} style={{backgroundImage: "url(" + shield + ")"}}><span>{firstName.substring(0,1)}{surName.substring(0,1)}</span></div>
                <div className={style.name}>{name}</div>

                <div className={style.break} />
                <div className={style.text}>{email}</div>
                <Link to={'profile'} className={style.link}>Update Email</Link>

                <div className={style.break} />
                <div className={style.text}>{phone}</div>
                <Link to={'profile'} className={style.link}>Update Phone</Link>

                <div className={style.break} />

                <Link to={'profile'}><Button>Update Details</Button></Link>

            </div>
            :
            <div className={style.AccountSidePanel}>
                <div className={style.avatar} style={{backgroundImage: "url(" + shield + ")"}}><span>{firstName.substring(0,1)}{surName.substring(0,1)}</span></div>
                <div className={style.name}>{firstName} {surName}</div>

                <div className={style.text}><b>TCR:</b> {tcr}</div>

                {level && <div className={style.text}><b>Level:</b> {level} {lapsed}</div>}

                {status &&
                    <div className={style.text}>
                        <b>Training Status:</b> <br/>
                        {status}
                    </div>
                }

                <div className={style.break} />
                <div className={style.text}>{email}</div>
                <Link to={'profile'} className={style.link}>Update Email</Link>

                <div className={style.break} />
                <div className={style.text}>{phone}</div>
                <Link to={'profile'} className={style.link}>Update Phone</Link>

                <div className={style.break} />

                <Link to={'profile'}><Button>Update Details</Button></Link>
            </div>
    )
}

export default AccountSidePanel