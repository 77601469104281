import style from './ContactPreferencesPanel.module.scss'
import ToggleSwitch from "../../ui-components/ToggleSwitch/ToggleSwitch";
import usePost from "../../../hooks/usePost";

const ContactPreferencesPanel = ({data}) => {

    const {postData} = usePost()

    const updatePreferencesHandler = (status, news) => {
        const data = {
            news_type: news,
            status: status
        };
        postData(data, '/api/contact-preferences/')
    }

    return(
        <div className={style.ContactPreferencesPanel}>

            <div className={style.mailing_item}>
                <div>
                    <div className={style.title}>Membership Info</div>
                    <div>Useful email about your account - for example when you receive a qualification.</div>
                </div>
                <ToggleSwitch
                    initiallyOn={data.admin_alerts}
                    onText={"Subscribed"}
                    offText={"Not subscribed"}
                    value={(value) => updatePreferencesHandler(value, 'admin_alerts')}
                />
            </div>

            <div className={style.mailing_item}>
                <div>
                    <div className={style.title}>BADC News</div>
                    <div>General occasional news from BADC.</div>
                </div>
                <ToggleSwitch
                    initiallyOn={data.general_news}
                    onText={"Subscribed"}
                    offText={"Not subscribed"}
                    value={(value) => updatePreferencesHandler(value, 'general_news')}
                />
            </div>

            <div className={style.mailing_item}>
                <div>
                    <div className={style.title}>Upcoming Courses</div>
                    <div>Find out about the BADC course schedule and contact info to get involved</div>
                </div>
                <ToggleSwitch
                    initiallyOn={data.upcoming_courses}
                    onText={"Subscribed"}
                    offText={"Not subscribed"}
                    value={(value) => updatePreferencesHandler(value, 'upcoming_courses')}
                />
            </div>
        </div>
    )

}

export default ContactPreferencesPanel