import React, {useState} from "react";

const NotificationsContext = React.createContext({
    setNotifications: null,
    markAsRead: null,
    notifications: null,
    setTempNotification: null,
    tempNotification: null
});

export const NotificationsContextProvider = (props) => {

    const [notifications, setNotifications] = useState('')
    const [tempNotification, setTempNotification] = useState('');

    function setNotificationsHandler(userNotifications){

        //Set notifications
        if(userNotifications && !userNotifications.error){

            const notifications = userNotifications.filter(notification => (notification.type !== "temp"))

            //Set temp notification
            const tempNotifications = notifications.filter(notification => (notification.type === "temp"))[0]
            setTempNotificationHandler(tempNotifications)
            setNotifications(notifications)
        }else{
            setNotifications(false)
        }
    }

    function setTempNotificationHandler(userData){
        setTempNotification(userData)
    }

    function markAsReadHandler(id, type){
        //Check if it's already read
        const readNotification = notifications.filter(item => (item.id === id) && (item.type === type))
        if(readNotification.length){
            if(!readNotification[0].read){
                let updateNotifications = []
                notifications.map(notification => {
                    let read = notification.read
                    if((notification.id === id) && (notification.type === type)){
                        read = true
                    }
                    const addNotification = {
                        'message': notification.message,
                        'type': notification.type,
                        'id': notification.id,
                        'link_ref': notification.link_ref,
                        'weaponsSystem': notification.weaponsSystem,
                        'read': read
                    }
                    updateNotifications.push(addNotification)
                    return false
                })
                setNotificationsHandler(updateNotifications)
            }
        }
    }

    const contextValue = {
        setNotifications: setNotificationsHandler,
        setTempNotification: setTempNotificationHandler,
        markAsRead: markAsReadHandler,
        notifications: notifications,
        tempNotification: tempNotification
    };

    return (
        <NotificationsContext.Provider value={contextValue}>
            {props.children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsContext;