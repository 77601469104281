import {Navigate, Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import AdminLogin from "./pages/AdminLogin";
import CreateProfile from "./pages/CreateProfile";
import React from "react";
import CreateAdminProfile from "./pages/CreateAdminProfile";
import Home from "./pages/account-pages/Home";
import MembersOnly from "./components/functional-components/Routes/MembersOnly";
import AdminsOnly from "./components/functional-components/Routes/AdminsOnly";
import SuperAdminsOnly from "./components/functional-components/Routes/SuperAdminsOnly";
import InviteMember from "./pages/account-pages/InviteMember";
import Members from "./pages/account-pages/Members";
import SubMenuPageWrapper from "./components/ui-components/SubMenuPageWrapper/SubMenuPageWrapper";
import PersonalDetails from "./pages/account-pages/profile-pages/PersonalDetails";
import Membership from "./pages/account-pages/profile-pages/Membership";
import ContactPreferences from "./pages/account-pages/profile-pages/ContactPreferences";
import Security from "./pages/account-pages/profile-pages/Security";
import CloseAccount from "./pages/account-pages/profile-pages/CloseAccount";
import InviteAdmin from "./pages/account-pages/InviteAdmin";
import AdminManagement from "./pages/account-pages/AdminManagement";
import ForgottenPassword from "./pages/ForgottenPassword";
import ResetPassword from "./pages/ResetPassword";
import CourseAdd from "./pages/account-pages/CourseAdd";
import CourseAdmin from "./pages/account-pages/CourseAdmin";
import CourseUpdate from "./pages/account-pages/CourseUpdate";
import CourseArchive from "./pages/account-pages/CourseArchive";
import CourseArchived from "./pages/account-pages/CourseArchived";
import MemberAreaWrapper from "./components/functional-components/MemberAreaWrapper";
import AdminAreaWrapper from "./components/functional-components/AdminAreaWrapper";
import GradesConfirm from "./pages/account-pages/GradesConfirm";
import Privacy from "./pages/account-pages/Privacy";
import History from "./pages/account-pages/History";
import InitialContactPreferences from "./pages/InitialContactPreferences";
import MailingLists from "./pages/account-pages/MailingLists";
import CoursesAll from "./pages/account-pages/CoursesAll";
import CourseMember from "./pages/account-pages/CourseMember";
import CourseSuperAdmin from "./pages/account-pages/CourseSuperAdmin";

function App() {

    return(
        <Routes>
            <Route path='login' element={<Login />} />
            <Route path='admin-login' element={<AdminLogin />} />
            <Route path="create-profile/:InviteString" element={<CreateProfile />} />
            <Route path="create-admin-profile/:InviteString" element={<CreateAdminProfile />} />
            <Route path='forgotten-password/:userType' element={<ForgottenPassword />} />
            <Route path="reset-password/:ResetString" element={<ResetPassword />} />
            <Route path="contact-preferences" element={<InitialContactPreferences />} />

            <Navigate from="/" to={'login'} />
            <Route path="*" element={<NotFound/>} />

            <MembersOnly>
                <Route path="member" element={<MemberAreaWrapper/>}>
                    <Route path="/" element={<Home />} />
                    <Route path="/certificate/:certificate_id" element={<Home />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/course/:course_id" element={<CourseMember />} />

                    <Route path="/profile" element={<SubMenuPageWrapper submenu={'profile'}/>}>
                        <Route path="/" element={<PersonalDetails />} />
                        <Route path="/membership" element={<Membership />} />
                        <Route path="/contact-preferences" element={<ContactPreferences />} />
                        <Route path="/security" element={<Security />} />
                        <Route path="/close-account" element={<CloseAccount />} />
                    </Route>
                </Route>
            </MembersOnly>

            <AdminsOnly>
                <Route path="admin" element={<AdminAreaWrapper/>}>
                    <Route path="/" element={<Home />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/new-course" element={<CourseAdd />} />
                    <Route path="/course/:course_id/update" element={<CourseUpdate />} />
                    <Route path="/course/:course_id" element={<CourseAdmin />} />
                    <Route path="/course-archive" element={<CourseArchive />} />
                    <Route path="/course-archive/:course_id" element={<CourseArchived />} />
                    <Route path="/grades-confirm/:course_id" element={<GradesConfirm />} />

                    <Route path="/manage-members" element={<SubMenuPageWrapper submenu={'members'}/>}>
                        <Route path="/" element={<Members />} />
                        <Route path="/invite-member" element={<InviteMember />} />
                        <Route path="/mailing-lists" element={<MailingLists />} />
                    </Route>

                    <Route path="/profile" element={<SubMenuPageWrapper submenu={'profile'}/>}>
                        <Route path="/" element={<PersonalDetails />} />
                        <Route path="/membership" element={<Membership />} />
                        <Route path="/contact-preferences" element={<ContactPreferences />} />
                        <Route path="/security" element={<Security />} />
                        <Route path="/close-account" element={<CloseAccount />} />
                    </Route>
                </Route>
            </AdminsOnly>

            <SuperAdminsOnly>
                <Route path="super-admin" element={<AdminAreaWrapper/>}>
                    <Route path="/" element={<Home />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/new-course" element={<CourseAdd />} />
                    <Route path="/course/:course_id/update" element={<CourseUpdate />} />
                    <Route path="/course-archive/:course_id/update" element={<CourseUpdate />} />
                    <Route path="/course/:course_id" element={<CourseAdmin />} />
                    <Route path="/course-archive" element={<CourseArchive />} />
                    <Route path="/other-tutor-course/:course_id" element={<CourseSuperAdmin />} />
                    <Route path="/other-tutor-course/:course_id/update" element={<CourseUpdate />} />
                    <Route path="/grades-confirm/:course_id" element={<GradesConfirm />} />
                    <Route path="/course-archive/:course_id" element={<CourseSuperAdmin />} />

                    <Route path="/admin-management" element={<SubMenuPageWrapper submenu={'admins'}/>}>
                        <Route path="/" element={<AdminManagement />} />
                        <Route path="/invite-admin" element={<InviteAdmin />} />
                        <Route path="/all-courses" element={<CoursesAll />} />
                        <Route path="/history-log" element={<History />} />
                    </Route>

                    <Route path="/manage-members" element={<SubMenuPageWrapper submenu={'members'}/>}>
                        <Route path="/" element={<Members />} />
                        <Route path="/invite-member" element={<InviteMember />} />
                        <Route path="/mailing-lists" element={<MailingLists />} />
                    </Route>

                    <Route path="/profile" element={<SubMenuPageWrapper submenu={'profile'}/>}>
                        <Route path="/" element={<PersonalDetails />} />
                        <Route path="/membership" element={<Membership />} />
                        <Route path="/contact-preferences" element={<ContactPreferences />} />
                        <Route path="/security" element={<Security />} />
                        <Route path="/close-account" element={<CloseAccount />} />
                    </Route>
                </Route>
            </SuperAdminsOnly>
        </Routes>
    )
}

export default App;