import React from 'react';
import PageTitle from "../../../components/ui-components/PageTitle/PageTitle";
import UpdatePasswordForm from "../../../components/functional-components/Forms/UpdatePasswordForm";

function Security() {
    return (
        <>
            <PageTitle>Security</PageTitle>
            <UpdatePasswordForm />
        </>
    );
}

export default Security;