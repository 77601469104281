import React from 'react';
import {Outlet} from "react-router-dom";
import SubMenu from "../SubMenu/SubMenu";
import style from './SubMenuPageWrapper.module.scss';

function SubMenuPageWrapper({submenu}) {

    return (
        <>
            <div className={style.sub_menu_page}>

                <SubMenu
                    subMenuType={submenu}
                />

                <div className={style.sub_menu_body}>
                    <Outlet />
                </div>

            </div>
        </>
    );
}

export default SubMenuPageWrapper;