export const ADMIN_COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Phone',
        accessor: 'phone',
    },
    {
        Header: 'Examiner',
        accessor: d => d.examiner.toString()
    },
    {
        Header: 'Super-Admin',
        accessor: d => d.super_admin.toString(),
    },
    {
        Header: 'Delete',
        accessor: d => d.super_admin.toString(),
    }
]