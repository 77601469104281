import style from './Button.module.scss';

function Button({onClick, children, position, colour}){

    let buttonPosition = '';
    if(position === "right"){
        buttonPosition = style.right
    }

    if(position === "top_right"){
        buttonPosition = style.top_right
    }

    if(colour === "green"){
        buttonPosition = style.green
    }

    return(
        <button
            onClick={onClick}
            className={[style.Button, buttonPosition].join(" ")}
        >
            <span className={style.text}>{children}</span>
            {children}
        </button>
    )
}

export default Button;