import React, {useMemo} from 'react';
import {useTable, usePagination, useSortBy, useGlobalFilter} from "react-table";
import {COLUMNS} from "./columns/addGradesColumns";
import './table.scss';
import RemoveButton from "../../ui-components/RemoveButton/RemoveButton";
import PercentageInput from "../Inputs/PercentageInput";
import Grade from "../Grade/Grade";

function AddGrades({data, removeMember, addGrade, showGrade, courseLevel}) {

    const columns = useMemo(() => COLUMNS, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns: columns,
        data: data,
        initialState: { pageSize: 9999 }
    }, useGlobalFilter, useSortBy, usePagination);

    function cellContentHandler(columnTitle, content, id, percent, displayGrade) {

        //Remove
        if(columnTitle === "Remove"){
            return(
                <RemoveButton onClick={() => removeMember(id)}>Remove</RemoveButton>
            )
        }

        if(columnTitle === "Enter Percentage"){
            return(
                <>
                    <PercentageInput
                        value={(percent) => addGrade(percent, id)}
                        showGrade={(percent) => showGrade(percent, id)}
                    />
                </>
            )
        }

        if(columnTitle === "Grade"){
            return(
                <>
                    {displayGrade ?
                        <Grade
                            percent={percent}
                            courseLevel={courseLevel}
                        />
                        : "–"
                    }
                </>
            )
        }
        return content
    }

    return (
        <div className={'table_container'}>
            <div className={'table_container_padding'}>
                <table style={{marginBottom: "0.5rem"}} {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup, i) =>(
                        <tr {...headerGroup.getHeaderGroupProps} key={i}>
                            {headerGroup.headers.map((column, i) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                    {column.render('Header')}
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {

                                    return <td{...cell.getCellProps()} key={cell.getCellProps().key}>
                                        {cellContentHandler(cell.column.Header, cell.render('Cell'), row.original.id, row.original.percent, row.original.showGrade)}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AddGrades;