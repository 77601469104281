import style from './Section.module.scss';

function Section(props){

    return(
        <div className={[style.Section, props.table ? style.SectionTableWrap : null, props.appWrap ? style.SectionAppWrap : null].join(" ")}>
            <h2 className={style.title}>{props.title}</h2>
            <div className={style.body}>
                {props.children}
            </div>
        </div>
    )
}

export default Section;