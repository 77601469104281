import React, {useContext, useMemo, useState} from 'react';
import {useTable, usePagination, useSortBy, useGlobalFilter} from "react-table";
import {ADMIN_COLUMNS} from "./columns/adminColumns";
import './table.scss';
import axios from "axios";
import AuthContext from "../../../store/auth-context";
import green_cross from '../../../assets/images/green_cross.svg';

function AdminTable(props) {

    const columns = useMemo(() => ADMIN_COLUMNS, []);
    const data = props.data;
    const authCtx = useContext(AuthContext);

    const initialExaminers = []
    data.map(item => {
            if(item.examiner === '1'){
                initialExaminers.push(item.id)
            }
            return false
        }
    )

    const [examiners, setExaminers] = useState(initialExaminers);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({
        columns: columns,
        data: data,
        initialState: { pageSize: 9999 }
    }, useGlobalFilter, useSortBy, usePagination);

    function switchExaminerHandler(id, status){

        if(status){
            setExaminers(examiners => [...examiners, id] );
        }else{
            let remove = examiners.filter(item => item !== id)
            setExaminers(remove);
        }

        const data = {
            status: status
        };

        const config = {
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + authCtx.token,
            },
        };

        axios.post('/api/examiners/' + id + '', data, config).then();
    }

    function cellContentHandler(columnTitle, content, id){

        //Examiner
        if(columnTitle === "Examiner"){

            let examinerStatus = <button className={'table_link'} onClick={() => switchExaminerHandler(id, true)}>Make Examiner</button>
            examiners.map(item => {
                if (parseInt(id) === parseInt(item)) {
                    examinerStatus = <div className={['table_badge', 'table_badge_with_button'].join(' ')}>
                        Examiner
                        <button
                            style={{ backgroundImage: "url(" + green_cross + ")"}}
                            onClick={() => switchExaminerHandler(id, false)}
                            className={['hide-text', 'remove_button'].join(' ')}>
                            remove
                        </button>
                    </div>;
                }
                return examinerStatus
            })

            return examinerStatus
        }

        //Super-Admin
        if(columnTitle === "Super-Admin"){
            if(content.props.value === '1'){
                return <div className={'table_badge'}>Super-Admin</div>
            }else{
                return "–"
            }
        }

        //Delete
        if(columnTitle === "Delete"){
            if(parseInt(id) !== parseInt(authCtx.id)){
                return <button className={'table_link'} onClick={() => props.deleteConfirm(id)}>Delete</button>
            }else{
                return "–"
            }
        }

        return content
    }

    return (
        <div className={'table_container'}>
            <div className={'table_container_padding'}>
                <table className={'min_cell_height'} {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup, i) =>(
                        <tr {...headerGroup.getHeaderGroupProps} key={i}>
                            {headerGroup.headers.map((column, i) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                    {column.render('Header')}
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {

                                    return <td{...cell.getCellProps()} key={cell.getCellProps().key}>
                                        {cellContentHandler(cell.column.Header, cell.render('Cell'), row.original.id)}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AdminTable;