import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import useGet from "../../hooks/useGet";
import HistoryList from "../../components/functional-components/HistoryList/HistoryList";
import Button from "../../components/ui-components/Button/Button";
import {useEffect, useState} from "react";
import Loading from "../../components/ui-components/Loading/Loading";
import BackButton from "../../components/ui-components/BackButton/BackButton";

function History(){

    const [page, setPage] = useState(0)
    const {returnData, loading} = useGet('/api/history/' + page, 600)

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const nextPageHandler = () => {
        setPage(page + 1)
    }

    const prevPageHandler = () => {
        setPage(page - 1)
    }

    return(
        <>
            {returnData ?
                <>
                    <PageTitle>History Log (Page {page + 1})</PageTitle>
                    <HistoryList
                        loading={loading}
                        data={returnData.history}
                    />
                    {(page > 0) || (returnData.nextPage) ? <>
                        <div style={{display: "flex", marginTop: "3rem"}}>
                            {(page > 0) ? <><Button onClick={() => prevPageHandler()}>Previous Page</Button><span style={{width: "1rem"}} /></> : null}
                            {returnData.nextPage && <Button onClick={() => nextPageHandler()}>Next Page</Button>}
                        </div>
                    </> : null}

                    <BackButton>Home</BackButton>
                </>
                : <Loading />}
        </>
    )
}

export default History;