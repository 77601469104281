import ModalButtons from "../../ui-components/ModalButtons/ModalButtons";
import usePost from "../../../hooks/usePost";
import {useEffect} from "react";

const DeleteGrade = ({memberId, courseId, closeModal, updateEnrolledPostDeleteHandler}) => {

    const {sending, returnPostData, postData} = usePost()

    function deleteGradeHandler(){
        const data = {
            courseId: courseId,
            memberId: memberId
        }

        postData(data, '/api/grades/delete', 1000)
    }

    useEffect(() => {
        if(returnPostData){
            updateEnrolledPostDeleteHandler(memberId)
            closeModal()
        }

    }, [closeModal, memberId, returnPostData, updateEnrolledPostDeleteHandler])

    return(
        <>
            <h1>Delete Grade</h1>
            <br />
            <div className='ReturnMsg ReturnMsgError'>
                Are you sure you want to delete this grade? The certificate will be deleted and the student's level reassesed.
            </div>

            <ModalButtons
                button1Text={'Delete Grade'}
                button1Function={() => deleteGradeHandler()}
                closeModal={closeModal}
            />
            {sending && <div className={'sending'} />}
        </>
    )
}

export default DeleteGrade