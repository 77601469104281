import React, {useEffect, useState, useContext} from "react"
import UserContext from "./user-context";
import CoursesContext from "./courses-context";
import NotificationsContext from "./notification-context";

const AuthContext = React.createContext({
    token: null,
    login: null,
    logout: null,
    userType: null,
    id: null
})

let logoutTimer = false

export const AuthContextProvider = (props) => {

    //Hooks
    const userCtx = useContext(UserContext)
    const coursesCtx = useContext(CoursesContext)
    const notificationsCtx = useContext(NotificationsContext)

    const storedToken = localStorage.getItem('token')
    const storedUserType = localStorage.getItem('userType')
    const storedId = localStorage.getItem('id')
    const [token, setToken] = useState(storedToken)
    const [expiry, setExpiry] = useState(false)
    const [userType, setUserType] = useState(storedUserType)
    const [id, setId] = useState(storedId)

    useEffect(()=>{
        let expirationTime = localStorage.getItem('expirationTime')
        if(expirationTime){
            expirationTime = (expirationTime * 1000) //Convert to milliseconds
            const currentTime = new Date().getTime()
            if((currentTime > expirationTime) || (expirationTime < 86400000)){
                logoutHandler()
            }else{
                autoLogout(expirationTime)
            }
        }
    })

    function logoutHandler(){
        localStorage.removeItem('token')
        localStorage.removeItem('expirationTime')
        localStorage.removeItem('id')
        setToken(false)
        setExpiry(false)
        setUserType(false)
        setId(false)

        //Reset other stores
        userCtx.setUser(false)
        coursesCtx.setCourses(false)
        notificationsCtx.setNotifications(false)
    }

    function autoLogout(expirationTime){
        if(logoutTimer){
            clearTimeout(logoutTimer)
        }

        const currentTime = new Date().getTime()
        const duration = expirationTime - currentTime

        logoutTimer = setTimeout(()=>{
            logoutHandler()
        }, duration)
    }

    function loginHandler(token, expiry, userType, id){
        localStorage.setItem('token', token)
        localStorage.setItem('expirationTime', expiry)
        localStorage.setItem('userType', userType)
        localStorage.setItem('id', id)
        setToken(token)
        setExpiry(expiry)
        setUserType(userType)
        setId(id)
    }

    const contextValue = {
        token: token,
        expiry: expiry,
        userType: userType,
        id: id,
        login: loginHandler,
        logout: logoutHandler
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext