export const COLUMNS = [
    {
        Header: 'Weapons System',
        accessor: 'weaponsSystem'
    },
    {
        Header: 'Status',
        accessor: 'status'
    },
    {
        Header: 'Details',
        accessor: 'details'
    }
]