import CourseForm from "../../components/functional-components/Forms/CourseForm";
import PageTitle from "../../components/ui-components/PageTitle/PageTitle";

function CourseAdd(){
    return(
        <>
            <PageTitle>Create New Course</PageTitle>
            <CourseForm />
        </>
    )
}

export default CourseAdd;