export const COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Level',
        accessor: 'level'
    },
    {
        Header: 'Add to Course',
        accessor: 'add'
    }
]