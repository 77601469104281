import React from 'react';
import PageTitle from "../../../components/ui-components/PageTitle/PageTitle";
import ContactPreferencesPanel
    from "../../../components/functional-components/ContactPreferencesPanel/ContactPreferencesPanel";
import useGet from "../../../hooks/useGet";
import Loading from "../../../components/ui-components/Loading/Loading";

function ContactPreferences() {

    const {loading, returnData} = useGet('/api/contact-preferences/', 350)

    return (
        <>
            <PageTitle>Contact Preferences</PageTitle>
            {
                loading ? <Loading/> : returnData ?
                    <div className={'page'}>
                        <ContactPreferencesPanel
                            data={returnData}
                        />
                    </div> : null
            }
        </>
    )
}

export default ContactPreferences;