import style from './CourseIconsWrapper.module.scss'

function CourseIconsWrapper(props){
    return(
        <div className={[style.courseWrapper].join(" ")}>
            {props.children}
        </div>
    )
}

export default CourseIconsWrapper