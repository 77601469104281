import {useContext, useState} from "react";
import axios from "axios";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import {useNavigate, useParams} from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import PasswordInput from "../Inputs/PasswordInput";
import PasswordConfirmInput from "../Inputs/PasswordConfirmInput";

function ResetPasswordForm() {

    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const params = useParams();

    const [password, setPassword] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState(false);

    const [sending, setSending] = useState(false);
    const [forceValidation, setForceValidation] = useState(false);
    const [returnMsg, setReturnMsg] = useState('');
    const [returnMsgError, setReturnMsgError] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    function formSubmitHandler(e) {
        e.preventDefault();
        setForceValidation(true);

        if(password && passwordConfirm)
        {
            setSending(true);
            setShowMessage(false);
            setShowSuccessMessage(false);

            const data = {
                resetString: params.ResetString,
                password: password
            };

            setTimeout(function(){

                axios.post('/api/auth/reset-password', data).then(response => {

                    if(response.status === 200){
                        setReturnMsg(response.data.message);
                        setSending(false);

                        //Check for api error
                        if(response.data.error !== false){
                            setReturnMsgError("ReturnMsgError");
                            setShowMessage(true);
                        }else{
                            authCtx.login(response.data.token, response.data.expirationTime, response.data.userType, response.data.id);
                            if(response.data.userType === 'member'){
                                navigate('/member');
                            }else{
                                if(response.data.userType === 'super-admin'){
                                    navigate('/super-admin');
                                }else{
                                    navigate('/admin');
                                }
                            }
                        }
                    }else{ connectionError()}
                }).catch(function (error) {
                    if (error) { connectionError()}
                });
            }, 500);
        }
    }

    function connectionError(){
        setReturnMsg("There's been an issue connecting to the server. Please check your internet connection and try again.");
        setSending(false);
        setReturnMsgError("ReturnMsgError");
        setShowMessage(true);
    }

    function passwordHandler(status){
        setPassword(status);
    }
    function passwordConfirmHandler(status){
        setPasswordConfirm(status);
    }

    return (
        <>
            <form onSubmit={formSubmitHandler} className={"form"} action="">

                <div className={"field"}>
                    <PasswordInput
                        value={(status) => passwordHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Password"}
                    />
                </div>

                <div className={"field"}>
                    <PasswordConfirmInput
                        otherPassword={password}
                        value={(status) => passwordConfirmHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Confirm Password"}
                    />
                </div>

                <TrayToggle open={showMessage}>
                    <div className={["ReturnMsg", returnMsgError].join(" ")}>{returnMsg}</div>
                </TrayToggle>
                <TrayToggle open={showSuccessMessage}>
                    <div className={'ReturnMsg'}>{returnMsg}</div>
                </TrayToggle>

                <button className={"submit"} type="submit"
                        disabled={sending}>{!sending ? "Change Password" : "Sending..."}</button>

                {sending && <div className={"sending"}/>}
            </form>
        </>
    )
}

export default ResetPasswordForm;