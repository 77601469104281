import React from 'react';
import {NavLink} from "react-router-dom";
import style from './SubMenuButton.module.scss';

function SubMenuButton(props) {
    return (
        <NavLink end activeClassName={style.selected} to={props.to}>
            <button className={style.SubMenuButton}>{props.children}</button>
        </NavLink>
    );
}

export default SubMenuButton;