import {useEffect, useRef, useState} from "react";

function PasswordConfirmInput(props){

    const input = useRef();
    const [error, setError] = useState(true)
    const [showError, setShowError] = useState(false)

    const validateHandler = () => {
        props.value(false);
        switch(true) {
            case (input.current.value.length === 0):
                setError("Please confirm password")
                return
            case (input.current.value !== props.otherPassword):
                setError("Passwords do not match")
                return
            default:
                setError(false)
                setShowError(false)
                props.value(input.current.value)
                return
        }
    }

    const showErrorHandler = () => {
        validateHandler()
        setShowError(true)
    }

    useEffect(() => {
        if(props.forceValidation){
            showErrorHandler()
        }
    })

    return(
        <>
            <label htmlFor="password" className={"label"}>{props.label}:</label>
            {(error && showError) && <div className={"bubble"}>{error}</div>}
            <input
                ref={input}
                type="password"
                name="password"
                autoComplete="new-password"
                onBlur={showErrorHandler}
                onChange={validateHandler}
                className={"input"}
                disabled={props.sending}
            />
        </>
    )
}

export default PasswordConfirmInput;