import {useEffect, useRef, useState} from "react";

function PasswordInput(props){

    const input = useRef();
    const [error, setError] = useState(true)
    const [showError, setShowError] = useState(false)

    const validateHandler = () => {
        props.value(false);
        if(props.basicValidation){
            switch(true) {
                case (input.current.value.length === 0):
                    setError("Please enter a password")
                    return
                case (!input.current.value.replace(/\s/g, '').length):
                    setError("Please enter a password")
                    return
                case (input.current.value.length > 80):
                    setError("Password should be less than 80 characters")
                    return
                default:
                    setError(false);
                    setShowError(false);
                    props.value(input.current.value);
                    return
            }
        }else{
            switch(true) {
                case (input.current.value.length === 0):
                    setError("Please enter a password")
                    return
                case (!input.current.value.replace(/\s/g, '').length):
                    setError("Please enter a password")
                    return
                case (RegExp("\\s+").test(input.current.value)):
                    setError("Password must not contain any spaces")
                    return
                case (input.current.value.length < 6 ):
                    setError("Password should be at least 6 characters long")
                    return
                case (input.current.value.length > 80):
                    setError("Password should be less than 80 characters")
                    return
                case (!/\d/.test(input.current.value)):
                    setError("Password should contain a number")
                    return
                case (!(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/).test(input.current.value)):
                    setError("Password must contain at least 1 lowercase and uppercase letter")
                    return
                default:
                    setError(false);
                    setShowError(false);
                    props.value(input.current.value);
                    return
            }
        }
    }

    const showErrorHandler = () => {
        validateHandler()
        setShowError(true)
    }

    useEffect(() => {
        if(props.forceValidation){
            showErrorHandler()
        }
    })

    return(
        <>
            <label htmlFor="password" className={"label"}>{props.label}:</label>
            {(error && showError) && <div className={"bubble"}>{error}</div>}
            <input
                ref={input}
                type="password"
                name="password"
                autoComplete={props.basicValidation ? "current-password" : "new-password"}
                maxLength={80}
                onBlur={showErrorHandler}
                onChange={validateHandler}
                className={"input"}
                disabled={props.sending}
            />
        </>
    )
}

export default PasswordInput;