import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import CourseUpdateForm from "../../components/functional-components/Forms/CourseUpdateForm";

function CourseUpdate(){
    return(
        <>
            <PageTitle>Edit Course</PageTitle>
            <CourseUpdateForm />
        </>
    )
}

export default CourseUpdate;