import {useRef, useState} from "react";
import chevronDown from '../../../assets/images/chevron_down_brown.svg'
import style from './selectInputMini.module.scss'

function SelectInputMini({options, onChange, sending, selected, show_number}){

    const select = useRef();
    const [selectDisplayText, setSelectDisplayText] = useState(options[0][1])

    const validateHandler = () => {
        setSelectDisplayText(select.current[select.current.selectedIndex].label)
        onChange(select.current.value);
    }

    return(
        <>
            <div className={[style.mini_select_wrapper, 'show_number'].join(" ")}>
                <select
                    className={style.select}
                    ref={select}
                    onChange={validateHandler}
                    disabled={sending}
                    defaultValue={selected}
                >
                    {options.map(
                        option => {
                            return <option key={option[1]} value={option[0]}>{option[1]}</option>
                        }
                    )}
                </select>
                <div className={style.select_display_text}>
                    {selectDisplayText}
                    <span className={style.select_chevron}>
                        <img src={chevronDown} alt="" />
                    </span>
                </div>
            </div>
        </>
    )
}

export default SelectInputMini;