import React from 'react';
import PageTitle from "../../../components/ui-components/PageTitle/PageTitle";

function Membership() {
    return (
        <>
            <PageTitle>Membership</PageTitle>
        </>
    );
}

export default Membership;