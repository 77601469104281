import React, {useMemo} from 'react';
import {useTable, usePagination, useSortBy, useGlobalFilter} from "react-table";
import {MembersColumns} from "./columns/membersColumns";
import './table.scss';
import GlobalFilter from "./GlobalFilter";
import SelectInputMini from "../Inputs/SelectInputMini";
import ButtonLittle from "../../ui-components/ButtonLittle/ButtonLittle";
import MoreTableButton from "../../ui-components/MoreTableButton/MoreTableButton";
import assesLevel from '../../../utils/assessLevel'

function MembersTable(props) {

    const columns = useMemo(() => MembersColumns, []);
    const data = props.data;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        pageCount,
        prepareRow,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        setGlobalFilter,
        gotoPage,
        setPageSize
    } = useTable({
        columns: columns,
        data: data,
        initialState: { pageSize: 50 }
    }, useGlobalFilter, useSortBy, usePagination);

    const { pageIndex, globalFilter} = state;

    function cellContentHandler(columnTitle, content, id, level){

        //View Profile
        if(columnTitle === "Profile")
        {
            return <MoreTableButton onClick={() => {props.viewProfile(id)}}>View Profile</MoreTableButton>
        }

        if(columnTitle === "Level")
        {
            return level
        }

        return content
    }


    return (
        <>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <div className={'table_container'}>
                <div className={'table_container_padding'}>

                    <table {...getTableProps()}>
                        <thead>
                        {headerGroups.map((headerGroup, i) =>(
                            <tr {...headerGroup.getHeaderGroupProps} key={i}>
                                {headerGroup.headers.map((column, i) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                        {column.render('Header')}
                                        <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>

                        <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, i) => {
                                        return <td{...cell.getCellProps()} key={cell.getCellProps().key}>
                                            {cellContentHandler(cell.column.Header, cell.render('Cell'), row.original.id, assesLevel(row.original.level))}
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>

                    </table>
                </div>

                <div className={'table_nav'}>
                    <div className={'page_of_page_text'}>
                        Page <b>{pageIndex + 1}</b> of <b>{pageOptions.length}</b>
                    </div>

                    <div className={'go_to_page_text'}>
                        Go to page:
                    </div>
                    <input
                        className={'page_search'}
                        type={'number'}
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            let pageNumber = 0;
                            if(e.target.value){
                                pageNumber = e.target.value -1;
                            }
                            gotoPage(pageNumber);
                        }}
                    />

                    <SelectInputMini
                        className={'show_number'}
                        sending={false}
                        selected={''}
                        options={[[50, 'Show 50'], [100, 'Show 100'], [500, 'Show 500'], [1000, 'Show 1000']]}
                        onChange={(value) => {
                            setPageSize(Number(value))
                        }}
                    />

                    <div className={'nav_search_buttons'}>
                        <ButtonLittle onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</ButtonLittle>
                        <ButtonLittle onClick={() => previousPage()} disabled={!canPreviousPage}>Previous Page</ButtonLittle>
                        <ButtonLittle onClick={() => nextPage()} disabled={!canNextPage}>Next Page</ButtonLittle>
                        <ButtonLittle onClick={() => gotoPage(pageCount-1)} disabled={!canNextPage}>{'>>'}</ButtonLittle>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MembersTable;