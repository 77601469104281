import style from './SubMenu.module.scss';
import SubMenuButton from "./SubMenuButton";
import React, {useContext} from "react";
import AuthContext from "../../../store/auth-context";

function SubMenu({subMenuType}) {

    let displaySubMenu = ''
    const authCtx = useContext(AuthContext)

    if(subMenuType === 'profile'){
        displaySubMenu = <>
            <SubMenuButton to={''}>Personal Details</SubMenuButton>
            <SubMenuButton to={'contact-preferences'}>Contact Preferences</SubMenuButton>
            <SubMenuButton to={'security'}>Security / Sign in</SubMenuButton>
            <SubMenuButton to={'close-account'}>Close Account</SubMenuButton>
            <SubMenuButton to={'/' + authCtx.userType}>Home</SubMenuButton>
        </>
    }

    if(subMenuType === 'members'){
        displaySubMenu = <>
            <SubMenuButton to={''}>Browse Members</SubMenuButton>
            <SubMenuButton to={'invite-member'}>Invite Member</SubMenuButton>
            <SubMenuButton to={'mailing-lists'}>Mailing Lists</SubMenuButton>
            <SubMenuButton to={'/' + authCtx.userType}>Home</SubMenuButton>
        </>
    }

    if(subMenuType === 'admins'){
        displaySubMenu = <>
            <SubMenuButton to={''}>Manage Admins</SubMenuButton>
            <SubMenuButton to={'invite-admin'}>Invite Admin</SubMenuButton>
            <SubMenuButton to={'all-courses'}>View All Courses</SubMenuButton>
            <SubMenuButton to={'history-log'}>History Log</SubMenuButton>
            <SubMenuButton to={'/' + authCtx.userType}>Home</SubMenuButton>
        </>
    }

    return (
        <div className={style.SubMenu}>
            {displaySubMenu}
        </div>
    );
}

export default SubMenu;