import style from './ToggleSwitch.module.scss'
import {useState} from "react";

const ToggleSwitch = ({initiallyOn, value}) => {

    const [status, setStatus] = useState(initiallyOn)

    function toggleHandler(){
        setStatus(!status)
        value(!status)
    }

    return(
        <button onClick={() => toggleHandler()} className={[style.toggleSwitch, !status && style.switchOff].join(" ")}>
            <span className={style.switchContainer}>
                <span className={style.offBackground} />
                <span className={style.knob} />
            </span>
        </button>
    )
}

export default ToggleSwitch