import './certificate.scss'
import Grade from "../Grade/Grade";
import parse from "date-fns/parse"
import format from "date-fns/format"
import certificatePaper from '../../../assets/images/certificate-paper.webp'
import certificatePaperLogo from '../../../assets/images/certificate-paper-logo.webp'
import certificateLogo from '../../../assets/images/certificate-logo.webp'
import certificateExpiryDate from '../../../assets/images/certificate-expiry-date.svg'
import certificateShield from '../../../assets/images/certificate-shield.svg'
import certificateStar from '../../../assets/images/certificate-star.svg'
import certificateGradeBadge from '../../../assets/images/certificate-grade-badge.svg'
import certificateBorderTopGold from '../../../assets/images/certificate-border-top-gold.svg'
import certificateBorderMiddleGold from '../../../assets/images/certificate-border-middle-gold.svg'
import certificateBorderBottomGold from '../../../assets/images/certificate-border-bottom-gold.svg'

const Certificate = ({name, weaponsSystem, level, teachers, examiner, trainingProvider, percentage, examDate, expiryDate, margins}) => {

    const removeTime = examDate.slice(0, -9)
    const parseDate = parse(removeTime, "yyyy-MM-dd", new Date())
    const formattedExamDate = format(parseDate, "MMMM do yyyy", { awareOfUnicodeTokens: true })

    const removeTime2 = expiryDate.slice(0, -9)
    const parseDate2 = parse(removeTime2, "yyyy-MM-dd", new Date())
    const formattedExpiryDate = format(parseDate2, "MMMM do yyyy", { awareOfUnicodeTokens: true })

    return(
        <div className={['certificate', margins ? 'certificate--with-margins' : null].join(" ")}>

            <div className='certificate__paper' style={{backgroundImage: "url(" + certificatePaper + ")"}} />
            <img src={certificatePaperLogo} className='certificate__paper-logo' alt='' />
            <img src={certificateLogo} className='certificate__logo' alt='' />
            <div className='certificate__border'>
                <div style={{backgroundImage: `url(${certificateBorderTopGold})`}} className='certificate__border__top'></div>
                <div style={{backgroundImage: `url(${certificateBorderMiddleGold})`}} className='certificate__border__middle'></div>
                <div style={{backgroundImage: `url(${certificateBorderBottomGold})`}} className='certificate__border__bottom'></div>
            </div>

            <div className='certificate__inner'>
                <div className='certificate__inner__badc'>British Academy of Dramatic Combat</div>
                <div className='certificate__inner__name'>{name}</div>
                <div className='certificate__inner__weapons-system'>{weaponsSystem}</div>
                <div className='certificate__inner__awarded-on'>Awarded on {formattedExamDate}</div>
                <div className='certificate__inner__details'>
                    <b>Tutors</b> {teachers}<br />
                    {examiner && <><b>Examiner </b> {examiner}<br /></>}
                    {trainingProvider && <><b>Training Provider</b> {trainingProvider}</>}
                </div>
                <div style={{backgroundImage: `url(${certificateExpiryDate})`}} className='certificate__inner__expiry-date'>
                    Valid until {formattedExpiryDate}
                </div>
            </div>
            <div className='certificate__shield'>
                <img src={certificateShield} alt='' />
                <div className='certificate__shield__text'>
                    LEVEL<br />
                    <b>{level}</b>
                    <div className='certificate__shield__text__stars'>
                        {(level === 'Foundation') && <><img src={certificateStar} alt='' /></>}
                        {(level === 'Standard') && <><img src={certificateStar} alt='' /><img src={certificateStar} alt='' /></>}
                        {(level === 'Further') && <><img src={certificateStar} alt='' /><img src={certificateStar} alt='' /><img src={certificateStar} alt='' /></>}
                    </div>
                </div>
            </div>
            <div className='certificate__grade-badge'>
                <img src={certificateGradeBadge} alt='' />
                <div className='certificate__grade-badge__text'><Grade percent={percentage} courseLevel={level} /></div>
            </div>
        </div>
    )
}

export default Certificate