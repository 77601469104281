const genderList = [
    ['Rather not say…', 'Rather not say…'],
    ['Female', 'Female'],
    ['Male', 'Male'],
    ['Transgender', 'Transgender'],
    ['Genderqueer', 'Genderqueer'],
    ['Agender', 'Agender'],
    ['Genderless', 'Genderless'],
    ['Non-binary', 'Non-binary'],
    ['Cis Man', 'Cis Man'],
    ['Cis Woman', 'Cis Woman'],
    ['Trans Man', 'Trans Man'],
    ['Trans Woman', 'Trans Woman'],
    ['Third Gender', 'Third Gender'],
    ['Bigender', 'Bigender'],
    ['Genderfluid', 'Genderfluid']
]

export {genderList}