import {useContext, useEffect, useRef, useState} from "react";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import TextInput from "../Inputs/TextInput";
import SelectInput from "../Inputs/SelectInput";
import DateInput from "../Inputs/DateInput";
import {useParams} from "react-router-dom";
import Loading from "../../ui-components/Loading/Loading";
import useGet from "../../../hooks/useGet";
import usePost from "../../../hooks/usePost";
import {useNavigate} from "react-router-dom";
import format from "date-fns/format";
import parse from "date-fns/parse";
import AuthContext from "../../../store/auth-context";
import coursesContext from "../../../store/courses-context";
import RemoveButton from "../../ui-components/RemoveButton/RemoveButton";
import Modal from "../../ui-components/Modal/Modal";
import useModal from "../../../hooks/useModal";
import DeleteCourse from "../DeleteCourse/DeleteCourse";
import BackButton from "../../ui-components/BackButton/BackButton";
import SorryTheresAProblem from "../../ui-components/SorryTheresAProblem/SorryTheresAProblem";
import {useLocation} from "react-router";
import {standardWeaponsList, furtherWeaponsList, foundationWeaponsList} from "../../../data/weaponsSystems"
import {levelsList} from '../../../data/levelSelect'
import notificationContext from '../../../store/notification-context'

function CourseUpdateForm() {

    //Hooks
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const {showModal, toggleModal} = useModal()
    const authCtx = useContext(AuthContext)
    const coursesCtx = useContext(coursesContext)
    const notificationsCtx = useContext(notificationContext)
    const {loading: loading1, returnData: course} = useGet('/api/courses/' + params.course_id, 500)
    const {loading: loading2, returnData: allTeachers} = useGet('/api/tutors/', 500)
    const {postData, returnPostData, sending, returnMsg, showMessage} = usePost()
    const form = useRef()

    //Set today's date
    const todayDate = format(new Date(), "MM-dd-yy", { awareOfUnicodeTokens: true })

    //Set return address
    const section = location.pathname.split('/')[2]
    let returnAddress = "/" + authCtx.userType + '/course/' + params.course_id
    if(section === "other-tutor-course"){
        returnAddress = "/super-admin/other-tutor-course/" + params.course_id
    }

    //States
    const [level, setLevel] = useState('Standard')
    const [weaponsSystem, setWeaponsSystem] = useState(false)
    const [weaponsSystemList, setWeaponsSystemList] = useState(standardWeaponsList)
    const [examDate, setExamDate] = useState(todayDate)
    const [trainingProvider, setTrainingProvider] = useState(false)
    const [mainTeacher, setMainTeacher] = useState(0)
    const [coTeacher1, setCoTeacher1] = useState(0)
    const [coTeacher2, setCoTeacher2] = useState(0)
    const [coTeachers, setCoTeachers] = useState(true)
    const [mainTeachers, setMainTeachers] = useState(false)

    //Form states
    const [allLoads, setAllLoads] = useState(true)
    const [allData, setAllData] = useState(false)
    const [forceValidation, setForceValidation] = useState(false)

    //Submit form
    function formSubmitHandler(e) {
        e.preventDefault()
        setForceValidation(true)
        if(weaponsSystem && examDate)
        {
            const data = {
                courseId: params.course_id,
                weaponsSystem: weaponsSystem,
                level: level,
                examDate: examDate,
                trainingProvider: trainingProvider,
                teacher1: mainTeacher,
                teacher2: coTeacher1,
                teacher3: coTeacher2
            };

            postData(data, '/api/courses/' + params.course_id + '/update', 0)
        }
    }

    //Redirect
    useEffect(() => {
        if(returnPostData.id){

            //Update the courses context if user is teacher on course
            if ((mainTeacher === authCtx.id) || (coTeacher1 === authCtx.id) || (coTeacher2 === authCtx.id)) {

                let updatedCourses = coursesCtx.courses.filter(item => item.id !== params.course_id)

                //Format date
                const parseDate = parse(examDate, "MM-dd-yy", new Date())
                const formattedDate = format(parseDate, "yyyy-MM-dd", { awareOfUnicodeTokens: true }) + ' 03:00:00'

                // Format teachers
                const teachers = [mainTeacher, coTeacher1, coTeacher2]
                    .map(teacherId => allTeachers.find(teacher => teacher.id === teacherId)?.name || '')
                    .filter(name => name !== '')
                    .join(', ');

                const addCourse = {
                    'id' : params.course_id,
                    'weaponsSystem' : weaponsSystem,
                    'level': level,
                    'examDate' : formattedDate,
                    'teachers': teachers,
                    'trainingProvider' : trainingProvider,
                    'gradesSubmitted' : course.gradesSubmitted
                }
                updatedCourses.push(addCourse)

                function compare(a, b) {
                    if (a.examDate > b.examDate) return 1;
                    if (a.examDate < b.examDate) return -1;
                    return 0;
                }
                updatedCourses.sort(compare)
                coursesCtx.setCourses(updatedCourses)
            } else {
                const updatedCourses = coursesCtx.courses.filter(item => item.id !== params.course_id)
                coursesCtx.setCourses(updatedCourses)
                const updatedNotification = notificationsCtx.notifications.filter((item => item.id !== params.course_id && 'type' !== 'course'))
                notificationsCtx.setNotifications(updatedNotification)
            }

            //Redirect
            navigate(returnAddress);
        }
    }, [authCtx.id, coTeacher1, coTeacher2, course.gradesSubmitted, coursesCtx, examDate, mainTeacher, navigate, notificationsCtx, params.course_id, returnAddress, returnPostData.id, trainingProvider, weaponsSystem])

    //Wait for everything to load and check the data
    useEffect(()=>{
        if(!loading1 && !loading2){
            setAllLoads(false)
            if(course && allTeachers){
                let coTeachersArray = [["0", "Not Applicable"]];
                let mainTeacherArray = [];

                allTeachers.map(
                    teacher => {
                        coTeachersArray.push([teacher.id, teacher.name])
                        mainTeacherArray.push([teacher.id, teacher.name])
                        return false
                    }
                )

                setCoTeachers(coTeachersArray)
                setMainTeachers(mainTeacherArray)
                setLevel(course.level)

                let weaponsSystemList = standardWeaponsList
                if (course.level === "Further"){
                    weaponsSystemList = furtherWeaponsList
                }
                if (course.level === "Foundation"){
                    weaponsSystemList = foundationWeaponsList
                }
                setWeaponsSystemList(weaponsSystemList)

                setWeaponsSystem(course.weaponsSystem)
                setExamDate(course.exam_date)
                setTrainingProvider(course.trainingProvider)

                if(course.teachers){
                    course.teachers.map((teacher, index) => {
                        if(index === 0){
                            setMainTeacher(teacher.id)
                        }
                        if(index === 1){
                            setCoTeacher1(teacher.id)
                        }
                        if(index === 2){
                            setCoTeacher2(teacher.id)
                        }
                        return false
                    })
                }

                setAllData(true)
            }
        }
    }, [loading1, loading2, allTeachers, course])

    //Switch Weapons Systems list
    useEffect(() => {
        let weaponsSystemList = standardWeaponsList
        if (level === "Further"){
            weaponsSystemList = furtherWeaponsList
        }
        if (level === "Foundation"){
            weaponsSystemList = foundationWeaponsList
        }
        setWeaponsSystemList(weaponsSystemList)

    }, [level])

    function levelHandler(status){
        setWeaponsSystem(false)
        setLevel(status);
    }

    function weaponsSystemHandler(status){
        setWeaponsSystem(status)
    }

    function trainingProviderHandler(status){
        setTrainingProvider(status)
    }

    function examDateHandler(status){
        setExamDate(status)
    }

    function mainTeacherHandler(status){
        setMainTeacher(status);
    }

    function coTeacher1Handler(status){
        setCoTeacher1(status);
    }

    function coTeacher2Handler(status){
        setCoTeacher2(status);
    }

    return (
        <>
            {allLoads ? <Loading/> : !allData ? <SorryTheresAProblem message={"There's been an issue loading the course. Please refresh the app."} /> :
                <>
                    <RemoveButton
                        position={'top_right'}
                        onClick={() => toggleModal()}
                    >Delete Course</RemoveButton>

                    <form ref={form} onSubmit={formSubmitHandler} className={"form"} action="">

                        <div className={"field field--half"}>
                            <SelectInput
                                label={"Level"}
                                options={levelsList}
                                required={false}
                                selected={level}
                                value={(status) => levelHandler(status)}
                                sending={sending}
                                forceValidation={forceValidation}
                            />
                        </div>

                        <div className={"field field--half"}>
                            <SelectInput
                                label={"Weapons System"}
                                options={weaponsSystemList}
                                required={true}
                                selected={weaponsSystem}
                                value={(status) => weaponsSystemHandler(status)}
                                sending={sending}
                                forceValidation={forceValidation}
                            />
                        </div>

                        <div className={"field"}>
                            <TextInput
                                defaultValue={trainingProvider}
                                label={"Training Provider (optional)"}
                                value={(status) => trainingProviderHandler(status)}
                                sending={sending}
                                maxLength={50}
                                required={false}
                                forceValidation={forceValidation}
                            />
                        </div>

                        <div className={"field"}>
                            <SelectInput
                                label={"Main Tutor"}
                                options={mainTeachers}
                                selected={mainTeacher}
                                required={false}
                                value={(status) => mainTeacherHandler(status)}
                                sending={sending}
                                forceValidation={forceValidation}
                            />
                        </div>

                        <div className={"field"}>
                            <SelectInput
                                label={"Co-Tutor 1"}
                                options={coTeachers}
                                selected={coTeacher1}
                                required={false}
                                value={(status) => coTeacher1Handler(status)}
                                sending={sending}
                                forceValidation={forceValidation}
                            />
                        </div>

                        <div className={"field"}>
                            <SelectInput
                                label={"Co-Tutor 2"}
                                options={coTeachers}
                                selected={coTeacher2}
                                required={false}
                                value={(status) => coTeacher2Handler(status)}
                                sending={sending}
                                forceValidation={forceValidation}
                            />
                        </div>

                        <div className={"field"}>
                            <DateInput
                                label={"Exam Date"}
                                initialDate={examDate}
                                value={(status) => examDateHandler(status)}
                                sending={sending}
                                forceValidation={forceValidation}
                            />
                        </div>

                        <TrayToggle open={showMessage}>
                            <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{returnMsg}</div>
                        </TrayToggle>

                        <button className={"submit"} type="submit" disabled={sending}>{!sending ? "Update Course" : "Sending..."}</button>

                        {sending && <div className={"sending"}/>}
                    </form>

                    <Modal
                        showModal={showModal}
                        toggleModal={toggleModal}>
                        <DeleteCourse
                            courseId={params.course_id}
                            weaponsSystem={weaponsSystem}
                            closeModal={() => toggleModal()}
                        />
                    </Modal>

                    <BackButton
                        navigate={returnAddress}
                    >
                        Back
                    </BackButton>

                </>
            }
        </>
    )
}

export default CourseUpdateForm;