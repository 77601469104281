import style from './ModalButtons.module.scss'

const ModalButtons = ({button1Text, button1Function, closeModal}) => {

    return(
        <div className={style.modal_buttons_container}>
            <button className={style.deleteButton} onClick={button1Function}>{button1Text}</button>
            <span className={style.break} />
            <button onClick={closeModal} className={style.altButton}>Cancel</button>
        </div>
    )
}

export default ModalButtons