import ModalButtons from "../../ui-components/ModalButtons/ModalButtons";
import usePost from "../../../hooks/usePost";
import {useEffect, useContext} from "react";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import coursesContext from "../../../store/courses-context";
import notificationContext from "../../../store/notification-context";
import {useNavigate} from "react-router-dom";
import authContext from "../../../store/auth-context";

const DeleteCourse = ({courseId, weaponsSystem, closeModal}) => {

    const {sending, showMessage, returnMsg, returnPostData, postData} = usePost()
    const coursesCtx = useContext(coursesContext)
    const authCtx = useContext(authContext)
    const notificationsCtx = useContext(notificationContext)
    const navigate = useNavigate()

    function deleteCourseHandler(){
        const data = {
            courseId: courseId
        }
        postData(data, '/api/courses/delete/', 1000)
    }

    useEffect(() => {
        if(returnPostData){

            //Update the courses context
            const updatedCourses = coursesCtx.courses.filter(item => (item.id !== courseId))
            coursesCtx.setCourses(updatedCourses)

            //Update notifications
            const updatedNotifications = notificationsCtx.notifications.filter(item => (item.type === 'course' && item.id !== courseId))
            notificationsCtx.setNotifications(updatedNotifications)

            //Update temp notification
            const data = {
                title: "Course deleted",
                message: weaponsSystem + " has been deleted."
            }
            notificationsCtx.setTempNotification(data)

            //Redirect
            navigate('/' + authCtx.userType);
        }

    }, [returnPostData, closeModal, courseId, coursesCtx.courses, notificationsCtx.notifications, coursesCtx, navigate, notificationsCtx, showMessage, authCtx.userType, weaponsSystem])

    return(
        <>
            <h2>Delete?</h2>
            <p>Are you sure you want to delete {weaponsSystem}? This cannot be undone.</p>
            <TrayToggle open={showMessage}>
                <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{returnMsg}</div>
            </TrayToggle>
            <ModalButtons
                button1Text={'Delete Course'}
                button1Function={() => deleteCourseHandler()}
                closeModal={closeModal}
            />
            {sending && <div className={'sending'} />}
        </>
    )
}

export default DeleteCourse