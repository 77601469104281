function assesLevel(levelString)
{
    if (levelString) {
        let level = levelString.slice(-1)

        if (level === '1') {
            level = 'Foundation'
        }

        if (level === '2') {
            level = 'Standard'
        }

        if (level === '3') {
            level = 'Intermediate'
        }

        if (level === '4') {
            level = 'Advanced'
        }

        return(level)

    } else {
        return '–'
    }
}

export default assesLevel