import style from './PlusToArrow.module.scss'
import plus from '../../../assets/images/plus.svg'
import arrow from '../../../assets/images/menuArrow.svg'

const PlusToArrow = ({open}) => {
    return(
        <span className={[style.PlusToArrow, open ? style.PlusToArrowOpen : null].join(" ")}>
            <img className={style.plus} src={plus} alt="" />
            <img className={style.arrow} src={arrow} alt="" />
        </span>
    )
}

export default PlusToArrow