export const COLUMNS = [
    {
        Header: 'Percent',
        accessor: 'percentage'
    },
    {
        Header: 'Grade',
        accessor: 'n/a'
    },
    {
        Header: 'Expires',
        accessor: 'expiryDate'
    }
]