import {useContext, useEffect, useState} from "react"
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle"
import AuthContext from '../../../store/auth-context'
import UserContext from "../../../store/user-context"
import FirstNameInput from "../Inputs/FirstNameInput"
import SurNameInput from "../Inputs/SurNameInput"
import EmailInput from "../Inputs/EmailInput"
import PhoneInput from "../Inputs/PhoneInput"
import usePost from "../../../hooks/usePost";
import SelectInput from "../Inputs/SelectInput";
import {genderList} from "../../../data/genderSelect";

function UpdateDetailsForm() {

    const authCtx = useContext(AuthContext)
    const userCtx = useContext(UserContext)
    const {postData, sending, showMessage, showSuccessMessage, returnMsg, returnPostData} = usePost()

    const [firstName, setFirstName] = useState(userCtx.firstName)
    const [surName, setSurName] = useState(userCtx.surName)
    const [gender, setGender] = useState(userCtx.gender)
    const [email, setEmail] = useState(userCtx.email)
    const [phone, setPhone] = useState(userCtx.phone)
    const [forceValidation, setForceValidation] = useState(false)

    useEffect(() => {
        return(() => {
            if(returnPostData){
                userCtx.setUser(JSON.parse(returnPostData.userData))
            }
        })
    }, [returnPostData, userCtx])

    function formSubmitHandler(e) {
        e.preventDefault()
        setForceValidation(true)

        let formIsValid = false

        // Validate form for BADC account (first name and surname not required)
        if (userCtx.firstName === 'BADC') {
            if (email && phone) {
                formIsValid = true
            }
        } else {
            if(firstName && surName && email && phone) {
                formIsValid = true
            }
        }

        if(formIsValid) {
            const data = {
                userType: authCtx.userType,
                firstName: firstName,
                surName: surName,
                gender: gender,
                oldEmail: userCtx.email,
                email: email,
                phone: phone
            }
            postData(data, '/api/users/' + authCtx.id + '/update_personal/', 500)
        }
    }

    function firstNameHandler(status){
        setFirstName(status)
    }

    function surNameHandler(status){
        setSurName(status)
    }

    function genderHandler(status){
        setGender(status)
    }

    function emailHandler(status){
        setEmail(status)
    }

    function phoneHandler(status){
        setPhone(status)
    }

    return (
        <>
            <form onSubmit={formSubmitHandler} className={"form"} action="">

                {(userCtx.firstName === 'BADC') ?
                    <>
                        <div className={"field"}>
                            <FirstNameInput
                                defaultValue='BADC'
                                value={(status) => firstNameHandler(status)}
                                sending={true}
                                forceValidation={forceValidation}
                                label={"Name"}
                            />
                        </div>
                    </>
                    :
                    <>
                        <div className={"field field--half"}>
                            <FirstNameInput
                                defaultValue={firstName}
                                value={(status) => firstNameHandler(status)}
                                sending={sending}
                                forceValidation={forceValidation}
                                label={"First Name"}
                            />
                        </div>

                        <div className={"field field--half"}>
                            <SurNameInput
                                defaultValue={surName}
                                value={(status) => surNameHandler(status)}
                                sending={sending}
                                forceValidation={forceValidation}
                                label={"Surname"}
                            />
                        </div>
                    </>
                }

                <div className={"field"}>
                    <SelectInput
                        label={"I identify my gender as"}
                        options={genderList}
                        selected={gender}
                        value={(status) => genderHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                    />
                </div>

                <div className={"field"}>
                    <EmailInput
                        defaultValue={email}
                        value={(status) => emailHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Email address"}
                    />
                </div>

                <div className={"field"}>
                    <PhoneInput
                        defaultValue={phone}
                        value={(status) => phoneHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Phone"}
                    />
                </div>

                <TrayToggle open={showMessage}>
                    <div className={["ReturnMsg", 'ReturnMsgError'].join(" ")}>{returnMsg}</div>
                </TrayToggle>
                <TrayToggle open={showSuccessMessage}>
                    <div className={'ReturnMsg'}>{returnMsg}</div>
                </TrayToggle>

                <button className={"submit"} type="submit" disabled={sending}>{!sending ? "Update" : "Updating..."}</button>
            </form>
        </>
    )
}

export default UpdateDetailsForm