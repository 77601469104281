import style from './MoreTableButton.module.scss';
import React from "react";
import rightChevron from '../../../assets/images/chevron_right_orange.svg'

function MoreTableButton({onClick, children}){

    return(
        <button
            onClick={onClick}
            className={style.MoreButton}
        >
            {children}
            <span><img src={rightChevron} alt="" /></span>
        </button>
    )
}

export default MoreTableButton;