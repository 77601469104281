import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import BackButton from "../../components/ui-components/BackButton/BackButton";

function Privacy(){

    return(
        <>
            <PageTitle>Privacy Policy</PageTitle>
            <div className={'page'}>
                <div style={{maxWidth: "70ch"}}>
                    <h2>We’re committed to your privacy</h2>
                    <p>We keep the amount of information we hold about you to a minimum and we’ll always keep your data safe and secure.</p>
                    <p>We only use your data to provide our services to you and we apply appropriate security mechanisms to protect your personal data.</p>

                    <h2 style={{marginTop: "3rem"}}>Who can see my data?</h2>
                    <p>The tutors of BADC have access to view your information as well as the digital agency who built this app (D digital). When viewing your information your contact preferences are always clear.</p>

                    <h2 style={{marginTop: "3rem"}}>Why do you ask about Gender?</h2>
                    <p>We ask about gender so that we can monitor our commitment to equality. The information is only used to give us a sense of who is joining BADC and to help us act with fairness and equality in all matters.</p>

                    <h2 style={{marginTop: "3rem"}}>We do not share or sell your personal data</h2>
                    <p>All other third parties we use – including those who provide email and storage solutions used in our day to day work – are selected for, and monitored on, how they meet the requirements of GDPR. Where this includes storage or processing of information outside of the European Economic Area (EEA), we include checks to ensure that compliance with the appropriate frameworks for exchange of personal data (such as the EU-US Privacy Shield) is in place.</p>
                    <p>If you’d like more details about any of terms used within this Privacy Policy, you can refer to the ICO website or look at the General Data Protection Regulation (“GDPR”). We suggest you start with the ICO website.</p>
                    <p>We’re happy to answer any questions you have about this Privacy Policy.</p>


                    <h2 style={{marginTop: "3rem"}}>What are cookies?</h2>
                    <p>A cookie is a file containing a string of letters and numbers that is sent by a web server to a web browser and is stored by the browser. The string is then sent back to the server each time the browser requests a page from the server.</p>
                    <p>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</p>

                    <h2 style={{marginTop: "3rem"}}>The Cookies we Use</h2>
                    <p>We use cookies to keep you logged in between sessions and to remember the type of user you are (a member or an administrator).</p>
                </div>
            </div>
            <BackButton>Home</BackButton>
        </>
    )
}

export default Privacy;