import assessGrade from "../../../utils/assessGrade";

const Grade = ({percent, courseLevel}) => {

    const grade = assessGrade(percent,courseLevel)

    return(
        <>
            {grade}
        </>
    )
}

export default Grade