import {useParams} from "react-router-dom"
import {useContext, useEffect, useState} from "react"
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle"
import { useNavigate } from "react-router-dom"
import AuthContext from "../../../store/auth-context"
import FirstNameInput from "../Inputs/FirstNameInput"
import SurNameInput from "../Inputs/SurNameInput"
import PhoneInput from "../Inputs/PhoneInput"
import PasswordInput from "../Inputs/PasswordInput"
import PasswordConfirmInput from "../Inputs/PasswordConfirmInput"
import SelectInput from "../Inputs/SelectInput"
import {genderList} from "../../../data/genderSelect";
import usePost from '../../../hooks/usePost'

function CreateProfileForm({hiddenEmail}){

    // Hooks
    const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const {postData, returnMsg, returnPostData, sending, showMessage} = usePost()
    const params = useParams()

    // State
    const [firstName, setFirstName] = useState(false)
    const [surName, setSurName] = useState(false)
    const [phone, setPhone] = useState(false)
    const [gender, setGender] = useState(genderList[0][0])
    const [password, setPassword] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState(false)
    const [forceValidation, setForceValidation] = useState(false)

    function formSubmitHandler(e) {
        e.preventDefault()
        setForceValidation(true)

        if (firstName && surName && phone && password && passwordConfirm){
            const data = {
                userType: 'member',
                firstName: firstName,
                surName: surName,
                phone: phone,
                gender: gender,
                password: password,
                password2: passwordConfirm,
                inviteString: params.InviteString
            }
            postData(data,'/api/users/', 500)
        }
    }

    //Redirect
    useEffect(() => {
        if (returnPostData.id) {
            authCtx.login(returnPostData.token, returnPostData.expirationTime, returnPostData.userType, returnPostData.id)
            navigate('/contact-preferences')
        }
    })

    function firstNameHandler(status){
        setFirstName(status)
    }

    function surNameHandler(status){
        setSurName(status)
    }

    function phoneHandler(status){
        setPhone(status)
    }

    function genderHandler(status){
        setGender(status)
    }

    function passwordHandler(status){
        setPassword(status)
    }

    function passwordConfirmHandler(status){
        setPasswordConfirm(status)
    }

    return(
        <>
            <form onSubmit={formSubmitHandler} className={"form"} action="">

                <div className={"field field--half"}>
                    <FirstNameInput
                        value={(status) => firstNameHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"First Name"}
                    />
                </div>

                <div className={"field field--half"}>
                    <SurNameInput
                        value={(status) => surNameHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Surname"}
                    />
                </div>

                <div className={"field field--half"}>
                    <PhoneInput
                        value={(status) => phoneHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Phone"}
                    />
                </div>

                <div className={"field field--half"}>
                    <SelectInput
                        value={(status) => genderHandler(status)}
                        label={"I identify my gender as"}
                        sending={sending}
                        options={genderList}
                        selected={gender}
                        required={false}
                        forceValidation={forceValidation}
                    />
                </div>

                <input
                    type="username"
                    name="username"
                    autoComplete="username"
                    readOnly
                    value={hiddenEmail}
                    hidden={true}
                />

                <div className={"field"}>
                    <PasswordInput
                        value={(status) => passwordHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Password"}
                    />
                </div>

                <div className={"field"}>
                    <PasswordConfirmInput
                        otherPassword={password}
                        value={(status) => passwordConfirmHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Confirm Password"}
                    />
                </div>

                <TrayToggle open={showMessage}>
                    <div className={["ReturnMsg", 'ReturnMsgError'].join(" ")}>{returnMsg}</div>
                </TrayToggle>

                <button className={"submit"} type="submit" disabled={sending}>{!sending ? "Join BADC" : "Creating..."}</button>
            </form>
        </>
    )
}

export default CreateProfileForm