function isDateInPast(date) {
    const q = new Date();
    const m = q.getMonth()+1;
    const d = q.getDay();
    const y = q.getFullYear();
    const todaysDate = new Date(y,m,d);
    const mydate = new Date(date);
    return mydate < todaysDate;
}

export default isDateInPast