import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import Section from "../../components/ui-components/Section/Section";
import CourseSidePanel from "../../components/ui-components/CourseSidePanel/CourseSidePanel";
import Loading from "../../components/ui-components/Loading/Loading";
import {useParams} from "react-router-dom";
import useGet from "../../hooks/useGet";
import ArchivedMembersOnCourse from "../../components/functional-components/Tables/ArchivedMembersOnCourse";
import BackButton from "../../components/ui-components/BackButton/BackButton";
import {useContext} from "react";
import authContext from "../../store/auth-context";
import SorryTheresAProblem from "../../components/ui-components/SorryTheresAProblem/SorryTheresAProblem";

function CourseArchived(){

    //Hooks
    const params = useParams()
    const authCtx = useContext(authContext)
    const {loading, returnData} = useGet('/api/courses/' + params.course_id, 250)

    return(
        <>
            {loading ? <Loading /> : !returnData ? <SorryTheresAProblem message={"No courses found."} /> :
                <>
                    <PageTitle>{returnData.weaponsSystem}</PageTitle>

                    <div className='two_columns'>

                        <div className={'left_column'}>
                            <Section table={true} title="Students">
                                <ArchivedMembersOnCourse
                                    data={returnData.enrolled}
                                    courseLevel={returnData.level}
                                />
                            </Section>

                            <Section title="Archive">
                                <p>The grades for this course have been submitted and cannot be changed. If there is a mistake, please contact your administrator</p>
                            </Section>
                        </div>

                        <CourseSidePanel
                            trainingProvider={returnData.trainingProvider}
                            teachers={returnData.teachers}
                            level={returnData.level}
                            examiner={returnData.examiner}
                            examDate={returnData.exam_date}
                            editOption={false}
                        />
                    </div>
                    <BackButton
                        navigate={"/" + authCtx.userType + "/course-archive/"}
                    >
                        Back
                    </BackButton>
                </>
            }
        </>
    )
}

export default CourseArchived;