export const COLUMNS = [
    {
        Header: 'Weapons System',
        accessor: 'weaponsSystem'
    },
    {
        Header: 'Tutors',
        accessor: 'teachers'
    },
    {
        Header: 'Exam Date',
        accessor: 'examDate'
    },
    {
        Header: 'Details',
        accessor: 'details'
    }
]