import style from './CourseSidePanel.module.scss'
import {Link} from "react-router-dom"
import Button from "../Button/Button"
import parse from "date-fns/parse"
import format from "date-fns/format"

function CourseSidePanel(props){

    const parseDate = parse(props.examDate, "MM-dd-yy", new Date())
    const formattedDate = format(parseDate, "PPPP", { awareOfUnicodeTokens: true })

    return(

        <div className={style.AccountSidePanel}>

            {props.trainingProvider &&
                <>
                    <div className={style.title}>Training Provider:</div>
                    <div className={style.text}>{props.trainingProvider}</div>
                    <div className={style.break} />
                </>
            }

            <div className={style.title}>Teachers:</div>
            {
                props.teachers.map(teacher => {
                    return(<div key={teacher.name} className={style.text}>{teacher.name}</div>)
                })
            }

            <div className={style.break} />

            {props.examiner &&
                <>
                    <div className={style.title}>Examiner:</div>
                    <div className={style.text}>{props.examiner}</div>
                    <div className={style.break} />
                </>
            }


            <div className={style.title}>Level:</div>
            <div className={style.text}>{props.level} Level</div>

            <div className={style.break} />

            <div className={style.title}>Exam Date:</div>
            <div className={style.text}>{formattedDate}</div>

            {props.editOption &&
                <>
                    <div className={style.break} />
                    <Link to={'update/'}><Button>Edit Details</Button></Link>
                </>
            }

        </div>
    )
}

export default CourseSidePanel