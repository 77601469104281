const foundationWeaponsList = [
    ['Select Weapon...', 'Select Weapon...'],
    ['Unarmed & Knife', 'Unarmed & Knife'],
    ['Unarmed & Single Sword', 'Unarmed & Single Sword'],
    ['Knife & Single Sword', 'Knife & Single Sword'],
]

const standardWeaponsList = [
    ['Select Weapon...', 'Select Weapon...'],
    ['Basic Smallsword', 'Basic Smallsword'],
    ['Rapier & Dagger', 'Rapier & Dagger'],
    ['Rapier', 'Rapier'],
    ['Single-handed sword', 'Single-handed sword'],
    ['Unarmed', 'Unarmed'],
    ['Unarmed with knife', 'Unarmed with knife'],
    ['Basic Smallsword (COVID-19)', 'Basic Smallsword (COVID-19)'],
    ['Rapier and Dagger (COVID-19)', 'Rapier and Dagger (COVID-19)'],
    ['Rapier (COVID-19)', 'Rapier (COVID-19)'],
    ['Single-Handed Sword (COVID-19)', 'Single-Handed Sword (COVID-19)']
]

const furtherWeaponsList = [
    ['Select Weapon...', 'Select Weapon...'],
    ['18th Century Smallsword', '18th Century Smallsword'],
    ['Advanced Unarmed', 'Advanced Unarmed'],
    ['Arming Sword', 'Arming Sword'],
    ['Axe & Knife', 'Axe & Knife'],
    ['Axe & Shield', 'Axe & Shield'],
    ['Club & Shield', 'Club & Shield'],
    ['Case of Rapiers', 'Case of Rapiers'],
    ['Dagger', 'Dagger'],
    ['Flail', 'Flail'],
    ['Found Weapons', 'Found Weapons'],
    ['Katana', 'Katana'],
    ['Knife', 'Knife'],
    ['Longsword', 'Longsword'],
    ['Nunchaku', 'Nunchaku'],
    ['Rapier & Buckler', 'Rapier & Buckler'],
    ['Rapier & Cloak', 'Rapier & Cloak'],
    ['Rapier & Dagger', 'Rapier & Dagger'],
    ['Rapier & Gauntlet', 'Rapier & Gauntlet'],
    ['Rapier & Lantern', 'Rapier & Lantern'],
    ['Sabre', 'Sabre'],
    ['Single Rapier', 'Single Rapier'],
    ['Spear & Shield', 'Spear & Shield'],
    ['Staff', 'Staff'],
    ['Sword & Buckler', 'Sword & Buckler'],
    ['Sword & Shield', 'Sword & Shield'],
    ['Tomahawk', 'Tomahawk'],
    ['Tomahawk & Knife', 'Tomahawk & Knife'],
    ['Two-handed Axe', 'Two-handed Axe'],
    ['Two-handed Club', 'Two-handed Club'],
    ['Warhammer/Axe', 'Warhammer/Axe']
]

export {foundationWeaponsList, standardWeaponsList, furtherWeaponsList}