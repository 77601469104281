import style from './RemoveButton.module.scss';
import React from "react";
import crossImage from '../../../assets/images/red_cross.svg'

function RemoveButton(props){

    let position = ''
    if(props.position){
        position = style.top_right
    }

    return(
        <button
            onClick={props.onClick}
            className={[style.RemoveButton, position].join(" ")}
        >
            {props.children}
            <span><img src={crossImage} alt="" /></span>
        </button>
    )
}

export default RemoveButton;