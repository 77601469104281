import {useEffect, useRef, useState} from "react";

function PhoneInput(props){

    const input = useRef();
    const [error, setError] = useState(true)
    const [showError, setShowError] = useState(false)

    const validateHandler = () => {
        props.value(false);
        switch(true) {
            case (input.current.value.length === 0):
                setError("Please enter your phone number")
                return
            case (!input.current.value.replace(/\s/g, '').length):
                setError("Please enter your phone number")
                return
            case (input.current.value.length > 30):
                setError("Please enter a phone number under 30 characters")
                return
            default:
                setError(false);
                setShowError(false);
                props.value(input.current.value);
                return
        }
    }

    const showErrorHandler = () => {
        validateHandler()
        setShowError(true)
    }

    useEffect(() => {
        if(props.forceValidation){
            showErrorHandler();
        }
    })

    return(
        <>
            <label htmlFor="phone" className={"label"}>{props.label}:</label>
            {(error && showError) && <div className={"bubble"}>{error}</div>}
            <input
                defaultValue={props.defaultValue}
                ref={input}
                type="text"
                name="phone"
                autoComplete="tel"
                maxLength={30}
                onBlur={showErrorHandler}
                onChange={validateHandler}
                className={"input"}
                disabled={props.sending}
            />
        </>
    )
}

export default PhoneInput;