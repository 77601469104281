import UserContext from "../../store/user-context"
import AuthContext from "../../store/auth-context"
import CoursesContext from "../../store/courses-context"
import NotificationContext from "../../store/notification-context";
import Section from "../../components/ui-components/Section/Section"
import AppIcon from "../../components/ui-components/Icons/AppIcon"
import PageTitle from "../../components/ui-components/PageTitle/PageTitle"
import {Fragment, useContext, useEffect, useState} from "react"
import AccountSidePanel from "../../components/ui-components/AccountSidePanel/AccountSidePanel"
import TrayToggle from "../../components/ui-components/TrayToggle/TrayToggle";
import TempMessage from "../../components/ui-components/TempMessage/TempMessage";
import IconLine from "../../components/ui-components/Icons/IconLine";
import assessGrade from "../../utils/assessGrade";
import CertificateThumbnail from "../../components/functional-components/CertificateThumbnail/CertificateThumbnail";
import Modal from "../../components/ui-components/Modal/Modal";
import Certificate from "../../components/functional-components/Certificate/Certificate";
import isDateInPast from "../../utils/isDateInPast";
import usePost from "../../hooks/usePost";
import CoursesStudentView from "../../components/functional-components/Tables/CoursesStudentView";
import CourseIconsWrapper from "../../components/ui-components/CourseIconsWrapper/CourseIconsWrapper";
import CourseAdminIconsWrapper from "../../components/ui-components/CourseAdminIconsWrapper/CourseAdminIconsWrapper";
import {useLocation} from "react-router";
import SmallPrint from '../../components/functional-components/SmallPrint/SmallPrint'
import RecordOfTrainingGet from '../../components/functional-components/RecordOfTrainingGet/RecordOfTrainingGet'
import assesLevel from '../../utils/assessLevel'
import parse from "date-fns/parse"
import format from "date-fns/format"
import MonthSection from '../../components/ui-components/MonthSection/MonthSection'

function Home(){

    //Hooks
    const authCtx = useContext(AuthContext)
    const notificationsCtx = useContext(NotificationContext)
    const userCtx = useContext(UserContext)
    const courses = useContext(CoursesContext).courses
    const userType = authCtx.userType
    const {postData} = usePost()
    const examinerNotifications = notificationsCtx.notifications.filter(notification => (notification.type === "examiner"))
    const location = useLocation()

    //State
    const [tempTray, setTempTray] = useState(false)
    const [tempTitle, setTempTitle] = useState('')
    const [tempMessage, setTempMessage] = useState('')
    const [showCertificate, setShowCertificate] = useState(false)
    const [showRot, setShowRot] = useState(false)
    const [selectedCertificate, setSelectedCertificate] = useState('empty')

    //Other
    let examinerMessage = "You have a new course to check and confirm the grades on:"
    if(examinerNotifications.length > 1){
        examinerMessage = "You have " + examinerNotifications.length + " new courses to check and confirm the grades on:"
    }
    let courseCount = 0

    //Count valid and expired certificates
    let expiredCertificates = 0
    let validCertificates = 0
    if(userCtx.grades){
        userCtx.grades.map((grade) => {
            const result = assessGrade(grade.percentage, grade.level);
            if(result !== "Failed" && grade.confirmed === '1'){
                if (isDateInPast(grade.expiryDate)) {
                    expiredCertificates = expiredCertificates + 1
                } else {
                    validCertificates = validCertificates + 1
                }
            }
            return false
        })
    }

    // Collate course months
    let courseMonths = []
    if (courses) {
        courses.map(course => {
            if(course.gradesSubmitted === "0"){
                const removeTime = course.examDate.slice(0, -9)
                const parseDate = parse(removeTime, "yyyy-MM-dd", new Date())
                const formattedMonth = format(parseDate, "MMMM yyyy", { awareOfUnicodeTokens: true })
                courseMonths.push(formattedMonth)
            }
            return false
        })
    }
    courseMonths = [...new Set(courseMonths)]

    //Use Effect
    useEffect(() => {

        //Check for open certificate state
        if(location.state){
            if(location.state['selectedCertificate']){
                const certificate = userCtx.grades.filter((grade) => (grade.id === location.state['selectedCertificate']))
                setSelectedCertificate(certificate)
                setShowCertificate(true)
            }
        }

        if(notificationsCtx.tempNotification){
            setTempTray(true)
            setTempTitle(notificationsCtx.tempNotification.title)
            setTempMessage(notificationsCtx.tempNotification.message)
            if(!notificationsCtx.tempNotification.fromServer){
                notificationsCtx.setTempNotification(false)
            }
        }
    }, [notificationsCtx, setTempTray, location.state, userCtx.grades])

    //Mark temp message as read
    const markAsReadHandler = () => {
        if(notificationsCtx.tempNotification.fromServer){
            postData('', '/api/notifications/' + notificationsCtx.tempNotification.id, 0)
        }
        setTempTray(false)
    }

    //Open certificate
    const openCertificateHandler = (id) => {
        const certificate = userCtx.grades.filter((grade) => (grade.id === id))
        setSelectedCertificate(certificate)
        setShowCertificate(true)
    }

    //Close certificate
    const closeCertificateHandler = () => {
        setShowCertificate(false)
    }

    // Close record of training
    const closeRotHandler = () => {
        setShowRot(false)
    }

    return(
        <>
            <PageTitle>Welcome to your account, {userCtx.firstName}</PageTitle>

            <div className={'two_columns'}>
                <div className={'left_column'}>

                    <TrayToggle
                        open={tempTray}
                    >
                        <TempMessage
                            title={tempTitle}
                            message={tempMessage}
                            onClick={() => markAsReadHandler()}
                        />
                    </TrayToggle>

                    {(userType === 'member') &&
                        <>
                            <Section table={true} title="Your Courses">
                                {courses && courses.length ?
                                    <CoursesStudentView
                                        data={courses}
                                    /> : <p>You haven't enrolled on any courses.</p>
                                }
                            </Section>

                            <Section title="Record of Training">
                                {userCtx.level ?
                                    <>
                                        {userCtx.level.includes("4") &&
                                            <CertificateThumbnail
                                                openCertificate={() => setShowRot('Advanced')}
                                                weaponsSystem={'Advanced'}
                                                examDate={null}
                                            />
                                        }
                                        {userCtx.level.includes("3") &&
                                            <CertificateThumbnail
                                                openCertificate={() => setShowRot('Intermediate')}
                                                weaponsSystem={'Intermediate'}
                                                examDate={null}
                                            />
                                        }
                                        {userCtx.level.includes("2") &&
                                            <CertificateThumbnail
                                                openCertificate={() => setShowRot('Standard')}
                                                weaponsSystem={'Standard'}
                                                examDate={null}
                                            />
                                        }
                                        {userCtx.level.includes("1") &&
                                            <CertificateThumbnail
                                                openCertificate={() => setShowRot('Foundation')}
                                                weaponsSystem={'Foundation'}
                                                examDate={null}
                                            />
                                        }
                                    </>
                                    :
                                    <p>Once your grades consolidate to a BADC level, your record of training will appear here.</p>
                                }
                            </Section>

                            <Section title="Your Certificates">
                                {
                                    (validCertificates) ?
                                        userCtx.grades.map((grade, i) => {
                                            const result = assessGrade(grade.percentage, grade.level);
                                            if (result !== "Failed" && grade.confirmed === '1') {
                                                if (!isDateInPast(grade.expiryDate)) {
                                                    return (
                                                        <CertificateThumbnail
                                                            openCertificate={() => openCertificateHandler(grade.id)}
                                                            key={i}
                                                            weaponsSystem={grade.weaponsSystem}
                                                            examDate={grade.examDate}
                                                        />
                                                    )
                                                }
                                            }
                                            return false
                                        }) : <p>You haven't earned any certificates.</p>
                                }
                                {(validCertificates > 0) ?
                                    <SmallPrint>
                                        <p>These certificates are a verification of the skills learned and demonstrated in a proficiency test or showcase. This means that you have trained for the required amount of hours, and have been judged to safely perform the minimum required moves within a dramatic context. </p>
                                        <p>These certificates do not qualify you to teach dramatic combat or to direct fights.</p>
                                    </SmallPrint> : null
                                }
                            </Section>

                            {expiredCertificates ?
                                <Section title="Expired Certificates">
                                    {
                                        userCtx.grades.map((grade, i) => {
                                            const result = assessGrade(grade.percentage, grade.level);
                                            if(result !== "Failed" && grade.confirmed === '1'){
                                                if(isDateInPast(grade.expiryDate)){
                                                    return(
                                                        <CertificateThumbnail
                                                            openCertificate={() => openCertificateHandler(grade.id)}
                                                            key={i}
                                                            weaponsSystem={grade.weaponsSystem}
                                                            examDate={grade.examDate}
                                                        />
                                                    )
                                                }
                                            }
                                            return false
                                        })
                                    }
                                </Section> : null
                            }
                        </>
                    }

                    {(userType === 'admin' || userType === 'super-admin') &&
                        <>
                            {examinerNotifications.length ?
                                <Section title="Examiner" appWrap={true}>
                                    <p>{examinerMessage}</p>
                                    <CourseIconsWrapper>
                                        {
                                            examinerNotifications.map(course => {
                                                return(
                                                    <Fragment key={course.id}>
                                                        <AppIcon
                                                            label={course.weaponsSystem}
                                                            link={"grades-confirm/" + course.id}
                                                            type={'course'}
                                                        />
                                                        <IconLine />
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </CourseIconsWrapper>
                                </Section> : null
                            }

                            <Section title="Courses" appWrap={true}>

                                {
                                    courseMonths.map(month => {
                                        return(
                                            <MonthSection key={month} title={month}>
                                                {courses.map(course => {
                                                    if(course.gradesSubmitted === "0"){
                                                        const removeTime = course.examDate.slice(0, -9)
                                                        const parseDate = parse(removeTime, "yyyy-MM-dd", new Date())
                                                        const formattedDay = format(parseDate, "do", { awareOfUnicodeTokens: true })
                                                        const formattedMonth = format(parseDate, "MMMM yyyy", { awareOfUnicodeTokens: true })

                                                        if (formattedMonth === month) {
                                                            return(
                                                                <Fragment key={course.id}>
                                                                    <AppIcon
                                                                        label={course.weaponsSystem}
                                                                        link={"course/" + course.id}
                                                                        type={'course'}
                                                                        examDate={formattedDay}
                                                                    />
                                                                    <IconLine />
                                                                </Fragment>
                                                            )
                                                        }
                                                    }
                                                    return false
                                                })
                                                }
                                            </MonthSection>
                                        )
                                    })
                                }

                                <CourseAdminIconsWrapper>
                                    <AppIcon
                                        label={"Add New Course"}
                                        link={"new-course"}
                                    />
                                    <IconLine />
                                    <AppIcon
                                        label={"Course Archive"}
                                        link={"course-archive"}
                                    />
                                    <IconLine />
                                </CourseAdminIconsWrapper>


                            </Section>
                            <Section title="Members" appWrap={true}>
                                <CourseIconsWrapper>
                                    <AppIcon
                                        label={"Browse Members"}
                                        link={"manage-members"}
                                    />
                                    <IconLine />
                                    <AppIcon
                                        label={"Invite Member"}
                                        link={"manage-members/invite-member"}
                                    />
                                    <IconLine />
                                    <AppIcon
                                        label={"Mailing Lists"}
                                        link={"manage-members/mailing-lists"}
                                    />
                                    <IconLine />
                                </CourseIconsWrapper>
                            </Section>
                        </>
                    }

                    {(userType === 'super-admin') &&
                        <>
                            <Section title="Super-Admin" appWrap={true}>
                                <CourseIconsWrapper>
                                    <AppIcon
                                        label={"Manage Admins"}
                                        link={"admin-management"}
                                    />
                                    <IconLine />
                                    <AppIcon
                                        label={"Invite Admin"}
                                        link={"admin-management/invite-admin"}
                                    />
                                    <IconLine />
                                    <AppIcon
                                        label={"View All Courses"}
                                        link={"admin-management/all-courses"}
                                    />
                                    <IconLine />
                                    <AppIcon
                                        label={"History Log"}
                                        link={"admin-management/history-log"}
                                    />
                                    <IconLine />
                                </CourseIconsWrapper>
                            </Section>
                        </>
                    }

                    {(userCtx.examiner === 'true') &&
                        <Section title="Examiner" appWrap={true}>
                            <AppIcon
                                label={"Validate Grades"}
                                link={"examiner"}
                            />
                        </Section>
                    }
                </div>

                <AccountSidePanel
                    userType={authCtx.userType}
                    name={userCtx.name}
                    firstName={userCtx.firstName}
                    surName={userCtx.surName}
                    email={userCtx.email}
                    phone={userCtx.phone}
                    tcr={userCtx.tcr}
                    level={assesLevel(userCtx.level)}
                    status={userCtx.status}
                    lapsed={userCtx.lapsed}
                />

                <Modal
                    showModal={(showCertificate !== false)}
                    toggleModal={() => closeCertificateHandler()}
                    noPadding={true}
                    printOption={true}
                >
                    {(selectedCertificate !== "empty") &&
                        <Certificate
                            name={userCtx.name}
                            weaponsSystem={selectedCertificate[0]['weaponsSystem']}
                            level={selectedCertificate[0]['level']}
                            teachers={selectedCertificate[0]['teachers']}
                            trainingProvider={selectedCertificate[0]['trainingProvider']}
                            examiner={selectedCertificate[0]['examiner']}
                            percentage={selectedCertificate[0]['percentage']}
                            examDate={selectedCertificate[0]['examDate']}
                            expiryDate={selectedCertificate[0]['expiryDate']}
                        />
                    }
                </Modal>

                <Modal
                    showModal={(showRot !== false)}
                    toggleModal={() => closeRotHandler()}
                    noPadding={true}
                    printOption={true}
                >
                    <RecordOfTrainingGet level={showRot} />
                </Modal>

            </div>
        </>
    )
}

export default Home