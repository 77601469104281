export const MembersColumns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Level',
        accessor: 'level'
    },
    {
        Header: 'TCR',
        accessor: 'tcr'
    },
    {
        Header: 'Profile'
    }
]