import style from './AddButton.module.scss';
import React from "react";
import plusImage from '../../../assets/images/green_plus.svg'

function AddButton({onClick, children, disabled}){

    return(
        <button
            disabled={disabled}
            onClick={onClick}
            className={[style.AddButton].join(" ")}
        >
            {children}
            <span><img src={plusImage} alt="" /></span>
        </button>
    )
}

export default AddButton;