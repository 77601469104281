import PercentageInput from "../Inputs/PercentageInput";
import {useState} from "react";
import style from "./AmendMemberGrade.module.scss"

const AmendMemberGrade = ({member, updatedGrades}) => {

    const [percent, setPercent] = useState(member.percent)

    const percentChangeHandler = (percent) =>{
        setPercent(percent)
    }

    return(
        <>
            <h1>Update Grade</h1>
            <div className={style.update_grade_container}>
                <table>
                    <tbody>

                    <tr>
                        <td><b>Name:</b></td>
                        <td>{member.name}</td>
                    </tr>
                    <tr>
                        <td><b>TCR</b></td>
                        <td>{member.tcr}</td>
                    </tr>
                    <tr>
                        <td><b>Percentage:</b></td>
                        <td style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <PercentageInput
                                value={(percent) => percentChangeHandler(percent)}
                                initialValue={percent}
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
            <button onClick={() => updatedGrades(percent)} className={"submit"} type="submit">Amend Grade</button>
        </>
    )
}

export default AmendMemberGrade