import ModalButtons from "../../ui-components/ModalButtons/ModalButtons";
import usePost from "../../../hooks/usePost";
import {useContext, useEffect, useState} from "react";
import authContext from "../../../store/auth-context";
import Iris from "../../ui-components/Iris/Iris";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";

const CloseYourAccount = ({closeModal}) => {

    const {sending, returnPostData, showMessage, returnMsg, postData} = usePost()
    const authCtx = useContext(authContext)

    const [iris, setIris] = useState(false)

    function closeAccountHandler(){
        setIris(true)
        const data = {
            id: authCtx.id,
            userType: authCtx.userType
        }
        postData(data, '/api/close-my-account/', 3000)
    }

    useEffect(() => {
        if(showMessage){
            setIris(false)
        }else{
            if(returnPostData){
                authCtx.logout()
            }
        }

    }, [showMessage, authCtx, iris, returnPostData])

    return(
        <>
            <h1>Close My Account</h1>
            <br />
            <div className='ReturnMsg ReturnMsgError'>
                Warning - closing your account is permanent and cannot be undone. All your information will be removed, including details of courses you’ve attended and grades you’ve achieved.
            </div>

            <ModalButtons
                button1Text={'Close Account'}
                button1Function={() => closeAccountHandler()}
                closeModal={closeModal}
            />

            <TrayToggle open={showMessage}>
                <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{returnMsg}</div>
            </TrayToggle>

            {sending && <div className={'sending'} />}
            {iris ? <Iris /> : null}
        </>
    )
}

export default CloseYourAccount