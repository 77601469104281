import {useContext, useEffect, useRef, useState} from "react"
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle"
import AuthContext from '../../../store/auth-context'
import TextInput from "../Inputs/TextInput"
import SelectInput from "../Inputs/SelectInput"
import DateInput from "../Inputs/DateInput"
import format from "date-fns/format"
import parse from "date-fns/parse"
import {useNavigate} from "react-router-dom"
import Loading from "../../ui-components/Loading/Loading"
import CoursesContext from "../../../store/courses-context"
import useGet from "../../../hooks/useGet";
import usePost from "../../../hooks/usePost";
import SorryTheresAProblem from "../../ui-components/SorryTheresAProblem/SorryTheresAProblem";
import {standardWeaponsList, furtherWeaponsList, foundationWeaponsList} from "../../../data/weaponsSystems"
import {levelsList} from '../../../data/levelSelect'

function CourseForm() {

    const {loading, returnData} = useGet('/api/tutors/', 300)
    const {postData, returnPostData, sending, returnMsg, showMessage} = usePost()

    const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const coursesCtx = useContext(CoursesContext)
    const form = useRef()

    //Set today's date
    const todayDate = format(new Date(), "MM-dd-yy", { awareOfUnicodeTokens: true })

    //States
    const [teachers, setTeachers] = useState(false)
    const [level, setLevel] = useState('Standard')
    const [weaponsSystem, setWeaponsSystem] = useState(false)
    const [weaponsSystemList, setWeaponsSystemList] = useState(standardWeaponsList)
    const [examDate, setExamDate] = useState(todayDate)
    const [trainingProvider, setTrainingProvider] = useState(false)
    const [coTeacher1, setCoTeacher1] = useState(false)
    const [coTeacher2, setCoTeacher2] = useState(false)
    const [forceValidation, setForceValidation] = useState(false)

    //Set teachers
    useEffect(()=>{
        if(returnData){
            let teachersArray = [[0, "Not Applicable"]]
            returnData.map(
                teacher => {
                    if(teacher.id !== authCtx.id){
                        teachersArray.push([teacher.id, teacher.name])
                    }
                    return false
                }
            )
            setTeachers(teachersArray)
        }
    }, [returnData, authCtx.id])

    //Redirect
    useEffect(() => {
        if(returnPostData.id){

            //Update the courses context
            let updatedCourses = coursesCtx.courses

            //Format date
            const parseDate = parse(examDate, "MM-dd-yy", new Date())
            const formattedDate = format(parseDate, "yyyy-MM-dd", { awareOfUnicodeTokens: true }) + ' 03:00:00'

            // Format teachers
            const formattedTeachers = [authCtx.id, coTeacher1, coTeacher2]
                .map(teacherId => returnData.find(teacher => teacher.id === teacherId)?.name || '')
                .filter(name => name !== '')
                .join(', ');

            const addCourse = {
                'id' : String(returnPostData.id),
                'weaponsSystem' : weaponsSystem,
                'examDate' : formattedDate,
                'trainingProvider' : trainingProvider,
                'teachers': formattedTeachers,
                'gradesSubmitted' : '0'
            }

            updatedCourses.push(addCourse)
            function compare(a, b) {
                if (a.examDate > b.examDate) return 1;
                if (a.examDate < b.examDate) return -1;
                return 0;
            }
            updatedCourses.sort(compare)
            coursesCtx.setCourses(updatedCourses)

            //Navigate
            navigate('/' + authCtx.userType + '/course/' + returnPostData.id)
        }
    }, [authCtx.userType, weaponsSystem, coursesCtx, examDate, trainingProvider, navigate, returnPostData.id])

    //Switch Weapons Systems list
    useEffect(() => {
        let weaponsSystemList = standardWeaponsList
        if (level === "Further"){
            weaponsSystemList = furtherWeaponsList
        }
        if (level === "Foundation"){
            weaponsSystemList = foundationWeaponsList
        }
        setWeaponsSystemList(weaponsSystemList)
    }, [level])

    function formSubmitHandler(e) {
        e.preventDefault()
        setForceValidation(true)
        if(weaponsSystem && examDate)
        {
            const data = {
                weaponsSystem: weaponsSystem,
                level: level,
                examDate: examDate,
                trainingProvider: trainingProvider,
                teacher1: authCtx.id,
                teacher2: coTeacher1,
                teacher3: coTeacher2
            }

            postData(data, '/api/courses/', 250)
        }
    }

    function levelHandler(status){
        setLevel(status)
    }

    function weaponsSystemHandler(status){
        setWeaponsSystem(status)
    }

    function examDateHandler(status){
        setExamDate(status)
    }

    function trainingProviderHandler(status){
        setTrainingProvider(status)
    }

    function coTeacher1Handler(status){
        setCoTeacher1(status)
    }

    function coTeacher2Handler(status){
        setCoTeacher2(status)
    }

    return (
        <>
            {loading ? <Loading /> : !teachers ? <SorryTheresAProblem message={"No teachers found."} />  :
                <form ref={form} onSubmit={formSubmitHandler} className={"form"} action="">

                    <div className={"field field--half"}>
                        <SelectInput
                            label={"Level"}
                            options={levelsList}
                            required={false}
                            selected={level}
                            value={(status) => levelHandler(status)}
                            sending={sending}
                            forceValidation={forceValidation}
                        />
                    </div>

                    <div className={"field field--half"}>
                        <SelectInput
                            label={"Weapons System"}
                            options={weaponsSystemList}
                            required={true}
                            selected={weaponsSystem}
                            value={(status) => weaponsSystemHandler(status)}
                            sending={sending}
                            forceValidation={forceValidation}
                        />
                    </div>

                    <div className={"field field--half"}>
                        <DateInput
                            label={"Exam Date"}
                            initialDate={examDate}
                            value={(status) => examDateHandler(status)}
                            sending={sending}
                            forceValidation={forceValidation}
                        />
                    </div>

                    <div className={"field field--half"}>
                        <TextInput
                            label={"Training Provider (optional)"}
                            value={(status) => trainingProviderHandler(status)}
                            sending={sending}
                            maxLength={50}
                            required={false}
                            forceValidation={forceValidation}
                        />
                    </div>

                    <div className={"field field--half"}>
                        <SelectInput
                            label={"Co-Tutor 1"}
                            options={teachers}
                            required={false}
                            value={(status) => coTeacher1Handler(status)}
                            sending={sending}
                            forceValidation={forceValidation}
                        />
                    </div>

                    <div className={"field field--half"}>
                        <SelectInput
                            label={"Co-Tutor 2"}
                            options={teachers}
                            required={false}
                            value={(status) => coTeacher2Handler(status)}
                            sending={sending}
                            forceValidation={forceValidation}
                        />
                    </div>

                    <TrayToggle open={showMessage}>
                        <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{returnMsg}</div>
                    </TrayToggle>
                    <button className={"submit"} type="submit" disabled={sending}>{!sending ? "Create Course" : "Sending..."}</button>

                    {sending && <div className={"sending"}/>}
                </form>
            }
        </>
    )
}

export default CourseForm