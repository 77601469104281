import style from './TopBarIcon.module.scss';
import bell from '../../../assets/images/bell.svg'

function TopBarIcon(props){

    let backgroundColour = false;
    if(props.status){
        backgroundColour = style.on;
    }

    let letter = 0
    if(props.firstName){
        letter = props.firstName.charAt(0).toUpperCase();
    }

    let iconBackground = <div className={style.letter}>{letter}</div>;

    if(props.type === "notifications"){
        iconBackground = <img src={bell} alt={''} />
    }

    return(
        <button onClick={props.click} className={[style.TopBarIcon, backgroundColour].join(' ')}>
            {iconBackground}
            {
                props.alert && <span className={style.alert} />
            }
        </button>
    )
}

export default TopBarIcon;