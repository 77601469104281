import {Link, useParams} from "react-router-dom";
import useGet from "../hooks/useGet";
import Loading from "../components/ui-components/Loading/Loading";
import Logo from "../components/ui-components/Logo/Logo";
import ExpiredInvite from "../components/functional-components/ExpiredInvite";
import CreateAdminProfileForm from "../components/functional-components/Forms/CreateAdminProfileForm";

function CreateAdminProfile(){

    const params = useParams();
    const url = '/api/invite/?invite_string=' + params.InviteString + '&user_type=admin'
    const {loading, returnMsg} = useGet(url, 2000)

    return(
        loading ? <Loading /> : !returnMsg ?
            <>
                <div className='login_container'>
                    <Link to={'/'}><Logo type={'big'} /></Link>
                    <div className={'login_box'}>
                        <h1>Become a BADC Admin</h1>
                        <CreateAdminProfileForm />
                    </div>
                </div>
            </> :
            <>
                <div className='login_container'>
                    <Link to={'/'}><Logo type={'big'} /></Link>
                    <div className={'login_box'}>
                        <ExpiredInvite />
                    </div>
                </div>
            </>
    )
}

export default CreateAdminProfile;