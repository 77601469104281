import style from './ButtonLittle.module.scss';

function ButtonLittle({onClick, disabled, children}){

    return(
        <button
            onClick={onClick}
            disabled={disabled}
            className={style.ButtonLittle}>
            {children}
        </button>
    )
}

export default ButtonLittle;