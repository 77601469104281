import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import {useContext} from "react";
import coursesContext from "../../store/courses-context";
import CourseArchiveTable from "../../components/functional-components/Tables/CourseArchiveTable";
import {useNavigate} from "react-router-dom";
import BackButton from "../../components/ui-components/BackButton/BackButton";
import authContext from '../../store/auth-context'

function CourseArchive(){

    const authCtx = useContext(authContext)
    const coursesCtx = useContext(coursesContext).courses
    const courses = coursesCtx.filter(item => item.gradesSubmitted === "1")
    const navigate = useNavigate()

    function compare(a, b) {
        if (a.examDate < b.examDate) return 1;
        if (a.examDate > b.examDate) return -1;
        return 0;
    }
    courses.sort(compare)

    function goToCourseHandler(courseId){
        if (authCtx.userType === 'super-admin') {
            navigate(`/super-admin/course-archive/${courseId}`)
        } else {
            navigate(courseId)
        }
    }

    return(
        <>
            <PageTitle>Course Archive</PageTitle>
            <CourseArchiveTable
                data={courses}
                goToCourse={(courseId) => goToCourseHandler(courseId)}
            />
            <BackButton>Home</BackButton>
        </>

    )
}

export default CourseArchive;