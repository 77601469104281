import React, {useMemo} from 'react';
import {useTable, usePagination, useSortBy, useGlobalFilter} from "react-table";
import {COLUMNS} from "./columns/membersOnCourseColumns";
import './table.scss';
import RemoveButton from "../../ui-components/RemoveButton/RemoveButton";

function MembersOnCourse(props) {

    const columns = useMemo(() => COLUMNS, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns: columns,
        data: props.data,
        initialState: { pageSize: 9999 }
    }, useGlobalFilter, useSortBy, usePagination);

    function cellContentHandler(columnTitle, content, id){

        //Remove
        if(columnTitle === "Remove"){
            return(
                <RemoveButton onClick={() => props.removeMember(id)}>Remove</RemoveButton>
            )
        }
        return content
    }

    return (
        <div className={'table_container'}>
            <div className={'table_container_padding'}>

                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup, i) =>(
                        <tr {...headerGroup.getHeaderGroupProps} key={i}>
                            {headerGroup.headers.map((column, i) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                    {column.render('Header')}
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {

                                    return <td{...cell.getCellProps()} key={cell.getCellProps().key}>
                                        {cellContentHandler(cell.column.Header, cell.render('Cell'), row.original.id)}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default MembersOnCourse;