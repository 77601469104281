import './certificate-thumbnail.scss'
import certificateBackground from '../../../assets/images/certificate-thumbnail.jpg'
import parse from "date-fns/parse"
import format from "date-fns/format"
import {default as parseHtml} from 'html-react-parser'

const CertificateThumbnail = ({openCertificate, weaponsSystem, examDate}) => {

    let formattedExamDate = false

    if (examDate) {
        const removeTime = examDate.slice(0, -9)
        const parseDate = parse(removeTime, "yyyy-MM-dd", new Date())
        formattedExamDate = format(parseDate, "MMMM do yyyy", { awareOfUnicodeTokens: true })
    }

    return(
        <button onClick={openCertificate} className='certificate-thumbnail'>
            <img className='certificate-thumbnail__background' src={certificateBackground} alt='' />
            <span className='certificate-thumbnail__inner'>
                <span className='certificate-thumbnail__inner__weapons-system'>{parseHtml(weaponsSystem.replace('(COVID-19)', '<span class="certificate-thumbnail__inner__weapons-system__covid-text">C19</span>'))}</span>
                {examDate ?
                    <span className='certificate-thumbnail__inner__exam-date'>{formattedExamDate}</span>
                    :
                    <span className='certificate-thumbnail__inner__exam-date'>Record of Training</span>
                }

            </span>
        </button>
    )
}

export default CertificateThumbnail