import style from './SorryTheresAProblem.module.scss'

const SorryTheresAProblem = ({message}) => {

    return(
        <div className={style.problem}>
            {message}
        </div>
    )

}

export default SorryTheresAProblem