import style from './HistoryList.module.scss';
import Loading from "../../ui-components/Loading/Loading";
import format from "date-fns/format";
import parse from "date-fns/parse";

const HistoryList = ({data, loading}) => {

    return(
        <div className={[style.history_container].join(" ")}>
            {
                loading ? <Loading /> :
                data.map(item => {

                    const removeTime = item.dateTime.slice(0, -9)
                    const parseDate = parse(removeTime, "yyyy-MM-dd", new Date())
                    const formattedDate = format(parseDate, "dd/MM/yy", { awareOfUnicodeTokens: true })

                    const time = item.dateTime.substring(11).slice(0, 5)

                    return <div className={style.history_item} key={item.id}><b>{formattedDate} [{time}]</b> - {item.action}</div>
                })
            }
        </div>
    )

}

export default HistoryList