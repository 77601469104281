import style from './Message.module.scss'

const Message = ({children, colour}) => {

    let theme = ''
    if(colour === 'orange'){
        theme = style.orange
    }

    return(
        <div className={[style.Message, theme].join(" ")}>
            {children}
        </div>
    )

}

export default Message