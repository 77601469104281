import {useContext, useRef, useState} from "react";
import axios from "axios";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import AuthContext from '../../../store/auth-context';
import PasswordInput from "../Inputs/PasswordInput";
import PasswordConfirmInput from "../Inputs/PasswordConfirmInput";

function UpdatePasswordForm() {

    const authCtx = useContext(AuthContext);
    const form = useRef();

    const [oldPassword, setOldPassword] = useState(false)
    const [password, setPassword] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState(false)

    const [sending, setSending] = useState(false)
    const [forceValidation, setForceValidation] = useState(false)
    const [returnMsg, setReturnMsg] = useState('')
    const [returnMsgError, setReturnMsgError] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    function formSubmitHandler(e) {
        e.preventDefault()
        setForceValidation(true)

        if(oldPassword && password && passwordConfirm)
        {
            setSending(true);
            setShowMessage(false);
            setShowSuccessMessage(false);

            const data = {
                userType: authCtx.userType,
                oldPassword: oldPassword,
                password: password
            };

            setTimeout(function(){

                const config = {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": "Bearer " + authCtx.token,
                    },
                };

                axios.post('/api/users/' + authCtx.id + '/update_password/', data, config).then(response => {
                    if(response.status === 200){
                        setReturnMsg(response.data.message)
                        setSending(false)

                        //Check for api error
                        if(response.data.error !== false){
                            setReturnMsgError("ReturnMsgError")
                            setShowMessage(true)
                        }else{
                            setReturnMsgError(false)
                            setShowSuccessMessage(true)
                            resetFormHandler()
                        }
                    }else{ connectionError()}
                }).catch(function (error) {
                    if (error) { connectionError()}
                });
            }, 500);
        }
    }

    function resetFormHandler(){
        form.current.reset()
        setForceValidation(false)
        setOldPassword(false)
        setPassword(false)
        setPasswordConfirm(false)
    }

    function connectionError(){
        setReturnMsg("There's been an issue connecting to the server. Please check your internet connection and try again.");
        setSending(false);
        setReturnMsgError("ReturnMsgError");
        setShowMessage(true);
    }

    function oldPasswordHandler(status){
        setOldPassword(status);
    }

    function passwordHandler(status){
        setPassword(status);
    }

    function passwordConfirmHandler(status){
        setPasswordConfirm(status);
    }

    return (
        <>
            <form ref={form} onSubmit={formSubmitHandler} className={"form"} action="">

                <div className={"field"}>
                    <PasswordInput
                        basicValidation={true}
                        value={(status) => oldPasswordHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Old password"}
                    />
                </div>

                <div className={"field"}>
                    <PasswordInput
                        value={(status) => passwordHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"New password"}
                    />
                </div>

                <div className={"field"}>
                    <PasswordConfirmInput
                        otherPassword={password}
                        value={(status) => passwordConfirmHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Confirm Password"}
                    />
                </div>

                <TrayToggle open={showMessage}>
                    <div className={["ReturnMsg", returnMsgError].join(" ")}>{returnMsg}</div>
                </TrayToggle>
                <TrayToggle open={showSuccessMessage}>
                    <div className={'ReturnMsg'}>{returnMsg}</div>
                </TrayToggle>

                <button className={"submit"} type="submit"
                        disabled={sending}>{!sending ? "Update" : "Sending..."}</button>

                {sending && <div className={"sending"}/>}
            </form>
        </>
    )
}

export default UpdatePasswordForm;