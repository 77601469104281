import './small-print.scss'

const SmallPrint = ({children}) => {

    return (

        <div className='small-print'>
            {children}
        </div>
    )

}

export default SmallPrint