import style from './MemberProfile.module.scss'
import Certificate from "../../functional-components/Certificate/Certificate";
import useGet from "../../../hooks/useGet";
import {useEffect, useState} from "react";
import Loading from "../../ui-components/Loading/Loading";
import red_cross from '../../../assets/images/red_cross.svg'
import green_tick from '../../../assets/images/green_tick.svg'
import assessGrade from "../../../utils/assessGrade";
import isDateInPast from "../../../utils/isDateInPast";
import FailedGrades from "../Tables/FailedGrades";
import parse from "date-fns/parse"
import format from "date-fns/format"
import SorryTheresAProblem from "../../ui-components/SorryTheresAProblem/SorryTheresAProblem";
import RecordOfTraining from '../RecordOfTraining/RecordOfTraining'
import assesLevel from '../../../utils/assessLevel'

const MemberProfile = ({memberId}) => {

    //Get
    const {loading: loading1, returnData: userData} = useGet('/api/users/' + memberId, 350)
    const {loading: loading2, returnData: rotData} = useGet('/api/record-of-training/' + memberId, 350)
    const {loading: loading3, returnData: contactPrefs} = useGet('/api/contact-preferences/' + memberId, 350)

    //States
    const [allLoads, setAllLoads] = useState(true)
    const [allData, setAllData] = useState(false)
    const [name, setName] = useState('')

    //Wait for everything to load and check the data
    useEffect(()=>{
        if(!loading1 && !loading2 && !loading3){
            setAllLoads(false)
            if(userData && contactPrefs){
                setName(userData.name)
                setAllData(true)
            }
        }
    }, [loading1, loading2, loading3, userData, contactPrefs])

    //Count valid and expired certificates
    let expiredCertificates = 0
    let validCertificates = 0
    let failedCertificates = []
    let pendingCertificates = []

    if(userData.grades){
        userData.grades.map((grade) => {
            const result = assessGrade(grade.percentage, grade.level);
            if(result !== "Failed"){
                if(isDateInPast(grade.expiryDate)){
                    expiredCertificates = expiredCertificates + 1
                }else{
                    if (grade.confirmed === '1') {
                        validCertificates = validCertificates + 1
                    } else {
                        pendingCertificates.push(grade)
                    }
                }
            }else{
                failedCertificates.push(grade)
            }
            return false
        })
    }

    //Format joined date
    let formattedExamDate = null
    if(allData){
        const parseDate = parse(userData.joined_date, "yyyy-MM-dd", new Date())
        formattedExamDate = format(parseDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
    }

    return(
        <>
            {allLoads ? <Loading/> : !allData ? <SorryTheresAProblem message={"There's been an issue loading this user's information. Please refresh the app."} /> :
                <div className={style.MemberProfile}>
                    <div>
                        <h1>{name}</h1>
                        <div className={style.tcr}>(TCR: {userData.tcr})</div>
                    </div>

                    <div className={style.line} />

                    <h2>About:</h2>
                    <div className={style.profile_item}>
                        <span className={style.label}>Email:</span> {userData.email}
                    </div>
                    <div className={style.profile_item}>
                        <span className={style.label}>Tel:</span> {userData.phone}
                    </div>
                    <div className={style.profile_item}>
                    </div>
                    <div className={style.profile_item}>
                        <span className={style.label}>Joined:</span> {formattedExamDate}
                    </div>
                    <div className={style.profile_item}>
                        <span className={style.label}>Gender identity:</span> {userData.gender}
                    </div>
                    <div className={style.profile_item}>
                        <span className={style.label}>Training Status:</span> {(userData.status === "") ? "–" : userData.status}
                    </div>
                    <div className={style.profile_item}>
                        <span className={style.label}>Level:</span> {assesLevel(userData.level)} {userData.lapsed}
                    </div>

                    <div className={style.line} />

                    <h2>Contact Preferences:</h2>
                    <table>
                        <tbody>
                        <tr>
                            <td>BADC News</td>
                            <td>
                                {contactPrefs.general_news ?
                                    <div className={style.contact_pref_yes}><img src={green_tick} alt={''}/></div>
                                    :
                                    <div className={style.contact_pref_no}><img src={red_cross} alt={''}/></div>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Membership Info</td>
                            <td>
                                {contactPrefs.admin_alerts ?
                                    <div className={style.contact_pref_yes}><img src={green_tick} alt={''}/></div>
                                    :
                                    <div className={style.contact_pref_no}><img src={red_cross} alt={''}/></div>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Upcoming Courses</td>
                            <td>
                                {contactPrefs.upcoming_courses ?
                                    <div className={style.contact_pref_yes}><img src={green_tick} alt={''}/></div>
                                    :
                                    <div className={style.contact_pref_no}><img src={red_cross} alt={''}/></div>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    {userData.level &&
                        <>
                            <div className={style.line}/>
                            <h2>Record of Training:</h2>
                            {userData.level.includes("4") &&
                                <RecordOfTraining
                                    name={name}
                                    level={'Advanced'}
                                    rotData={rotData}
                                    margins={true}
                                />
                            }
                            {userData.level.includes("3") &&
                                <RecordOfTraining
                                    name={name}
                                    level={'Intermediate'}
                                    rotData={rotData}
                                    margins={true}
                                />
                            }
                            {userData.level.includes("2") &&
                                <RecordOfTraining
                                    name={name}
                                    level={'Standard'}
                                    rotData={rotData}
                                    margins={true}
                                />
                            }
                            {userData.level.includes("1") &&
                                <RecordOfTraining
                                    name={name}
                                    level={'Foundation'}
                                    rotData={rotData}
                                    margins={true}
                                />
                            }
                        </>
                    }

                    {validCertificates ?
                        <>
                            <div className={style.line} />
                            <h2>Certificates:</h2>
                            {userData.grades.map(grade => {
                                const result = assessGrade(grade.percentage, grade.level);
                                if (result !== "Failed" && (grade.confirmed === '1')) {
                                    if (!isDateInPast(grade.expiryDate)) {
                                        return <Certificate
                                            name={name}
                                            weaponsSystem={grade.weaponsSystem}
                                            level={grade.level}
                                            teachers={grade.teachers}
                                            examiner={grade.examiner}
                                            trainingProvider={grade.trainingProvider}
                                            examDate={grade.examDate}
                                            percentage={grade.percentage}
                                            expiryDate={grade.expiryDate}
                                            key={grade.id}
                                            margins={true}
                                        />
                                    }
                                }
                                return false;
                            })

                            }
                        </> : null
                    }

                    {expiredCertificates ?
                        <>
                            <div className={style.line} />
                            <h2>Expired Certificates:</h2>
                            {userData.grades.map(grade => {
                                const result = assessGrade(grade.percentage, grade.level);
                                if (result !== "Failed") {
                                    if (isDateInPast(grade.expiryDate)) {
                                        return <Certificate
                                            name={name}
                                            weaponsSystem={grade.weaponsSystem}
                                            level={grade.level}
                                            teachers={grade.teachers}
                                            examiner={grade.examiner}
                                            examDate={grade.examDate}
                                            percentage={grade.percentage}
                                            expiryDate={grade.expiryDate}
                                            key={grade.id}
                                            margins={true}
                                        />
                                    }
                                }
                                return false;
                            })

                            }
                        </> : null
                    }

                    {pendingCertificates.length ?
                        <>
                            <div className={style.line} />
                            <h2>Pending Confirmation:</h2>
                            <FailedGrades
                                data={pendingCertificates}
                            />
                        </> : null
                    }

                    {failedCertificates.length ?
                        <>
                            <div className={style.line} />
                            <h2>Fails:</h2>
                            <FailedGrades
                                data={failedCertificates}
                            />
                        </> : null
                    }
                </div>
            }
        </>
    )
}

export default MemberProfile