function NotFound(){

    return(
        <>
            404 Error<br/>
            Page not found
        </>
    )
}

export default NotFound;