import {useContext} from 'react'
import userContext from '../../../store/user-context'
import useGet from '../../../hooks/useGet'
import Loading from '../../ui-components/Loading/Loading'
import SorryTheresAProblem from '../../ui-components/SorryTheresAProblem/SorryTheresAProblem'
import RecordOfTraining from '../RecordOfTraining/RecordOfTraining'

const RecordOfTrainingGet = ({level}) => {

    // Context
    const userCtx = useContext(userContext)

    // Get Record of Training
    const {returnData: rotData , loading} = useGet('/api/record-of-training/' + userCtx.id, 600)

    return (
        <>
            {
                loading ? <Loading/> : !rotData ? <SorryTheresAProblem message={"There's been an issue loading your Record of Training. Please refresh the app."} /> :
                    <RecordOfTraining
                        name={userCtx.name}
                        level={level}
                        rotData={rotData}
                    />
            }
        </>
    )
}

export default RecordOfTrainingGet