function assesGrade(percent, courseLevel){

    let grade = "–"

    if(percent){
        if(percent === ''){
            grade = "–"
        }else{
            if(percent < 45){
                grade = "Failed"
            }else{
                if(percent >= 45){
                    grade = "Pass"
                }

                if(percent <= 100) {
                    if (courseLevel !== "Foundation") {

                        if(courseLevel === "Standard"){
                            if(percent >= 60){
                                grade = "Merit"
                            }
                        }

                        if(courseLevel === "Further"){
                            if(percent >= 60){
                                grade = "Merit"
                            }

                            if(percent >= 80){
                                grade = "Distinction"
                            }
                        }

                    }
                }
            }
        }
    }

    return(grade)
}

export default assesGrade