import style from './Menu.module.scss'
import burgerMenuBackgroundImage from '../../../../assets/images/burger-menu-button.svg'
import burgerMenuClose from '../../../../assets/images/burger-menu-close.svg'
import {Fragment, useContext, useEffect, useState} from "react"
import AuthContext from "../../../../store/auth-context"
import {NavLink} from "react-router-dom"
import {useLocation} from "react-router"
import PlusToArrow from "../../PlusToArrow/PlusToArrow"
import TrayToggle from "../../TrayToggle/TrayToggle"
import CoursesContext from "../../../../store/courses-context"
import NotificationContext from "../../../../store/notification-context"

function Menu({logout}){

    const authCtx = useContext(AuthContext)
    const notificationsCtx = useContext(NotificationContext)
    const userType = authCtx.userType
    const courses = useContext(CoursesContext).courses
    const [menuOpen, setMenuOpen] = useState(false)
    const [examinerCoursesOpen, setExaminerCoursesOpen] = useState(false)
    const [coursesOpen, setCoursesOpen] = useState(false)
    const [membersOpen, setMembersOpen] = useState(false)
    const [superAdminOpen, setSuperAdminOpen] = useState(false)
    const [accountOpen, setAccountOpen] = useState(false)
    const activeCourses = courses ? courses.filter(course => course.gradesSubmitted === "0") : null
    const examinerNotifications = notificationsCtx.notifications.filter(notification => (notification.type === "examiner"))

    const location = useLocation()
    const FocusTrap = require('focus-trap-react')

    useEffect(() => {
        const section = location.pathname.split('/')[2]

        if(!section){
            setCoursesOpen(false)
            setMembersOpen(false)
            setSuperAdminOpen(false)
            setAccountOpen(false)
            setExaminerCoursesOpen(false)
        }

        if(section === "course" || section === "course-archive" || section === "new-course"){
            coursesOpenHandler()
        }

        if(section === "grades-confirm"){
            examinerCoursesOpenHandler()
        }

        if(section === "profile"){
            accountOpenHandler()
        }

        if(section === "manage-members"){
            membersOpenHandler()
        }

        if(section === "admin-management"){
            superAdminOpenHandler()
        }

    }, [location])


    const openMenu = () => {
        setMenuOpen(true)
    }

    const closeMenu = () => {
        setMenuOpen(false)
    }

    const coursesToggleHandler = () =>{
        if(coursesOpen){setCoursesOpen(false)
        }else{coursesOpenHandler()}
    }

    const coursesOpenHandler = () => {
        setMembersOpen(false)
        setSuperAdminOpen(false)
        setAccountOpen(false)
        setCoursesOpen(true)
        setExaminerCoursesOpen(false)
    }

    const examinerCoursesToggleHandler = () =>{
        if(examinerCoursesOpen){setExaminerCoursesOpen(false)
        }else{examinerCoursesOpenHandler()}
    }

    const examinerCoursesOpenHandler = () => {
        setMembersOpen(false)
        setSuperAdminOpen(false)
        setAccountOpen(false)
        setCoursesOpen(false)
        setExaminerCoursesOpen(true)
    }

    const membersToggleHandler = () =>{
        if(membersOpen){setMembersOpen(false)
        }else{membersOpenHandler()}
    }

    const membersOpenHandler = () => {
        setCoursesOpen(false)
        setSuperAdminOpen(false)
        setAccountOpen(false)
        setMembersOpen(true)
        setExaminerCoursesOpen(false)
    }

    const superAdminToggleHandler = () =>{
        if(superAdminOpen){setSuperAdminOpen(false)
        }else{superAdminOpenHandler()}
    }

    const superAdminOpenHandler = () => {
        setCoursesOpen(false)
        setMembersOpen(false)
        setAccountOpen(false)
        setSuperAdminOpen(true)
        setExaminerCoursesOpen(false)
    }

    const accountToggleHandler = () =>{
        if(accountOpen){setAccountOpen(false)
        }else{accountOpenHandler()}
    }

    const accountOpenHandler = () => {
        setCoursesOpen(false)
        setMembersOpen(false)
        setSuperAdminOpen(false)
        setAccountOpen(true)
        setExaminerCoursesOpen(false)
    }

    return(
        <FocusTrap active={menuOpen}>
            <div>
                <button onClick={openMenu} style={{backgroundImage: "url(" + burgerMenuBackgroundImage + ")"}} className={[style.BurgerMenuButton, "hide-text"].join(" ")}>Menu</button>

                <div className={[style.mobMenu, menuOpen ? style.openMenu : null].join(" ")}>

                    <div onClick={closeMenu} className={style.mobMenu_background} />

                    <div className={[style.mobMenu_tray, 'scrollable'].join(" ")}>
                        <button onClick={closeMenu} className={[style.mobMenu_tray_close, "hide-text"].join(" ")}>
                            Close
                            <img src={burgerMenuClose} alt="" />
                        </button>
                        <nav>

                            <NavLink activeClassName={style.linkActive} onClick={closeMenu} className={style.bigLink} to={"/" + userType} end>Home</NavLink>

                            {(userType === 'member' && courses) &&
                                <>
                                    <button className={style.bigLink} onClick={coursesToggleHandler}>Your Courses<PlusToArrow open={coursesOpen}/></button>
                                    <TrayToggle speed={"fast"} open={coursesOpen}>
                                        {
                                            courses.map(course => {
                                                return(
                                                    <Fragment key={course.id}>
                                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/course/" + course.id}>{course.weaponsSystem}</NavLink><br/>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </TrayToggle>
                                </>
                            }

                            {(examinerNotifications.length > 0) &&
                                <>
                                    <button className={style.bigLink} onClick={examinerCoursesToggleHandler}>Examiner<PlusToArrow open={examinerCoursesOpen}/></button>
                                    <TrayToggle speed={"fast"} open={examinerCoursesOpen}>
                                        {
                                            examinerNotifications.map(course => {
                                                return(
                                                    <Fragment key={course.id}>
                                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/grades-confirm/" + course.id}>{course.weaponsSystem}</NavLink><br/>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </TrayToggle>

                                </>
                            }

                            {(userType === 'admin' || userType === 'super-admin') &&
                                <>
                                    <button className={style.bigLink} onClick={coursesToggleHandler}>Your Courses<PlusToArrow open={coursesOpen}/></button>
                                    <TrayToggle speed={"fast"} open={coursesOpen}>
                                        {
                                            activeCourses.map(course => {
                                                return(
                                                    <Fragment key={course.id}>
                                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/course/" + course.id}>{course.weaponsSystem}</NavLink><br/>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/course-archive"}>Course Archive</NavLink><br/>
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/new-course"}>New Course</NavLink><br/>
                                    </TrayToggle>

                                    <button className={style.bigLink} onClick={membersToggleHandler}>Members<PlusToArrow open={membersOpen}/></button>
                                    <TrayToggle speed={"fast"} open={membersOpen}>
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/manage-members"} end>Browse Members</NavLink><br/>
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/manage-members/invite-member"}>Invite Member</NavLink><br/>
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/manage-members/mailing-lists"}>Mailing Lists</NavLink><br/>
                                    </TrayToggle>
                                </>
                            }

                            {(userType === 'super-admin') &&
                                <>
                                    <button className={style.bigLink} onClick={superAdminToggleHandler}>Super Admin<PlusToArrow open={superAdminOpen}/></button>
                                    <TrayToggle speed={"fast"} open={superAdminOpen}>
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/admin-management"} end>Manage Admins</NavLink><br/>
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/admin-management/invite-admin"}>Invite Admin</NavLink><br/>
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/admin-management/all-courses"}>View All Courses</NavLink><br/>
                                        <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/admin-management/history-log"}>History Log</NavLink><br/>
                                    </TrayToggle>
                                </>
                            }

                            <>
                                <button className={style.bigLink} onClick={accountToggleHandler}>Your Account<PlusToArrow open={accountOpen}/></button>
                                <TrayToggle speed={"fast"} open={accountOpen}>
                                    <NavLink onClick={closeMenu} to={"/" + userType + "/profile"} className={style.smallLink} activeClassName={style.linkActive} end>Personal Details</NavLink><br/>
                                    <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/profile/contact-preferences"}>Contact Preferences</NavLink><br/>
                                    <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/profile/security"}>Security</NavLink><br/>
                                    <NavLink onClick={closeMenu} className={style.smallLink} activeClassName={style.linkActive} to={"/" + userType + "/profile/close-account"}>Close Account</NavLink><br/>
                                </TrayToggle>
                            </>

                            <button className={style.bigLink} onClick={logout}>Log Out</button>

                        </nav>
                    </div>
                </div>
            </div>
        </FocusTrap>
    )
}

export default Menu