import {useEffect, useRef, useState} from "react";

function EmailInput(props){

    const input = useRef();
    const [error, setError] = useState(true)
    const [showError, setShowError] = useState(false)

    const validateHandler = () => {
        props.value(false);
        switch(true) {
            case (input.current.value.length === 0):
                setError("Please enter an email address")
                return
            case (!input.current.value.replace(/\s/g, '').length):
                setError("Please enter an email address")
                return
            case (input.current.value.length > 75):
                setError("Please enter an email address under 75 characters")
                return
            case (!/\S+@\S+\.\S+/.test(input.current.value)):
                setError("Your email address is invalid")
                return
            default:
                setError(false);
                setShowError(false);
                props.value(input.current.value);
                return
        }
    }

    const showErrorHandler = () => {
        validateHandler()
        setShowError(true)
    }

    useEffect(() => {
        if(props.forceValidation){
            showErrorHandler();
        }
    })

    return(
        <>
            <label htmlFor="email" className={"label"}>{props.label}:</label>
            {(error && showError) && <div className={"bubble"}>{error}</div>}
            <input
                defaultValue={props.defaultValue}
                ref={input}
                type="text"
                name="email"
                maxLength={75}
                onBlur={showErrorHandler}
                onChange={validateHandler}
                className={"input"}
                autoComplete={"email"}
                disabled={props.sending}
            />
        </>
    )
}

export default EmailInput;