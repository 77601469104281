import {useContext, useEffect, useState} from "react"
import axios from "axios"
import AuthContext from "../store/auth-context"

const useGet = (url, delay) => {

    //Get Auth Context
    const authCtx = useContext(AuthContext)

    //States
    const [returnData, setReturnData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [returnMsg, setReturnMsg] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    useEffect(()=>{

        if(url !== false) {

            setLoading(true)
            setReturnData(false)

            setTimeout(function () {

                let config = {}

                if (authCtx.id) {
                    config = {
                        headers: {
                            "Content-type": "application/json",
                            "Authorization": "Bearer " + authCtx.token,
                        },
                    }
                }

                axios.get(url, config).then(response => {
                    if(response.status === 200){
                        setShowSuccessMessage(true)
                        setReturnData(response.data)
                        setReturnMsg(response.data.message)
                        setLoading(false)
                    }else{
                        setShowMessage(true)
                        setReturnMsg("There's been a problem on the server. Please make a note of what you were doing and contact the administrator.")
                        setLoading(false)
                    }
                }).catch(function (error) {
                    if (error) {
                        setShowMessage(true)
                        setReturnMsg("Could not connect to the server. Please check your internet connection and refresh the page.")
                        setLoading(false)
                    }
                });
            }, delay)
        }else{
            setLoading(false)
        }
    }, [authCtx.id, authCtx.token, delay, url])

    return {
        loading,
        returnData,
        returnMsg,
        showMessage,
        showSuccessMessage
    }
}

export default useGet