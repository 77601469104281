import React, {useRef} from "react"
import ReactDOM from 'react-dom';
import './Modal.scss'
import {useEffect} from "react";
import white_cross from '../../../assets/images/white_cross.svg'
import {CSSTransition} from "react-transition-group";
import {useReactToPrint} from 'react-to-print';

const Modal = ({showModal, toggleModal, noPadding, printOption, children}) => {

    const FocusTrap = require('focus-trap-react');
    const nodeRef = useRef(null)
    const printableContent = useRef(null)

    const printHandler = useReactToPrint({
        content: () => printableContent.current
    });

    useEffect(() =>{

        if(showModal){
            document.body.classList.add('modal-open')
        }

        const close = (e) => {
            if(e.key === 'Escape'){
                toggleModal()
            }
        }
        window.addEventListener('keydown', close)

        return(() => {
            window.removeEventListener('keydown', close)
            document.body.classList.remove('modal-open')
        })
    }, [toggleModal, showModal])

    return(
        ReactDOM.createPortal(
            <CSSTransition
                nodeRef={nodeRef}
                in={showModal}
                timeout={500}
                unmountOnExit
            >
                <FocusTrap>
                    <div ref={nodeRef} className={['modal', 'scrollable'].join(" ")}>
                        <div onClick={toggleModal} className='modal_backdrop' />
                        <div className={'modal_content_scroll'}>
                            <div className={['modal_content', (noPadding ? "modal-no-padding" : null)].join(" ")}>
                                <button style={{backgroundImage: "url(" + white_cross + ")"}} onClick={toggleModal} className={['hide-text', 'modal_close'].join(" ")}>Close Modal</button>
                                <div ref={printableContent}>
                                    {children}
                                </div>
                                {printOption && <div className='modal__print'><button onClick={printHandler}>Click to print certificate</button></div>}
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            </CSSTransition>
            , document.body)
    )
}
export default Modal