export const archivedMembersColumnsWithDelete = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'TCR',
        accessor: 'tcr'
    },
    {
        Header: 'Percentage',
        accessor: 'percent'
    },
    {
        Header: 'Grade',
        accessor: '-'
    },
    {
        Header: 'Delete',
        accessor: 'Delete'
    }
]