import ModalButtons from "../../ui-components/ModalButtons/ModalButtons";
import usePost from "../../../hooks/usePost";
import {useEffect} from "react";

const DeleteAdmin = ({user, closeModal, updateAdmins}) => {

    const {sending, returnPostData, postData} = usePost()

    function deleteAdminHandler(){
        const data = {
            adminId: user.id
        }
        postData(data, '/api/admins/delete/', 1000)
    }

    useEffect(() => {
        if(returnPostData){
            updateAdmins(user.id)
            closeModal()
        }

    }, [returnPostData, closeModal, updateAdmins, user.id])

    return(
        <>
            <h1>Delete Admin</h1>
            <br />
            <div className='ReturnMsg ReturnMsgError'>
                Are you sure you want to delete this administrator? Any courses they've run and grades they've set will be retained but all other account information will be deleted from the system.
            </div>
            <p>Name: {user.name}</p>
            <p>Email: {user.email}</p>

            <ModalButtons
                button1Text={'Delete Account'}
                button1Function={() => deleteAdminHandler()}
                closeModal={closeModal}
            />
            {sending && <div className={'sending'} />}
        </>
    )
}

export default DeleteAdmin