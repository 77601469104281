import React from 'react';
import PageTitle from "../../../components/ui-components/PageTitle/PageTitle";
import UpdateDetailsForm from "../../../components/functional-components/Forms/UpdateDetailsForm";

function PersonalDetails() {
    return (
        <>
            <PageTitle>Personal Details</PageTitle>
            <UpdateDetailsForm />
        </>
    );
}

export default PersonalDetails;