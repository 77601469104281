import LoginForm from "../components/functional-components/Forms/LoginForm";
import Logo from "../components/ui-components/Logo/Logo";
import {Link} from "react-router-dom";

function Login(){
    return(
        <div className='login_container'>
            <Logo type={'big'} />
            <div className={'login_box'}>
                <div className={'login_links'}>
                    <div className={'login_link login_link_selected'}>Members</div>
                    <div className={'login_divide'} />
                    <Link className={'login_link'} to={'/admin-login'}>Admin</Link>
                </div>
                <LoginForm userType={'member'} />
            </div>
        </div>
    )
}
export default Login;