import style from './BackButton.module.scss'
import {useNavigate} from "react-router-dom";
import leftArrow from '../../../assets/images/chevron_left.svg'
import {useContext} from "react";
import authContext from "../../../store/auth-context";

const BackButton = ({children, navigate}) => {

    const goTo = useNavigate();
    const authCtx = useContext(authContext)

    let address = '/' + authCtx.userType
    if(navigate){
        address = navigate
    }

    return(
        <>
            <button
                className={style.back}
                onClick={() => goTo(address)}
            >
                <span><img src={leftArrow} alt='' /></span>
                {children}
            </button>
        </>
    )
}

export default BackButton