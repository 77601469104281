import style from './TopBar.module.scss';
import Logo from "../Logo/Logo";
import {useContext, useEffect, useRef, useState} from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import {Link} from "react-router-dom";
import TopBarIcon from "./TopBarIcon";
import UserContext from "../../../store/user-context";
import Notifications from "./Notifications/Notifications";
import notificationContext from "../../../store/notification-context";
import Account from "./Account/Account";
import AuthContext from "../../../store/auth-context";
import Menu from "./Menu/Menu";

function TopBar(){

    const userCtx = useContext(UserContext)
    const authCtx = useContext(AuthContext)
    const notificationsCtx = useContext(notificationContext)

    const [showNotifications, setShowNotifications] = useState(false)
    const [showAccount, setShowAccount] = useState(false)
    const [notificationAlert, setNotificatioAlert] = useState(true)

    useEffect(() => {
        let unread = 0
        notificationsCtx.notifications.map(notification => {
            if (notification.read === false){
                unread = unread + 1
            }
            return true}
        )
        if(unread === 0){
            setNotificatioAlert(false)
        }
    }, [notificationsCtx])

    function showNotificationsHandler(){
        setShowNotifications(!showNotifications);
        setShowAccount(false);
    }

    function showAccountHandler(){
        setShowAccount(!showAccount);
        setShowNotifications(false);
    }

    const nodeRef = useRef(null);
    useClickOutside(nodeRef, () => {
        setShowNotifications(false);
        setShowAccount(false);
    });

    return(
        <div className={style.TopBar}>
            <div className={[style.inner, 'app_width'].join(' ')}>

                <Menu
                    logout={authCtx.logout}
                />

                <Link className={style.Logo} to={''}><Logo /></Link>

                <div ref={nodeRef}>
                    <TopBarIcon
                        click={showNotificationsHandler}
                        type={"notifications"}
                        status={showNotifications}
                        alert={notificationAlert}
                    />
                    {showNotifications &&
                        <div className={style.DropDownTray}>
                            <Notifications
                                notificationsCtx={notificationsCtx}
                                userType={authCtx.userType}
                                closeNotifications={() => showNotificationsHandler()}
                            />
                        </div>
                    }

                    <TopBarIcon
                        click={showAccountHandler}
                        type={"account"}
                        status={showAccount}
                        firstName={userCtx.firstName}
                    />
                    {showAccount &&
                        <div className={style.DropDownTray}>
                            <Account
                                user={userCtx}
                                closeAccount={() => showAccountHandler()}
                                logout={authCtx.logout}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default TopBar;