import React, {useState} from "react";

const CoursesContext = React.createContext({
    setCourses: null,
    courses: null
});

export const CoursesContextProvider = (props) => {

    const [courses, setCourses] = useState(null);

    function setCoursesHandler(userData){
        setCourses(userData);
    }

    const contextValue = {
        setCourses: setCoursesHandler,
        courses: courses
    };

    return (
        <CoursesContext.Provider value={contextValue}>
            {props.children}
        </CoursesContext.Provider>
    );
};

export default CoursesContext;