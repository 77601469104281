import TopBar from "../ui-components/TopBar/TopBar"
import {Outlet} from "react-router-dom"
import {useEffect, useState} from "react"
import AuthContext from "../../store/auth-context"
import UserContext from "../../store/user-context"
import {useContext} from "react"
import Loading from '../ui-components/Loading/Loading'
import useGet from "../../hooks/useGet"
import CoursesContext from "../../store/courses-context"
import NotificationsContext from "../../store/notification-context";
import Footer from "../ui-components/Footer/Footer";
import SorryTheresAProblem from "../ui-components/SorryTheresAProblem/SorryTheresAProblem";

function AdminAreaWrapper(){

    //Hooks
    const authCtx = useContext(AuthContext)
    const userCtx = useContext(UserContext)
    const coursesCtx = useContext(CoursesContext)
    const notificationsCtx = useContext(NotificationsContext)

    //Get
    const {loading: loading1, returnData: userData} = useGet('/api/admins/' + authCtx.id, 1000)
    const {loading: loading2, returnData: courses} = useGet('/api/teacher-courses/', 1000)
    const {loading: loading3, returnData: notifications} = useGet('/api/notifications/', 1000)

    //States
    const [allLoads, setAllLoads] = useState(true)
    const [allData, setAllData] = useState(false)

    //Wait for everything to load and check the data
    useEffect(()=>{
        if(!loading1 && !loading2 && !loading3){
            setAllLoads(false)
            if(!userData.error && !courses.error && !notifications.error){
                if(!userCtx.id){
                    userCtx.setUser(userData)
                }
                if(!coursesCtx.courses){
                    coursesCtx.setCourses(courses)
                }
                if(!notificationsCtx.notifications){
                    notificationsCtx.setNotifications(notifications)
                }
                setAllData(true)
            }
        }
    }, [loading1, loading2, loading3, userCtx, userData, courses, notificationsCtx, notifications, coursesCtx, authCtx.id])

    return(
        <div className='members_area'>
            {allLoads ? <Loading/> : !allData ? <SorryTheresAProblem message={"There's been an issue loading your admin area. Please refresh the app."} /> :
                <>
                    <TopBar/>
                    <div className={'app_width'}>
                        <div className={'members_area_body'}>
                            <Outlet/>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </div>
    )
}

export default AdminAreaWrapper