import React, {useMemo} from 'react';
import {useTable, usePagination, useSortBy, useGlobalFilter} from "react-table";
import {COLUMNS} from "./columns/gradeStudentViewColumns";
import './table.scss';
import assesGrade from "../../../utils/assessGrade";
import format from "date-fns/format";
import parse from "date-fns/parse";

function GradeStudentView(props) {

    const columns = useMemo(() => COLUMNS, []);
    const data = props.data;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({
        columns: columns,
        data: data,
        initialState: { pageSize: 9999 }
    }, useGlobalFilter, useSortBy, usePagination);

    function cellContentHandler(columnTitle, content, percent, level){

        //Percent
        if(columnTitle === "Percent"){
            return percent + "%"
        }

        //Grade
        if(columnTitle === "Grade"){
            return assesGrade(percent, level)
        }

        //Format Exam Date
        if(columnTitle === "Expires"){
            const checkFailed = assesGrade(percent)
            if(checkFailed === "Failed"){
                return "–"
            }else{
                const removeTime = content.props.value.slice(0, -9)
                const parseDate = parse(removeTime, "yyyy-MM-dd", new Date())
                const formattedDate = format(parseDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
                return(
                    formattedDate
                )
            }
        }

        return content
    }


    return (
        <div className={'table_container'}>
            <div className={'table_container_padding'}>

                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup, i) =>(
                        <tr {...headerGroup.getHeaderGroupProps} key={i}>
                            {headerGroup.headers.map((column, i) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                    {column.render('Header')}
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, i) => {
                                    return <td{...cell.getCellProps()} key={cell.getCellProps().key}>
                                        {cellContentHandler(cell.column.Header, cell.render('Cell'), row.original.percentage, props.data[0].level)}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default GradeStudentView;