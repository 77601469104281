export const COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'TCR',
        accessor: 'tcr'
    },
    {
        Header: 'Percentage',
        accessor: 'percent'
    },
    {
        Header: 'Grade',
        accessor: '-'
    },
    {
        Header: 'Amend',
        accessor: ''
    }
]