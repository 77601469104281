import {useContext, useEffect} from "react";
import authContext from "../../../store/auth-context";
import coursesContext from "../../../store/courses-context";
import usePost from "../../../hooks/usePost";
import {useNavigate, useParams} from "react-router-dom";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import notificationContext from "../../../store/notification-context";

const SendGrades = (props) => {

    const authCtx = useContext(authContext)
    const coursesCtx = useContext(coursesContext)
    const notificationsCtx = useContext(notificationContext)
    const params = useParams()
    const navigate = useNavigate()
    const {postData, returnPostData, sending, returnMsg, showMessage} = usePost()

    //Submit form
    function formSubmitHandler(e) {
        e.preventDefault()
        const data = {
            examinerId: -1,
            grades: props.grades
        };
        postData(data, '/api/courses/' + params.course_id + '/grades', 600)
    }

    //Set Examiners
    useEffect(()=>{

        //Grades successfully added
        if(returnPostData){
            if(!returnPostData.error){
                //Update the courses context
                let updatedCourses = []
                coursesCtx.courses.map(course => {

                    let gradesSubmitted = course.gradesSubmitted
                    if(params.course_id === course.id){
                        gradesSubmitted = "1"
                    }
                    updatedCourses.push({
                            'id': course.id,
                            'weaponsSystem' : course.weaponsSystem,
                            'teachers': course.teachers,
                            'examDate' : course.examDate,
                            'gradesSubmitted' : gradesSubmitted
                        }
                    )
                    return false
                })
                coursesCtx.setCourses(updatedCourses)

                //Update notifications
                const updatedNotifications = notificationsCtx.notifications.filter(item => !(item.type === 'course' && item.id === params.course_id))
                notificationsCtx.setNotifications(updatedNotifications)

                //Update temp notification
                const data = {
                    title: "Grades submitted",
                    message: "Your grades have successfully been submitted, students will now receive their certificates."
                }
                notificationsCtx.setTempNotification(data)

                //Redirect
                navigate('/' + authCtx.userType);
            }
        }
    }, [returnPostData, authCtx.id, authCtx.userType, coursesCtx, params, navigate, notificationsCtx])

    return(
        <>
            <h1>Submit Grades</h1>
            <p>With a Foundation Course, grades are confirmed without an examiner. Are you sure you're ready to submit these grades?</p>
            <TrayToggle open={showMessage}>
                <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{returnMsg}</div>
            </TrayToggle>
            <button onClick={formSubmitHandler} className={"submit"} type="submit" disabled={sending}>{!sending ? "Post Grades" : "Sending..."}</button>
            {sending && <div className={"sending"}/>}
        </>
    )
}

export default SendGrades