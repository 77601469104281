import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import React, {useCallback, useContext, useEffect, useState} from 'react'
import Section from "../../components/ui-components/Section/Section";
import AddMembersToCourse from "../../components/functional-components/Tables/AddMembersToCourse";
import CourseSidePanel from "../../components/ui-components/CourseSidePanel/CourseSidePanel";
import Loading from "../../components/ui-components/Loading/Loading";
import {useParams, useLocation} from "react-router-dom";
import Modal from "../../components/ui-components/Modal/Modal";
import useModal from "../../hooks/useModal";
import useGet from "../../hooks/useGet";
import Button from "../../components/ui-components/Button/Button";
import MembersOnCourse from "../../components/functional-components/Tables/MembersOnCourse";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import AddGrades from "../../components/functional-components/Tables/AddGrades";
import TrayToggle from "../../components/ui-components/TrayToggle/TrayToggle";
import SendGrades from "../../components/functional-components/SendToExaminer/SendToExaminer";
import BackButton from "../../components/ui-components/BackButton/BackButton";
import notificationContext from "../../store/notification-context";
import SorryTheresAProblem from "../../components/ui-components/SorryTheresAProblem/SorryTheresAProblem";
import ArchivedMembersOnCourse from "../../components/functional-components/Tables/ArchivedMembersOnCourse";
import DeleteGrade from '../../components/functional-components/DeleteGrade/DeleteGrade'

function CourseSuperAdmin(){

    //Hooks
    const authCtx = useContext(AuthContext)
    const notificationsCtx = useContext(notificationContext)
    const {showModal, toggleModal} = useModal()
    const {showModal: showModal2, toggleModal: toggleModal2} = useModal()
    const {showModal: showModal3, toggleModal: toggleModal3} = useModal()
    const params = useParams()
    const location = useLocation()

    // Get content
    const {loading: loading1, returnData: course} = useGet('/api/courses/' + params.course_id, 250)
    const {loading: loading2, returnData: getAllMembers} = useGet('/api/users/', 250)

    //States
    const [allLoads, setAllLoads] = useState(true)
    const [allData, setAllData] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [allMembers, setAllMembers] = useState([])
    const [gradedMembers, setGradedMembers] = useState([])
    const [ungradedMembers, setUngradedMembers] = useState([])
    const [grades, setGrades] = useState([])
    const [examDatePassed, setExamDatePassed] = useState(true)
    const [deleteGrade, setDeleteGrade] = useState({memberId: 0, courseId: 0})

    const createAllMembersMinusGradedHandler = useCallback((gradedMembers) => {
        let allMembersMinusGraded = getAllMembers
        gradedMembers.map(gradedMember => {
            allMembersMinusGraded = allMembersMinusGraded.filter(member => member.id !== gradedMember.id)
            return false
        })

        setAllMembers(allMembersMinusGraded)
    }, [getAllMembers])

    // Wait for everything to load and check the data
    useEffect(() => {
        if(!loading1 && !loading2){
            setAllLoads(false)
            if(!course.error && getAllMembers){
                setExamDatePassed(course.exam_date_passed)

                // Set graded members
                const gradedMembers = course.enrolled.filter(member => member.percent !== null)
                setGradedMembers(gradedMembers)

                // Set ungraded members
                setUngradedMembers(course.enrolled.filter(member => member.percent === null))

                createAllMembersMinusGradedHandler(gradedMembers)

                // Set all members (minus those who have already received a grade
                setAllData(true)
            }
        }
    }, [course.enrolled, course.error, course.exam_date_passed, createAllMembersMinusGradedHandler, getAllMembers, loading1, loading2])

    //Mark notification as read
    useEffect(()=>{
        notificationsCtx.markAsRead(params.course_id, "course")
    }, [notificationsCtx, params.course_id])

    const removeMemberHandler = (id) => {
        const removedMembers = ungradedMembers.filter(item => item.id !== id)
        setUngradedMembers(removedMembers)

        const updatedEnrollment = removedMembers.concat(gradedMembers)
        updateEnrollmentHandler(updatedEnrollment)
    }

    const addMemberHandler = (id) => {
        let addMember = allMembers.filter(item => item.id === id)
        addMember = {
            'id' : addMember[0].id,
            'name' : addMember[0].name,
            'tcr' : addMember[0].tcr
        }
        let updatedMembers = [addMember]
        if(ungradedMembers){
            updatedMembers = [...ungradedMembers, addMember]
        }

        const updatedEnrollment = updatedMembers.concat(gradedMembers)
        updateEnrollmentHandler(updatedEnrollment)
        setUngradedMembers(updatedMembers)
    }

    const updateEnrollmentHandler = (updatedMembers) => {
        let memberIds = []
        updatedMembers.map(member => {
            memberIds.push(member.id)
            return false
        })

        if(authCtx.id){
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + authCtx.token,
                },
            };

            const data = {
                courseId: params.course_id,
                enroll: memberIds.join(', '),
            };

            axios.post('/api/enroll/', data, config).then(response => {
                if(response.status === 200){
                    if(response.data === 'error'){
                        setAllData(false)
                    }
                }
            }).catch(function (error) {
                if (error) {
                    setAllData(false)
                }
            });
        }
    }

    const showGradeHandler = (percent, id) => {
        let newMembers = []
        ungradedMembers.map(member => {

            let showGrade = member.showGrade
            if(member.id === id){
                showGrade = true
            }
            newMembers.push({
                    "id" : member.id,
                    "name" : member.name,
                    'tcr' : member.tcr,
                    'percent' : member.percent,
                    'confirmed' : member.confirmed,
                    'showGrade' : showGrade
                }
            )
            return false
        })

        setUngradedMembers(newMembers)
    }

    const addGradeHandler = (percent, id) => {

        let newMembers = []
        ungradedMembers.map(member => {

            let updatePercent = member.percent
            if(member.id === id){
                updatePercent = percent
            }
            newMembers.push({
                    "id" : member.id,
                    "name" : member.name,
                    'tcr' : member.tcr,
                    'percent' : updatePercent,
                    'confirmed' : member.confirmed,
                    'showGrade' : member.showGrade
                }
            )
            return false
        })

        setUngradedMembers(newMembers)

        const newGrade = {
            'id' : id,
            'percent' : percent
        }
        const removePreExisting = grades.filter(item => item.id !== id)
        const updatedGrades = [...removePreExisting, newGrade]
        setGrades(updatedGrades)

        if(errorMessage){
            if(updatedGrades.length === ungradedMembers.length){
                let checkGrades = false
                updatedGrades.map(grade => {
                    if(grade.percent === ''){
                        checkGrades = true
                    }
                    return false
                })
                if(!checkGrades){
                    setErrorMessage('')
                }
            }
        }
    }

    const deleteGradeHandler = (memberId, gradeId) => {

        const data = {
            memberId: memberId,
            gradeId: gradeId
        }

        setDeleteGrade(data)
        toggleModal3()
    }

    const updateEnrolledPostDeleteHandler = (memberId) => {
        const updatedGradedMembers = gradedMembers.filter(member => member.id !== memberId)
        setGradedMembers(updatedGradedMembers)
        createAllMembersMinusGradedHandler(updatedGradedMembers)

        const updatedEnrollment = updatedGradedMembers.concat(ungradedMembers)
        updateEnrollmentHandler(updatedEnrollment)
    }

    const submitGradesHandler = () => {
        let errors = 0
        ungradedMembers.map(member => {
            if (member.confirmed !== '1') {
                let match = false
                grades.map(grade => {
                    if(grade.percent !== ''){
                        if(grade.id === member.id){
                            match = true
                        }
                    }
                    return false
                })
                if(!match){
                    errors = errors + 1
                }
            }
            return false
        })
        if(errors){
            setErrorMessage("Please add a grade to every student before submitting")
        }else{
            toggleModal2()
        }
    }

    return(
        <>
            {allLoads ? <Loading /> : !allData ? <SorryTheresAProblem message={"There's been an issue loading this course. Please refresh the app."} /> :
                <>
                    <PageTitle>{course.weaponsSystem}</PageTitle>

                    <div className={'two_columns'}>

                        <div className={'left_column'}>

                            <>
                                {(course.gradesSubmitted === "0") ?

                                    !examDatePassed ?
                                        <Section title="Students">
                                            {(ungradedMembers && ungradedMembers.length) ?
                                                <>
                                                    <Button position={'top_right'} onClick={toggleModal}>Add Students +</Button>
                                                    <MembersOnCourse
                                                        data={ungradedMembers}
                                                        removeMember = {remove => removeMemberHandler(remove)}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <p>Tutor has not added any students to course.</p>
                                                    <Button position={'right'} onClick={toggleModal}>Add Students +</Button>
                                                </>
                                            }
                                        </Section>
                                        :
                                        <>
                                            <Section title="Grades">
                                                <p>You can submit grades below but it's better to allow the main teacher of the course to do it themselves.</p>
                                            </Section>

                                            <Section title="Students">
                                                <Button position={'top_right'} onClick={toggleModal}>Edit
                                                    Students</Button>
                                                <AddGrades
                                                    data={ungradedMembers}
                                                    addGrade={(percent, id) => addGradeHandler(percent, id)}
                                                    showGrade={(percent, id) => showGradeHandler(percent, id)}
                                                    courseLevel={course.level}
                                                />
                                                <TrayToggle open={errorMessage}>
                                                    <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{errorMessage}</div>
                                                </TrayToggle>
                                                {(ungradedMembers.length) ?
                                                    <button disabled={errorMessage.length > 0} className={'submit'} onClick={submitGradesHandler}>Submit Grades</button>
                                                    :
                                                    null
                                                }
                                            </Section>
                                        </>
                                    :
                                    <>
                                        <Section title="Ungraded Students">
                                            <Button position={'top_right'} onClick={toggleModal}>Add Students +</Button>
                                            {(ungradedMembers.length) ?
                                                <>
                                                    <AddGrades
                                                        data={ungradedMembers}
                                                        addGrade={(percent, id) => addGradeHandler(percent, id)}
                                                        showGrade={(percent, id) => showGradeHandler(percent, id)}
                                                        courseLevel={course.level}
                                                    />
                                                    <TrayToggle open={errorMessage}>
                                                        <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{errorMessage}</div>
                                                    </TrayToggle>
                                                    <button disabled={errorMessage.length > 0} className={'submit'} onClick={submitGradesHandler}>Submit Grades</button>
                                                </>
                                                :
                                                <p>Even though the exam date has passed, as a super-admin it's not too late to add and grade students.</p>
                                            }
                                        </Section>
                                        {
                                            (gradedMembers.length) ?
                                                <Section table={true} title="Graded Students">
                                                    <ArchivedMembersOnCourse
                                                        withDelete
                                                        deleteConfirm={(memberId, gradeId) => deleteGradeHandler(memberId, gradeId)}
                                                        data={gradedMembers}
                                                        courseLevel={course.level}
                                                    />
                                                </Section>
                                                :
                                                null
                                        }
                                    </>
                                }
                            </>

                            {(location.pathname.includes('course-archive')) ?
                                <BackButton navigate={"/super-admin/course-archive"}>Course archive</BackButton>
                                :
                                <BackButton navigate={"/super-admin/admin-management/all-courses"}>All Courses</BackButton>
                            }
                        </div>

                        {course &&
                            <CourseSidePanel
                                trainingProvider={course.trainingProvider}
                                teachers={course.teachers}
                                level={course.level}
                                examDate={course.exam_date}
                                editOption={'true'}
                            />
                        }

                    </div>

                    <Modal
                        showModal={showModal}
                        toggleModal={toggleModal}>
                        <AddMembersToCourse
                            data={allMembers}
                            enrolledMembers={ungradedMembers}
                            addHandler = {add => addMemberHandler(add)}
                            removeHandler = {remove => removeMemberHandler(remove)}
                        />
                    </Modal>

                    <Modal
                        showModal={showModal2}
                        toggleModal={toggleModal2}>
                        <SendGrades
                            grades={grades}
                            membersOnCourse={ungradedMembers}
                        />
                    </Modal>

                    <Modal
                        showModal={showModal3}
                        toggleModal={toggleModal3}
                    >
                        <DeleteGrade
                            memberId={deleteGrade.memberId}
                            courseId={params.course_id}
                            closeModal={showModal3 ? toggleModal3 : () => {}}
                            updateEnrolledPostDeleteHandler={showModal3 ? (memberId) => updateEnrolledPostDeleteHandler(memberId) : () => {}}
                        />
                    </Modal>
                </>
            }
        </>
    )
}

export default CourseSuperAdmin;