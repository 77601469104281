import {Link} from "react-router-dom";
import style from './Account.module.scss'

const Account = ({user, logout, closeAccount}) => {

    return(
        <div className={style.Account}>
            <div className={style.inner}>
                <Link onClick={closeAccount} className={style.title} to="">{user.name}</Link>
                <Link onClick={closeAccount} className={style.link} to="profile">Your Account</Link><br/>
                <Link onClick={closeAccount} className={style.link} to="profile/security">Security / Sign In</Link>
            </div>
            <button onClick={logout} className={style.sign_out}>Sign Out</button>
        </div>
    )
}

export default Account