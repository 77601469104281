import React, {useState} from "react";

const UserContext = React.createContext({
    setUser: null,
    id: null,
    name: null,
    firstName: null,
    surName: null,
    email: null,
    phone: null,
    gender: null,
    tcr: null,
    status: null,
    level: null,
    lapsed: null,
    joinedDate: null,
    paidToDate: null,
    examiner: null,
    grades: null
});

export const UserContextProvider = (props) => {

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [surName, setSurName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [tcr, setTcr] = useState('');
    const [status, setStatus] = useState('');
    const [level, setLevel] = useState('');
    const [lapsed, setLapsed] = useState('');
    const [joinedDate, setJoinedDate] = useState('');
    const [paidToDate, setPaidToDate] = useState('');
    const [examiner, setExaminer] = useState('');
    const [grades, setGrades] = useState('');

    function setUserHandler(userData){
        setId(userData['id'])
        setName(userData['name'])
        setFirstName(userData['first_name'])
        setSurName(userData['surname'])
        setEmail(userData['email'])
        setPhone(userData['phone'])
        setGender(userData['gender'])
        setTcr(userData['tcr'])
        setStatus(userData['status'])
        setLevel(userData['level'])
        setLapsed(userData['lapsed'])
        setJoinedDate(userData['joined_date'])
        setPaidToDate(userData['paid_to_date'])
        setExaminer(userData['examiner'])
        setGrades(userData['grades'])
    }

    const contextValue = {
        setUser: setUserHandler,
        id: id,
        name: name,
        firstName: firstName,
        surName: surName,
        email: email,
        phone: phone,
        gender: gender,
        tcr: tcr,
        status: status,
        level: level,
        lapsed: lapsed,
        joinedDate: joinedDate,
        paidToDate: paidToDate,
        examiner: examiner,
        grades: grades
    };

    return (
        <UserContext.Provider value={contextValue}>
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContext;