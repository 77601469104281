import {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateInput.scss"
import parse from "date-fns/parse"
import format from "date-fns/format"

function DateInput(props){

    const [date, setDate] = useState(parse(props.initialDate, 'MM-dd-yy', new Date()))
    const [error, setError] = useState(true)
    const [showError, setShowError] = useState(false)

    const validateHandler = () => {

        const checkDate = formatDateHandler(date)

        setShowError(true)
        const parseDate = parse(checkDate, "dd/MM/yyyy", new Date())

        switch(true) {
            case (String(parseDate) === "Invalid Date"):
                setError("Please enter a valid date (DD/MM/YYYY)")
                return
            default:
                setError(false);
                setShowError(false);
                return
        }
    }

    useEffect(() => {
        if(props.forceValidation){
            validateHandler();
        }
    })

    const formatDateHandler = (inputDate, forInput) => {
        let formattedDate = ""

        if(forInput){
            try{
                formattedDate = format(inputDate, "MM-dd-yy", { awareOfUnicodeTokens: true })
            }catch{
            }
        }else{
            try{
                formattedDate = format(inputDate, "dd/MM/yyyy", { awareOfUnicodeTokens: true })
            }catch{
            }
        }

        return formattedDate
    }

    const changeHandler = (date) => {
        validateHandler(date)
        const formattedDate = formatDateHandler(date, true)
        setDate(date)
        props.value(formattedDate)
    }

    return(
        <>
            <label htmlFor={props.label.toLowerCase().replace(/\s/g, "")} className={"label"}>{props.label}:</label>
            <DatePicker
                selected={date}
                onChange={(date) => changeHandler(date)}
                onBlur={() => validateHandler(date)}
                dateFormat={"dd/MM/yyyy"}
                disabled={props.sending}
            />

            {(error && showError) && <div className={"bubble"}>{error}</div>}
        </>
    )
}

export default DateInput;