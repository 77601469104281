import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import './style.scss';
import App from './App';
import { AuthContextProvider } from './store/auth-context';
import {UserContextProvider} from "./store/user-context";
import {CoursesContextProvider} from "./store/courses-context";
import reportWebVitals from './reportWebVitals';
import {NotificationsContextProvider} from "./store/notification-context";
import ScrollToTop from "./components/functional-components/ScrollToTop";

ReactDOM.render(
    <React.StrictMode>

        <UserContextProvider>
            <NotificationsContextProvider>
                <CoursesContextProvider>
                    <AuthContextProvider>
                        <BrowserRouter>
                            <ScrollToTop>
                                <App />
                            </ScrollToTop>
                        </BrowserRouter>
                    </AuthContextProvider>
                </CoursesContextProvider>
            </NotificationsContextProvider>
        </UserContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
