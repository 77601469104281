import Logo from "../components/ui-components/Logo/Logo";
import {Link, useParams} from "react-router-dom";
import useGet from "../hooks/useGet";
import Loading from "../components/ui-components/Loading/Loading";
import ExpiredResetLink from "../components/functional-components/ExpiredResetLink";
import ResetPasswordForm from "../components/functional-components/Forms/ResetPasswordForm";

function ResetPassword(){

    const params = useParams();
    const url = '/api/auth/?reset_string=' + params.ResetString
    const {loading, returnData} = useGet(url, 2000)

    return(
        loading ? <Loading /> : returnData ?
            <>
                <div className='login_container'>
                    <Link to={'/'}><Logo type={'big'} /></Link>
                    <div className={'login_box'}>
                        <ResetPasswordForm />
                    </div>
                </div>
            </> :
            <>
                <div className='login_container'>
                    <Link to={'/'}><Logo type={'big'} /></Link>
                    <div className={'login_box'}>
                        <ExpiredResetLink />
                    </div>
                </div>
            </>
    )
}

export default ResetPassword;