export const COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'TCR',
        accessor: 'tcr'
    },
    {
        Header: 'Enter Percentage',
        accessor: 'percentage'
    },
    {
        Header: 'Grade',
        accessor: 'grade'
    }
]