import '../Certificate/certificate.scss'
import parse from "date-fns/parse"
import format from "date-fns/format"
import certificatePaper from '../../../assets/images/certificate-paper.webp'
import certificatePaperLogo from '../../../assets/images/certificate-paper-logo.webp'
import certificateLogo from '../../../assets/images/certificate-logo.webp'
import certificateExpiryDate from '../../../assets/images/certificate-expiry-date.svg'
import certificateStamp from '../../../assets/images/certificate-stamp.svg'

// Wood
import certificateBorderMiddleWood from '../../../assets/images/certificate-border-middle-wood.svg'
import certificateBorderTopWood from '../../../assets/images/certificate-border-top-wood.svg'
import certificateBorderBottomWood from '../../../assets/images/certificate-border-bottom-wood.svg'
import consolidationBannerWood from '../../../assets/images/consolidation-banner-wood.svg'
import certificateCourseBorderWood from '../../../assets/images/certificate-course-border-wood.svg'

// Bronze
import certificateBorderMiddleBronze from '../../../assets/images/certificate-border-middle-bronze.svg'
import certificateBorderTopBronze from '../../../assets/images/certificate-border-top-bronze.svg'
import certificateBorderBottomBronze from '../../../assets/images/certificate-border-bottom-bronze.svg'
import consolidationBannerBronze from '../../../assets/images/consolidation-banner-bronze.svg'
import certificateCourseBorderBronze from '../../../assets/images/certificate-course-border-bronze.svg'

// Silver
import certificateBorderMiddleSilver from '../../../assets/images/certificate-border-middle-silver.svg'
import certificateBorderTopSilver from '../../../assets/images/certificate-border-top-silver.svg'
import certificateBorderBottomSilver from '../../../assets/images/certificate-border-bottom-silver.svg'
import consolidationBannerSilver from '../../../assets/images/consolidation-banner-silver.svg'
import certificateCourseBorderSilver from '../../../assets/images/certificate-course-border-silver.svg'

// Gold
import certificateBorderMiddleGold from '../../../assets/images/certificate-border-middle-gold.svg'
import certificateBorderTopGold from '../../../assets/images/certificate-border-top-gold.svg'
import certificateBorderBottomGold from '../../../assets/images/certificate-border-bottom-gold.svg'
import consolidationBannerGold from '../../../assets/images/consolidation-banner-gold.svg'
import certificateCourseBorderGold from '../../../assets/images/certificate-course-border-gold.svg'
import {useEffect, useState} from 'react'
import {default as parseHtml} from 'html-react-parser'

const RecordOfTraining = ({name, level, margins, rotData}) => {

    // State
    const [rememberLevel, setRememberLevel] = useState()

    useEffect(() => {
        if (level.length) {
            setRememberLevel(level)
        }
    }, [level])

    // Set colour
    let certificateBorderMiddle = certificateBorderMiddleWood
    let certificateBorderTop = certificateBorderTopWood
    let certificateBorderBottom = certificateBorderBottomWood
    let consolidationBanner = consolidationBannerWood
    let certificateCourseBorder = certificateCourseBorderWood

    if (rememberLevel === 'Standard') {
        certificateBorderMiddle = certificateBorderMiddleBronze
        certificateBorderTop = certificateBorderTopBronze
        certificateBorderBottom = certificateBorderBottomBronze
        consolidationBanner = consolidationBannerBronze
        certificateCourseBorder = certificateCourseBorderBronze
    }

    if (rememberLevel === 'Intermediate') {
        certificateBorderMiddle = certificateBorderMiddleSilver
        certificateBorderTop = certificateBorderTopSilver
        certificateBorderBottom = certificateBorderBottomSilver
        consolidationBanner = consolidationBannerSilver
        certificateCourseBorder = certificateCourseBorderSilver
    }

    if (rememberLevel === 'Advanced') {
        certificateBorderMiddle = certificateBorderMiddleGold
        certificateBorderTop = certificateBorderTopGold
        certificateBorderBottom = certificateBorderBottomGold
        consolidationBanner = consolidationBannerGold
        certificateCourseBorder = certificateCourseBorderGold
    }

    let formattedExpiryDate = null
    if(rotData)
    {
        const removeTime2 = rotData.expiryDate.slice(0, -9)
        const parseDate2 = parse(removeTime2, "yyyy-MM-dd", new Date())
        formattedExpiryDate = format(parseDate2, "MMMM do yyyy", { awareOfUnicodeTokens: true })
    }

    return(
        <div className={['certificate', margins ? 'certificate--with-margins' : null].join(" ")}>

            <div className='certificate__paper' style={{backgroundImage: "url(" + certificatePaper + ")"}}/>
            <img src={certificatePaperLogo} className='certificate__paper-logo certificate__paper-logo--lower'
                 alt=''/>
            <img src={certificateLogo} className='certificate__logo' alt=''/>
            <div className='certificate__border'>
                <div style={{backgroundImage: `url(${certificateBorderTop})`}} className='certificate__border__top'></div>
                <div style={{backgroundImage: `url(${certificateBorderMiddle})`}} className='certificate__border__middle'></div>
                <div style={{backgroundImage: `url(${certificateBorderBottom})`}} className='certificate__border__bottom'></div>
            </div>

            <img className='certificate__stamp' src={certificateStamp} alt=''/>

            <div className='certificate__inner'>
                <div className='certificate__inner__badc'>British Academy of Dramatic Combat</div>
                <div className='certificate__inner__name'>{name}</div>
                <div className='certificate__inner__has-achieved'>Has Achieved Consolidation Level:</div>
                <img src={consolidationBanner} className='certificate__inner__consolidation-banner' alt=''/>

                <div className='certificate__inner__record-of-training'>Record of Training</div>

                {rotData.record[rememberLevel] ? <div>
                    {rotData.record[rememberLevel].map(course => {
                        return (
                            <div key={course.id} className='certificate__inner__course' style={{backgroundImage: "url(" + certificateCourseBorder + ")"}}>
                                {parseHtml(course.weaponsSystem.replace('(COVID-19)', '<span class="certificate__inner__course__covid-text">(COVID-19)</span>'))}
                            </div>
                        )
                    })}
                </div> : null}

                <div style={{backgroundImage: `url(${certificateExpiryDate})`}} className='certificate__inner__expiry-date'>
                    Valid until {formattedExpiryDate}
                </div>
            </div>
        </div>
    )
}

export default RecordOfTraining