import Logo from "../components/ui-components/Logo/Logo";
import {Link, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import authContext from "../store/auth-context";
import ContactPreferencesPanel
from "../components/functional-components/ContactPreferencesPanel/ContactPreferencesPanel";
import useGet from "../hooks/useGet";
import Loading from "../components/ui-components/Loading/Loading";

function InitialContactPreferences(){

    const {loading, returnData} = useGet('/api/contact-preferences/', 1000)

    const authCtx = useContext(authContext)
    const navigate = useNavigate()

    useEffect(() =>{
        if(!authCtx.id){
            navigate('/login')
        }
    })

    function redirectHandler(){
        navigate('/' + authCtx.userType)
    }

    return(
        loading ? <Loading /> : returnData ?
        <>
            <div className='login_container'>
                <Link to={'/'}><Logo type={'big'} /></Link>
                <div className={'login_box'}>
                    <h1>Please set your contact preferences</h1>
                    <div className={'form'}>
                        <ContactPreferencesPanel
                            data={returnData}
                        />
                        <button onClick={()=>redirectHandler()} style={{marginTop: "3rem", marginBottom: "2rem"}} className={"submit"}>Continue</button>
                    </div>
                </div>
            </div>
        </> : null
    )
}

export default InitialContactPreferences;