import React from 'react';
import PageTitle from "../../../components/ui-components/PageTitle/PageTitle";
import Button from "../../../components/ui-components/Button/Button";
import Modal from "../../../components/ui-components/Modal/Modal";
import useModal from "../../../hooks/useModal";
import CloseYourAccount from "../../../components/functional-components/CloseYourAccount/CloseYourAccount";

function CloseAccount() {

    const {showModal, toggleModal} = useModal()

    return (
        <>
            <PageTitle>Close Account</PageTitle>
            <div className='page'>
                <p>Closing your account will remove all of your data from our system. Before continuing you should print off your certificates and make sure you’re happy for all of your data to be deleted. This operation cannot be undone, so proceed with caution. </p>
                <Button onClick={toggleModal}>Close Account</Button>
            </div>
            <Modal
                showModal={showModal}
                toggleModal={toggleModal}
            >
                <CloseYourAccount
                    closeModal={() => toggleModal()}
                />
            </Modal>
        </>
    );
}

export default CloseAccount;