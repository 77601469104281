import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import Section from "../../components/ui-components/Section/Section";
import CourseSidePanel from "../../components/ui-components/CourseSidePanel/CourseSidePanel";
import Loading from "../../components/ui-components/Loading/Loading";
import {useNavigate, useParams} from "react-router-dom";
import useGet from "../../hooks/useGet";
import BackButton from "../../components/ui-components/BackButton/BackButton";
import ConfirmGrades from "../../components/functional-components/Tables/ConfirmGrades";
import TrayToggle from "../../components/ui-components/TrayToggle/TrayToggle";
import usePost from "../../hooks/usePost";
import React, {useContext, useEffect, useState} from "react";
import useModal from "../../hooks/useModal";
import Modal from "../../components/ui-components/Modal/Modal";
import notificationContext from "../../store/notification-context";
import AuthContext from "../../store/auth-context";
import AmendMemberGrade from "../../components/functional-components/AmendMemberGrade/AmendMemberGrade";
import SorryTheresAProblem from "../../components/ui-components/SorryTheresAProblem/SorryTheresAProblem";
import ArchivedMembersOnCourse from '../../components/functional-components/Tables/ArchivedMembersOnCourse'

function GradesConfirm(){

    //Hooks
    const params = useParams()
    const {showModal, toggleModal} = useModal()
    const notificationsCtx = useContext(notificationContext)
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    const {postData, returnPostData, sending, returnMsg, showMessage} = usePost()
    const {loading, returnData} = useGet('/api/examiner-course/' + params.course_id, 250)

    //States
    const [errorMessage, setErrorMessage] = useState('')
    const [amendMember, setAmendMember] = useState()
    const [gradedMembers, setGradedMembers] = useState([])
    const [ungradedMembers, setUngradedMembers] = useState([])


    //Wait for everything to load and check the data
    useEffect(()=>{
        if(!loading){
            if(returnData){

                // Set ungraded members
                setUngradedMembers(returnData.enrolled.filter(member => member.confirmed !== '1'))

                // Set graded member
                setGradedMembers(returnData.enrolled.filter(member => member.confirmed === '1'))
            }
        }
    }, [loading, returnData])

    //Mark notification as read
    useEffect(()=>{
        notificationsCtx.markAsRead(params.course_id, "examiner")
    }, [notificationsCtx, params.course_id])

    //Data posted
    useEffect(()=>{
        if(returnPostData && !returnPostData.error){

            //Update notifications
            const updatedNotifications = notificationsCtx.notifications.filter(item => !((item.type === 'examiner') && (item.id === params.course_id)))
            notificationsCtx.setNotifications(updatedNotifications)

            //Update temp notification
            const data = {
                title: "Grades Confirmed",
                message: "Thank you. Grades for " + returnData.weaponsSystem + " have been confirmed. The students will now be issued with their certificates."
            }
            notificationsCtx.setTempNotification(data)

            //Redirect
            navigate('/' + authCtx.userType);
        }else{
            setErrorMessage(returnMsg)
        }

    }, [setErrorMessage, authCtx.userType, navigate, notificationsCtx, params.course_id, returnMsg, returnPostData, showMessage, returnData.weaponsSystem])

    //Confirm Grades
    const confirmGradesHandler = () => {
        const data = {
            weaponsSystem: returnData.weaponsSystem,
            grades: ungradedMembers
        };
        postData(data, '/api/courses/' + params.course_id + '/confirm-grades', 600)
    }

    //Amend Grade
    const amendGradeHandler = (memberId) => {
        const member = ungradedMembers.filter(member => (member.id === memberId))[0]
        setAmendMember(member)
        toggleModal()
    }

    //Update Grade Handler
    const updateGradeHandler = (percent) => {
        let newGrades = []
        ungradedMembers.map(member => {

            let updatePercent = member.percent
            if(member.id === amendMember.id){
                updatePercent = percent
            }
            newGrades.push({
                "id" : member.id,
                "name" : member.name,
                'tcr' : member.tcr,
                'percent' : updatePercent}
            )
            return false
        })
        setUngradedMembers(newGrades)

        let errors = 0
        newGrades.map(grade => {
            if(grade.percent === ''){
                errors = errors + 1
            }
            return false
        })

        if(errors){
            setErrorMessage("Please add a grade to every student before submitting")
        }else{
            setErrorMessage(false)
        }

        toggleModal()
    }

    return(
        <>
            {loading ? <Loading /> : !returnData ? <SorryTheresAProblem message={"There's been an issue loading the course. Please refresh the app."} /> :
                <>
                    <PageTitle>{returnData.weaponsSystem}</PageTitle>

                    <div className='two_columns'>
                        <div className={'left_column'}>

                            <Section title="Students">
                                <ConfirmGrades
                                    data={ungradedMembers}
                                    courseLevel={returnData.level}
                                    amendGrade={(memberId) => amendGradeHandler(memberId)}
                                />
                                <TrayToggle open={errorMessage}>
                                    <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{errorMessage}</div>
                                </TrayToggle>
                                <button disabled={errorMessage} className={'submit'} onClick={confirmGradesHandler}>Confirm All Grades</button>
                            </Section>

                            {
                                (gradedMembers.length) ?
                                    <Section table={true} title="Graded Students">
                                        <ArchivedMembersOnCourse
                                            data={gradedMembers}
                                            courseLevel={returnData.level}
                                        />
                                    </Section>
                                    :
                                    null
                            }


                            <BackButton>Back</BackButton>
                        </div>

                        <CourseSidePanel
                            trainingProvider={returnData.trainingProvider}
                            teachers={returnData.teachers}
                            level={returnData.level}
                            examiner={returnData.examiner}
                            examDate={returnData.exam_date}
                            editOption={false}
                        />
                    </div>
                </>
            }

            <Modal
                showModal={showModal}
                toggleModal={toggleModal}
            >
                <AmendMemberGrade
                    member={amendMember}
                    updatedGrades={(percent) => updateGradeHandler(percent)}
                />
            </Modal>

            {sending && <div className={"sending"}/>}

        </>
    )
}

export default GradesConfirm;