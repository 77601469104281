import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import React, {useContext, useEffect, useState} from "react";
import Section from "../../components/ui-components/Section/Section";
import AddMembersToCourse from "../../components/functional-components/Tables/AddMembersToCourse";
import CourseSidePanel from "../../components/ui-components/CourseSidePanel/CourseSidePanel";
import Loading from "../../components/ui-components/Loading/Loading";
import {useNavigate, useParams} from "react-router-dom";
import Modal from "../../components/ui-components/Modal/Modal";
import useModal from "../../hooks/useModal";
import useGet from "../../hooks/useGet";
import Button from "../../components/ui-components/Button/Button";
import MembersOnCourse from "../../components/functional-components/Tables/MembersOnCourse";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import AddGrades from "../../components/functional-components/Tables/AddGrades";
import TrayToggle from "../../components/ui-components/TrayToggle/TrayToggle";
import SendToExaminer from '../../components/functional-components/SendToExaminer/SendToExaminer'
import SendGrades from "../../components/functional-components/SendGrades/SendGrades";
import BackButton from "../../components/ui-components/BackButton/BackButton";
import notificationContext from "../../store/notification-context";
import coursesContext from "../../store/courses-context";
import SorryTheresAProblem from "../../components/ui-components/SorryTheresAProblem/SorryTheresAProblem";

function CourseAdmin(){

    const authCtx = useContext(AuthContext)
    const notificationsCtx = useContext(notificationContext)
    const navigate = useNavigate();
    const coursesCtx = useContext(coursesContext)

    //Hooks
    const {showModal, toggleModal} = useModal()
    const {showModal: showModal2, toggleModal: toggleModal2} = useModal()
    const {showModal: showModal3, toggleModal: toggleModal3} = useModal()
    const params = useParams()
    const {loading: loading1, returnData: course} = useGet('/api/courses/' + params.course_id, 250)
    const {loading: loading2, returnData: allMembers} = useGet('/api/users/', 250)

    //States
    const [allLoads, setAllLoads] = useState(true)
    const [allData, setAllData] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [membersOnCourse, setMembersOnCourse] = useState(false)
    const [grades, setGrades] = useState([])
    const [examDatePassed, setExamDatePassed] = useState(true)

    //Wait for everything to load and check the data
    useEffect(()=>{
        setAllLoads(true)
        setAllData(false)
        if(!loading1 && !loading2){
            setAllLoads(false)
            if (!course.error && allMembers) {
                //Check if grades have been submitted already
                if(course.gradesSubmitted === "1"){
                    navigate('/' + authCtx.userType + "/course-archive/" + params.course_id)
                }
                setExamDatePassed(course.exam_date_passed)
                setMembersOnCourse(course.enrolled)
                setAllData(true)
            } else {

                if (!allMembers) {
                    alert("There are currently no members in BADC. Please add some before continuing.")
                }

                //Remove course and redirect if there's an issue
                const updatedCourses = coursesCtx.courses.filter(item => item.id !== params.course_id)
                coursesCtx.setCourses(updatedCourses)
                const updatedNotification = notificationsCtx.notifications.filter((item => item.id !== params.course_id && 'type' !== 'course'))
                notificationsCtx.setNotifications(updatedNotification)
                navigate('/' + authCtx.userType)
            }
        }
    }, [loading1, loading2, allMembers, course, authCtx.userType, navigate, notificationsCtx, coursesCtx, params.course_id])

    //Mark notification as read
    useEffect(()=>{
        notificationsCtx.markAsRead(params.course_id, "course")
    }, [notificationsCtx, params.course_id])

    const removeMemberHandler = (id) => {
        const removedMembers = membersOnCourse.filter(item => item.id !== id)
        setMembersOnCourse(removedMembers)
        updateEnrollmentHandler(removedMembers)
    }

    const addMemberHandler = (id) => {
        let addMember = allMembers.filter(item => item.id === id)
        addMember = {
            'id' : addMember[0].id,
            'name' : addMember[0].name,
            'tcr' : addMember[0].tcr
        }
        let updatedMembers = [addMember]
        if(membersOnCourse){
            updatedMembers = [...membersOnCourse, addMember]
        }
        updateEnrollmentHandler(updatedMembers)
        setMembersOnCourse(updatedMembers)
    }

    const updateEnrollmentHandler = (updatedMembers) => {
        let memberIds = []
        updatedMembers.map(member => {
            memberIds.push(member.id)
            return false
        })

        if(authCtx.id){
            const config = {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + authCtx.token,
                },
            };

            const data = {
                courseId: params.course_id,
                enroll: memberIds.join(', '),
            };

            axios.post('/api/enroll/', data, config).then(response => {
                if(response.status === 200){
                    if(response.data === 'error'){
                        setAllData(false)
                    }
                }
            }).catch(function (error) {
                if (error) {
                    setAllData(false)
                }
            });
        }
    }

    const showGradeHandler = (percent, id) => {
        let newMembers = []
        membersOnCourse.map(member => {

            let showGrade = member.showGrade
            if(member.id === id){
                showGrade = true
            }
            newMembers.push({
                    "id" : member.id,
                    "name" : member.name,
                    'tcr' : member.tcr,
                    'percent' : member.percent,
                    'showGrade' : showGrade
                }
            )
            return false
        })

        setMembersOnCourse(newMembers)
    }

    const addGradeHandler = (percent, id) => {

        let newMembers = []
        membersOnCourse.map(member => {

            let updatePercent = member.percent
            if(member.id === id){
                updatePercent = percent
            }
            newMembers.push({
                    "id" : member.id,
                    "name" : member.name,
                    'tcr' : member.tcr,
                    'percent' : updatePercent,
                    'showGrade' : member.showGrade
                }
            )
            return false
        })

        setMembersOnCourse(newMembers)

        const newGrade = {
            'id' : id,
            'percent' : percent
        }
        const removePreExisting = grades.filter(item => item.id !== id)
        const updatedGrades = [...removePreExisting, newGrade]
        setGrades(updatedGrades)

        if(errorMessage){
            if(updatedGrades.length === membersOnCourse.length){
                let checkGrades = false
                updatedGrades.map(grade => {
                    if(grade.percent === ''){
                        checkGrades = true
                    }
                    return false
                })
                if(!checkGrades){
                    setErrorMessage('')
                }
            }
        }
    }

    const submitGradesHandler = () => {
        let errors = 0
        membersOnCourse.map(member => {
            let match = false
            grades.map(grade => {
                if(grade.percent !== ''){
                    if(grade.id === member.id){
                        match = true
                    }
                }
                return false
            })
            if(!match){
                errors = errors + 1
            }
            return false
        })
        if(errors){
            setErrorMessage("Please add a grade to every student before submitting")
        }else{
            if (course.level === "Foundation"){
                toggleModal3()
            } else {
                toggleModal2()
            }
        }
    }

    return(
        <>
            {allLoads ? <Loading /> : !allData ? <SorryTheresAProblem message={"There's been an issue loading this course. Please refresh the app."} /> :
                <>
                    <PageTitle>{course.weaponsSystem}</PageTitle>

                    <div className={'two_columns'}>

                        <div className={'left_column'}>

                            {(examDatePassed && membersOnCourse.length) ?
                                <>
                                    <Section title="Grades">
                                        <p>Now the exam date has passed, you can add your student's grades below.</p>
                                    </Section>

                                    <Section title="Students">
                                        <Button position={'top_right'} onClick={toggleModal}>Edit Students</Button>
                                        <AddGrades
                                            data={membersOnCourse}
                                            addGrade = {(percent, id) => addGradeHandler(percent, id)}
                                            showGrade = {(percent, id) => showGradeHandler(percent, id)}
                                            courseLevel = {course.level}
                                        />
                                        <TrayToggle open={errorMessage}>
                                            <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{errorMessage}</div>
                                        </TrayToggle>
                                        <button disabled={errorMessage.length > 0} className={'submit'} onClick={submitGradesHandler}>Submit Grades</button>

                                    </Section>

                                </>
                                :
                                <>
                                    <Section title="Students">
                                        {(membersOnCourse && membersOnCourse.length) ?
                                            <>
                                                <Button position={'top_right'} onClick={toggleModal}>Add Students +</Button>
                                                <MembersOnCourse
                                                    data={membersOnCourse}
                                                    removeMember = {remove => removeMemberHandler(remove)}
                                                />
                                            </>
                                            :
                                            <>
                                                <p>Now you've created your course, please add some students.</p>
                                                <Button position={'right'} onClick={toggleModal}>Add Students +</Button>
                                            </>
                                        }
                                    </Section>

                                    <Section title="Grades">
                                        <p>Once you've added some students and the exam date has passed you can submit their grades here.</p>
                                    </Section>
                                </>
                            }
                            <BackButton>Home</BackButton>
                        </div>

                        {course &&
                            <CourseSidePanel
                                trainingProvider={course.trainingProvider}
                                teachers={course.teachers}
                                level={course.level}
                                examDate={course.exam_date}
                                editOption={'true'}
                            />
                        }

                    </div>

                    <Modal
                        showModal={showModal}
                        toggleModal={toggleModal}>
                        <AddMembersToCourse
                            data={allMembers}
                            level={course.level}
                            enrolledMembers={membersOnCourse}
                            addHandler = {add => addMemberHandler(add)}
                            removeHandler = {remove => removeMemberHandler(remove)}
                        />
                    </Modal>

                    <Modal
                        showModal={showModal2}
                        toggleModal={toggleModal2}>
                        <SendToExaminer
                            grades={grades}
                            membersOnCourse={membersOnCourse}
                        />
                    </Modal>

                    <Modal
                        showModal={showModal3}
                        toggleModal={toggleModal3}>
                        <SendGrades
                            grades={grades}
                            membersOnCourse={membersOnCourse}
                        />
                    </Modal>
                </>
            }
        </>
    )
}

export default CourseAdmin;