import style from './MailingListEmails.module.scss'
import {useRef} from "react";
import Loading from "../../ui-components/Loading/Loading";

const MailingListEmails = ({data, loading, selected}) => {

    const textArea = useRef(null);

    const selectAllText = () => {
        textArea.current.select();
    };

    return(
        <div className={style.mailing_list_container}>
                <>
                    <textarea readOnly ref={textArea} onClick={selectAllText} value={data ? data.join(", ") : ""} />

                    {loading ? <Loading/> :
                        !selected ? <div className={style.noMailingList}>No list selected</div> : null
                    }
                </>
            }
        </div>
    )
}

export default MailingListEmails