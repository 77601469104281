import InviteForm from "../../components/functional-components/Forms/InviteForm";
import PageTitle from "../../components/ui-components/PageTitle/PageTitle";

function InviteAdmin(){

    return(
        <>
            <PageTitle>Invite Admin</PageTitle>
            <InviteForm userType={'admin'} />
        </>
    )
}

export default InviteAdmin;