import {useState, useContext} from "react";
import axios from "axios";
import AuthContext from "../store/auth-context";

const usePost = () => {

    //Get Auth Context
    const authCtx = useContext(AuthContext);

    //States
    const [returnPostData, setReturnPostData] = useState('');
    const [sending, setSending] = useState(false)
    const [returnMsg, setReturnMsg] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    function postData(postedData, url, delay){

        setSending(true)
        setShowMessage(false)
        setShowSuccessMessage(false)

        setTimeout(function(){

            let config = {}

            if(authCtx.id){
                config = {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": "Bearer " + authCtx.token,
                    },
                };
            }

            axios.post(url, postedData, config).then(response => {
                if(response.status === 200){
                    if(response.data.error === false){
                        setSending(false)
                        setShowSuccessMessage(true)
                        setReturnPostData(response.data)
                        setReturnMsg(response.data.message)
                    }else{
                        setSending(false)
                        setShowMessage(true)
                        setReturnMsg(response.data.message)
                    }
                }else{
                    setSending(false)
                    setShowMessage(true)
                    setReturnMsg("There's been a problem on the server. Please make a note of what you were doing and contact the administrator.")
                }
            }).catch(function (error) {
                if (error) {
                    setSending(false)
                    setShowMessage(true)
                    setReturnMsg("Could not connect to the server. Please check your internet connection and refresh the page.")
                }
            });

        }, delay)
    }

    return {
        postData,
        returnPostData,
        sending,
        returnMsg,
        showMessage,
        showSuccessMessage
    }
};

export default usePost;