import style from './TempMessage.module.scss'
import Button from "../Button/Button";

const TempMessage = ({title, message, onClick}) => {

    return(
        <div className={style.TempMessage}>
            <h2 className={style.title}>{title}</h2>
            <div className={style.messageContainer}>
                {message}
                <Button
                    onClick={onClick}
                    colour={"green"}
                >
                    Okay
                </Button>
            </div>
        </div>
    )
}

export default TempMessage