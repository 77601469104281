import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import style from './TrayToggle.module.scss'

function TrayToggle(props){

    //Set speed
    let speed = style.traySpeedMedium
    if(props.speed === "fast"){
        speed = style.traySpeedFast
    }

    return(
        <SlideDown className={speed} style={{width: "100%"}}>
            {
                props.open &&
                <>
                    <div className={style.tray_container}>
                        {props.children}
                    </div>
                </>
            }
        </SlideDown>
    )
}

export default TrayToggle;