import {useState} from "react";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import EmailInput from "../Inputs/EmailInput";
import usePost from "../../../hooks/usePost";

function ForgottenPasswordForm(props){

    const [email, setEmail] = useState(false);
    const [forceValidation, setForceValidation] = useState(false);
    const {sending, showMessage, returnMsg, showSuccessMessage, postData} = usePost()

    function formSubmitHandler(e){
        e.preventDefault()
        setForceValidation(true)
        if(email) {
            const data = {
                email: email,
                userType: props.userType
            };
            postData(data, '/api/auth/request-reset-password', 600)
        }
    }

    function emailHandler(status){
        setEmail(status);
    }

    return(
        <>
            <form onSubmit={formSubmitHandler} className={"form"} action="">

                <div className={"field"}>
                    <EmailInput
                        value={(status) => emailHandler(status)}
                        sending={sending}
                        forceValidation={forceValidation}
                        label={"Email address"}
                    />
                </div>

                <TrayToggle open={showMessage}>
                    <div className={["ReturnMsg", 'ReturnMsgError'].join(" ")}>{returnMsg}</div>
                </TrayToggle>
                <TrayToggle open={showSuccessMessage}>
                    <div className={'ReturnMsg'}>{returnMsg}</div>
                </TrayToggle>
                <button className={"submit"} type="submit" disabled={sending}>{!sending ? "Reset Password" : "Sending..."}</button>

                {sending && <div className={"sending"} />}
            </form>
        </>
    )
}

export default ForgottenPasswordForm;