import ForgottenPasswordForm from "../components/functional-components/Forms/ForgottenPasswordForm";
import {useParams} from "react-router-dom";
import Logo from "../components/ui-components/Logo/Logo";
import {Link} from "react-router-dom";

function ForgottenPassword(){

    const params = useParams();

    return(
        <div className='login_container'>
            <Link to={'/'}><Logo type={'big'} /></Link>
            <div className={'login_box'}>
                <h2>Forgotten your Password?</h2>
                <p>Type in your email address below and we'll send you an email with instructions on how to create a new password</p>
                <ForgottenPasswordForm userType={params.userType} />
            </div>
        </div>
    )
}
export default ForgottenPassword;