import style from './FilterButton.module.scss';

function FilterButton({selected, onClick, children}){
    return(
        <button
            onClick={onClick}
            className={[style.FilterButton, selected && style.selected].join(" ")}
        >
            <span className={style.text}>{children}</span>
            {children}
        </button>
    )
}

export default FilterButton;