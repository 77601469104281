import style from './CourseAdminIconsWrapper.module.scss'

function CourseAdminIconsWrapper(props){

    return(
        <div className={style.courseAdminWrapper}>
            {props.children}
        </div>
    )
}

export default CourseAdminIconsWrapper