import PageTitle from "../../components/ui-components/PageTitle/PageTitle";
import FilterButton from "../../components/ui-components/FilterButton/FilterButton";
import {useState} from "react";
import MailingListEmails from "../../components/functional-components/MailingListEmails/MailingListEmails";
import useGet from "../../hooks/useGet";
import TrayToggle from "../../components/ui-components/TrayToggle/TrayToggle";
import Message from "../../components/ui-components/Message/Message";

function MailingLists(){

    const [badcNews, setBadcNews] = useState(false)
    const [upcomingCourses, setUpComingCourses] = useState(false)
    const [address, setAddress] = useState(false)
    const {returnData, loading, showMessage, returnMsg} = useGet(address, 1000)

    function badcNewsHandler(){
        setAddress('/api/mailing-list/general_news')
        setBadcNews(true)
        setUpComingCourses(false)
    }

    function upcomingCoursesHandler(){
        setAddress('/api/mailing-list/upcoming_courses')
        setBadcNews(false)
        setUpComingCourses(true)
    }

    return(
        <>
            <PageTitle>Mailing Lists</PageTitle>
            <div className={'page'}>
                <br/>
                <h2>Which subscribers would you like to see?</h2>
                <div style={{display: "flex", marginTop: "2.5rem", marginBottom: "2.5rem", flexWrap: "wrap"}}>
                    <FilterButton onClick={badcNewsHandler} selected={badcNews}>BADC News Subscribers</FilterButton>
                    <FilterButton onClick={upcomingCoursesHandler} selected={upcomingCourses}>Upcoming Courses Subscribers</FilterButton>
                </div>
                <TrayToggle open={showMessage}>
                    <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{returnMsg}</div>
                </TrayToggle>
                <TrayToggle
                    open={address}>
                    <Message colour={'orange'}>
                        This mailing list is always updating. New people join the app, some leave, they change their contact preferences. So it's always best to copy the addresses below just before you send out an email and never store a version offline.
                    </Message>
                </TrayToggle>
                <MailingListEmails
                    loading={loading}
                    data={returnData}
                    selected={address}
                />
            </div>
        </>
    )
}

export default MailingLists;