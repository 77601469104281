import React, {useContext} from 'react';
import { Route, Navigate } from 'react-router-dom';
import AuthContext from "../../../store/auth-context";

const MembersOnly = (props) => {

    const authCtx = useContext(AuthContext);
    let redirect = '/login';
    if(localStorage.getItem('userType') === 'admin' || localStorage.getItem('userType') === 'super-admin'){
        redirect = '/admin-login';
    }

    if(authCtx.userType !== 'member') {
        return <Navigate to={redirect} />;
    }else{
        return <Route path={props.path} element={props.component} />
    }
};

export default MembersOnly;