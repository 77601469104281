import React, {useMemo} from 'react';
import {useTable, usePagination, useSortBy, useGlobalFilter} from "react-table";
import {archivedMembersColumns} from "./columns/archivedMembersOnCourseColumns";
import {archivedMembersColumnsWithDelete} from "./columns/archivedMembersOnCourseColumnsWithDelete";
import './table.scss';
import Grade from "../Grade/Grade";
import RemoveButton from '../../ui-components/RemoveButton/RemoveButton'

function ArchivedMembersOnCourse({data, courseLevel, withDelete, deleteConfirm}) {

    let columns = useMemo(() => withDelete ? archivedMembersColumnsWithDelete : archivedMembersColumns, [withDelete]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
    } = useTable({
        columns: columns,
        data: data,
        initialState: { pageSize: 9999 }
    }, useGlobalFilter, useSortBy, usePagination);

    function cellContentHandler(columnTitle, content, percentage, memberId, gradeId){

        // Percentage
        if(columnTitle === "Percentage"){
            return(
                <>
                    {content}%
                </>
            )
        }

        // Delete
        if(columnTitle === "Delete"){
            return <RemoveButton onClick={() => deleteConfirm(memberId, gradeId)}>Delete</RemoveButton>
        }

        // Grade
        if(columnTitle === "Grade"){
            return(
                <Grade
                    percent={percentage}
                    courseLevel={courseLevel}
                />
            )
        }

        return content
    }

    return (
        <div className={'table_container'}>
            <div className={'table_container_padding'}>

                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup, i) =>(
                        <tr {...headerGroup.getHeaderGroupProps} key={i}>
                            {headerGroup.headers.map((column, i) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                                    {column.render('Header')}
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td{...cell.getCellProps()} key={cell.getCellProps().key}>
                                        {cellContentHandler(cell.column.Header, cell.render('Cell'), row.original.percent, row.original.id, row.original.gradeId)}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ArchivedMembersOnCourse;