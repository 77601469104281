import LoginForm from "../components/functional-components/Forms/LoginForm";
import Logo from "../components/ui-components/Logo/Logo";
import {Link} from "react-router-dom";

function AdminLogin(){
    return(
        <div className='login_container'>
            <Logo type={'big'} />
            <div className={'login_box'}>
                <div className={'login_links'}>
                    <Link className={'login_link'} to={'/login'}>Members</Link>
                    <div className={'login_divide'} />
                    <div className={'login_link login_link_selected'}>Admin</div>
                </div>
                <LoginForm userType={'admin'} />
            </div>
        </div>
    )
}
export default AdminLogin;