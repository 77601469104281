import {useEffect, useRef, useState} from "react"
import style from './SelectInput.module.scss'
import chevronDown from '../../../assets/images/chevron_down.svg'

function SelectInput({value, label, options, selected, sending, required=false, an=false, forceValidation}){

    const select = useRef()

    const [selectDisplayText, setSelectDisplayText] = useState(options[0][1])
    const [fontWeight, setFontWeight] = useState(required ? 'select_display_text_bold' : "")
    const [error, setError] = useState(true)
    const [showError, setShowError] = useState(false)

    const validateHandler = () => {
        setShowError(true)
        setSelectDisplayText(select.current[select.current.selectedIndex].label)
        fontWeightHandler()
        value(false)
        switch(true) {
            case (required && (select.current.value === options[0][0])):
                let terminology = "a"
                if(an){
                    terminology = "an"
                }
                setError("Please select " + terminology + " " + label.toLowerCase())
                return
            default:
                setError(false)
                setShowError(false)
                value(select.current.value)
                return
        }
    }

    //Try and match the selected option and set the display text
    useEffect(() => {
        let initialDisplayText = options[0][1]
        try {
            initialDisplayText = options.filter(item => item[0] === selected)[0][1]
        } catch (err) {
        }
        setSelectDisplayText(initialDisplayText)
    }, [options, selected])

    const fontWeightHandler = () => {
        if(required && (String(select.current.value) === String(options[0][0]))) {
            setFontWeight("select_display_text_bold")
        }else{
            setFontWeight("")
        }
    }

    useEffect(() => {
        if (forceValidation){
            validateHandler()
        }
    })

    return(
        <>
            <label htmlFor={label.toLowerCase().replace(/\s/g, "")} className={"label"}>{label}:</label>
            <div className={[style.select_wrapper, sending && style.select_wrapper_disabled].join(" ")}>
                <select
                    ref={select}
                    name={label.toLowerCase().replace(/\s/g, "")}
                    onChange={validateHandler}
                    onBlur={validateHandler}
                    defaultValue={selected}
                >
                    {options.map(
                        option => {
                            return <option key={label.toLowerCase().replace(' ', '') + option[0]} value={option[0]}>{option[1]}</option>
                        }
                    )}
                </select>
                <div className={[style.select_display_text, fontWeight].join(" ")}>
                    {selectDisplayText}
                    <span className={style.select_chevron}>
                        <img src={chevronDown} alt="" />
                    </span>
                </div>
            </div>
            {(error && showError) && <div className={"bubble"}>{error}</div>}
        </>
    )
}

export default SelectInput