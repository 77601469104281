import AdminTable from "../../components/functional-components/Tables/AdminTable"
import {useEffect, useState} from "react"
import Loading from "../../components/ui-components/Loading/Loading"
import PageTitle from "../../components/ui-components/PageTitle/PageTitle"
import Modal from "../../components/ui-components/Modal/Modal"
import useModal from "../../hooks/useModal"
import DeleteAdmin from "../../components/functional-components/DeleteAdmin/DeleteAdmin"
import useGet from "../../hooks/useGet";
import SorryTheresAProblem from "../../components/ui-components/SorryTheresAProblem/SorryTheresAProblem";

function AdminManagement(){

    const {showModal, toggleModal} = useModal()
    const [admins, setAdmins] = useState(false)
    const [user, setUser] = useState(false)
    const {loading, returnData} = useGet('/api/admins/', 600)

    useEffect(() => {
        setAdmins(returnData)
    }, [returnData, setAdmins])

    function deleteConfirmHandler(id){
        const user = admins.filter(user => (user.id === id))[0]
        setUser(user)
        toggleModal()
    }

    function updateAdmins(id){
        const updatedAdmins = admins.filter(user => (user.id !== id))
        setAdmins(updatedAdmins)
    }

    return(
        <>
            {loading ? <Loading/> : !admins ? <SorryTheresAProblem message={"No admins found."} /> :
                <>
                    <PageTitle>Manage Admins</PageTitle>
                    <AdminTable
                        data={admins}
                        deleteConfirm = {(id) => deleteConfirmHandler(id)}
                    />
                </>
            }

            <Modal
                showModal={showModal}
                toggleModal={toggleModal}
            >
                <DeleteAdmin
                    user={user}
                    updateAdmins={showModal ? (id) => (updateAdmins(id)) : () => {}}
                    closeModal={showModal ? () => toggleModal() : () => {}}
                />
            </Modal>
        </>
    )
}

export default AdminManagement