import React,{ useState } from 'react';
import { useAsyncDebounce } from "react-table";

function GlobalFilter({ filter, setFilter }) {

    const [value, setValue] = useState(filter);

    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined);
    }, 300);

    return (
        <input
            className={'search'}
            placeholder={'Search...'}
            value={value || ''}
            onChange={e => {
                setValue(e.target.value)
                onChange(e.target.value)
            }}
        />
    );
}

export default GlobalFilter;