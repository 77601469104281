import style from './Loading.module.scss';
import loadingSpinner from '../../../assets/images/loading.svg'

function Loading(){
    return(
        <div className={style.backdrop}>
            <img className={style.spinner} alt={''} src={loadingSpinner} />
        </div>
    )
}

export default Loading;
