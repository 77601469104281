import Loading from "../../ui-components/Loading/Loading";
import {useState, useEffect, useContext} from "react";
import useGet from "../../../hooks/useGet";
import SelectInput from "../Inputs/SelectInput";
import authContext from "../../../store/auth-context";
import coursesContext from "../../../store/courses-context";
import usePost from "../../../hooks/usePost";
import {useNavigate, useParams} from "react-router-dom";
import TrayToggle from "../../ui-components/TrayToggle/TrayToggle";
import notificationContext from "../../../store/notification-context";
import SorryTheresAProblem from "../../ui-components/SorryTheresAProblem/SorryTheresAProblem";

const SendToExaminer = (props) => {

    const authCtx = useContext(authContext)
    const coursesCtx = useContext(coursesContext)
    const notificationsCtx = useContext(notificationContext)
    const params = useParams()
    const navigate = useNavigate()
    const [examiners, setExaminers] = useState(false)
    const [examiner, setExaminer] = useState(false)
    const [forceValidation, setForceValidation] = useState(false)
    const [skipExaminer, setSkipExaminer] = useState(false)
    const {loading, returnData: fetchExaminers} = useGet('/api/examiners/', 500)
    const {postData, returnPostData, sending, returnMsg, showMessage} = usePost()

    //Set Examiners
    useEffect(()=>{
        if(!loading){
            if(fetchExaminers.length){
                //Format admins
                let examiners = [['0', "Please Select..."]]
                fetchExaminers.map(admin => {
                    if(String(admin.id) !== String(authCtx.id)){
                        examiners.push(
                            [admin.id, (admin.name)]
                        )
                    }
                    return false
                })
                setExaminers(examiners)
            }

            // Grades successfully added
            if(returnPostData){
                if(!returnPostData.error){

                    // Temp message
                    let tempMessage = 'Your grades have been submitted. The examiner has been notified and will review them before confirming.'

                    //Update the courses context
                    let updatedCourses = []
                    coursesCtx.courses.map(course => {

                        let gradesSubmitted = course.gradesSubmitted

                        // Match current course
                        if(params.course_id === course.id){
                            gradesSubmitted = "1"

                            if (course.level === 'foundation' || skipExaminer) {
                                tempMessage = 'Your grades have successfully been submitted, students will now receive their certificates.'
                            }
                        }

                        updatedCourses.push({
                                'id': course.id,
                                'weaponsSystem' : course.weaponsSystem,
                                'teachers': course.teachers,
                                'examDate' : course.examDate,
                                'gradesSubmitted' : gradesSubmitted
                            }
                        )
                        return false
                    })
                    coursesCtx.setCourses(updatedCourses)

                    //Update notifications
                    const updatedNotifications = notificationsCtx.notifications.filter(item => !(item.type === 'course' && item.id === params.course_id))
                    notificationsCtx.setNotifications(updatedNotifications)

                    const data = {
                        title: "Grades submitted",
                        message: tempMessage
                    }
                    notificationsCtx.setTempNotification(data)

                    //Redirect
                    navigate('/' + authCtx.userType);
                }
            }
        }
    }, [authCtx.id, authCtx.userType, coursesCtx, fetchExaminers, loading, navigate, notificationsCtx, params.course_id, returnPostData, skipExaminer])

    //Submit grades
    function gradesSubmitHandler(e) {
        e.preventDefault()
        setForceValidation(true)
        if(examiner) {
            const data = {
                examinerId: examiner,
                grades: props.grades
            };
            postData(data, '/api/courses/' + params.course_id + '/grades', 600)
        }
    }

    //Submit grades (skip examiner)
    function gradesSubmitSkipExaminerHandler(e) {
        e.preventDefault()
        const data = {
            examinerId: authCtx.id,
            grades: props.grades
        };
        postData(data, '/api/courses/' + params.course_id + '/grades-no-examiner', 600)
    }

    function chooseExaminerHandler(status){
        setExaminer(status)
    }

    return(
        <>
            {loading ? <Loading /> : !examiners ? <SorryTheresAProblem message={"No examiners were found"} /> :
                skipExaminer ?
                    <>
                        <h1>Confirm Grades Now</h1>
                        <p>As a super-admin you can skip the examiner confirmation process and issue these grades now. You will be listed as the examiner.</p>

                        <form onSubmit={gradesSubmitSkipExaminerHandler} className={"form"} action="">
                            <TrayToggle open={showMessage}>
                                <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{returnMsg}</div>
                            </TrayToggle>
                            <button className={"submit"} onClick={gradesSubmitSkipExaminerHandler} type="button" disabled={sending}>{!sending ? "Submit Grades" : "Sending..."}</button>
                            <button onClick={() => setSkipExaminer(false)} type='button' className={"submit submit--alt"} disabled={sending}>Cancel</button>
                        </form>
                        {sending && <div className={"sending"}/>}
                    </>
                    :
                    <>
                        <h1>Select Examiner</h1>
                        <p>Please select an examiner to send these grades to. Warning, once you've submitted the grades, they cannot be removed or amended.</p>
                        <form onSubmit={gradesSubmitHandler} className={"form"} action="">
                            <div className='field'>
                                <SelectInput
                                    label={"Examiner"}
                                    an={true}
                                    required={true}
                                    options={examiners}
                                    value={(status) => chooseExaminerHandler(status)}
                                    sending={sending}
                                    forceValidation={forceValidation}
                                />
                            </div>
                            <TrayToggle open={showMessage}>
                                <div className={["ReturnMsg", "ReturnMsgError"].join(" ")}>{returnMsg}</div>
                            </TrayToggle>
                            <button className={"submit"} onClick={gradesSubmitHandler} type="button" disabled={sending}>{!sending ? "Post Grades" : "Sending..."}</button>
                            {(authCtx.userType === 'super-admin') &&
                                <button onClick={() => setSkipExaminer(true)} type='button' className={"submit submit--alt"} disabled={sending}>Skip Examiner</button>
                            }
                        </form>
                        {sending && <div className={"sending"}/>}
                    </>
            }
        </>
    )
}

export default SendToExaminer