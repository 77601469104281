export const COLUMNS = [
    {
        Header: 'Weapons System',
        accessor: 'weaponsSystem'
    },
    {
        Header: 'Teachers',
        accessor: 'teachers'
    },
    {
        Header: 'Examiner',
        accessor: 'examiner'
    },
    {
        Header: 'Percent',
        accessor: 'percentage'
    },
    {
        Header: 'Exam Date',
        accessor: 'examDate'
    },
]