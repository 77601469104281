import MembersTable from "../../components/functional-components/Tables/MembersTable"
import {useContext, useEffect, useState} from "react"
import AuthContext from "../../store/auth-context"
import UserContext from "../../store/user-context"
import axios from "axios"
import Loading from "../../components/ui-components/Loading/Loading"
import PageTitle from "../../components/ui-components/PageTitle/PageTitle"
import Modal from "../../components/ui-components/Modal/Modal"
import useModal from "../../hooks/useModal"
import MemberProfile from "../../components/functional-components/MemberProfile/MemberProfile";
import SorryTheresAProblem from "../../components/ui-components/SorryTheresAProblem/SorryTheresAProblem";

function Members(){

    const authCtx = useContext(AuthContext)
    const userCtx = useContext(UserContext)
    const {showModal, toggleModal} = useModal()

    const [loading, setLoading] = useState(true)
    const [members, setMembers] = useState(false)
    const [memberId, setMemberId] = useState(false)

    const showMemberHandler = (id) =>{
        setMemberId(id)
        toggleModal()
    }

    useEffect(()=>{

        setTimeout(function(){

            if(authCtx.id){
                const config = {
                    headers: {
                        "Content-type": "application/json",
                        "Authorization": "Bearer " + authCtx.token,
                    },
                }

                axios.get('/api/users/', config).then(response => {
                    if(response.status === 200){
                        setLoading(false)
                        if(response.data !== 'error'){
                            setMembers(response.data)
                        }
                    }else{
                        setLoading(false)
                        setMembers(false)
                    }
                }).catch(function (error) {
                    if (error) {
                        setLoading(false)
                        setMembers(false)
                    }
                })
            }
        }, 500)
    }, [authCtx.id, authCtx.token, userCtx])



    return(
        <>
            <PageTitle>Members</PageTitle>
            {loading ? <Loading/> : !members ? <SorryTheresAProblem message={"No members found."} /> :
                <MembersTable
                    viewProfile={(id) => showMemberHandler(id)}
                    data={members}
                />
            }

            <Modal
                showModal={showModal}
                toggleModal={toggleModal}
            >
                <MemberProfile
                    memberId={memberId}
                />
            </Modal>
        </>
    )
}

export default Members