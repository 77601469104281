import style from './Notifications.module.scss'
import {useNavigate} from "react-router-dom";
import usePost from "../../../../hooks/usePost";

const Notifications = ({notificationsCtx, userType, closeNotifications}) => {

    const navigate = useNavigate()
    const {postData} = usePost()

    function notificationClickHandler(index, id){
        const clickedNotification = notificationsCtx.notifications[index]

        //Redirects
        if(clickedNotification.type === "course"){
            navigate('/' + userType + '/course/' + clickedNotification.id);
            closeNotifications()
        }
        if(clickedNotification.type === "examiner"){
            navigate('/' + userType + '/grades-confirm/' + clickedNotification.id);
            closeNotifications()
        }
        if(clickedNotification.type === "certificate"){
            if(!clickedNotification.read){
                postData('', '/api/notifications/' + id, 0)
            }
            notificationsCtx.markAsRead(id, "certificate")
            navigate('/' + userType, { state: { selectedCertificate: clickedNotification.link_ref }})
        }
    }

    return(
        <div className={style.Notifications}>
            <div className={style.title}>Notifications</div>

            {
                (notificationsCtx.notifications.length !== 0) ?
                    <>
                        {
                            notificationsCtx.notifications.map((notification, index) => {
                                if (!notification.read) {
                                    return <button key={index} onClick={() => notificationClickHandler(index, notification.id)}
                                                   className={style.notification}>{notification.message}</button>
                                } else {
                                    return <button onClick={() => notificationClickHandler(index, notification.id)} key={index}
                                                   className={style.notification_read}>{notification.message}</button>
                                }
                            })
                        }
                    </>
                    :
                    <div>You have no notifications</div>
            }
        </div>
    )

}

export default Notifications